import { types, flow } from "mobx-state-tree";
import { IObservableArray } from "mobx";
import {
  listDevicesSort,
  sortDirection,
  devicesListCountRows,
  DevicesListCategories, ErrorMessages, DeviceStatuses
} from "../boot/Constants";
import {
  getTotalDevicesCountApi,
  getListDevicesApi,
  moveDeviceApi,
  serviceRequestDeviceApi
} from "../services/api/devices.api";
import { checkIsCanUseApi, getDeviceListCSVApi, sendCommandForDeviceApi } from "../services/api/list-patients.api";

export const ListDevicesModel = types
  .model("ListDevicesModel", {
    activeCategory: types.optional(types.string, "all"),
    page: 0,
    total: 0,
    allCategoryTotal: types.optional(types.number, 0),
    activeCategoryTotal: types.optional(types.number, 0),
    inventoryCategoryTotal: types.optional(types.number, 0),
    problemCategoryTotal: types.optional(types.number, 0),
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, listDevicesSort.serialnumber),
    sortDirection: types.optional(types.number, sortDirection.ASC),
    selectedDevice: types.optional(types.frozen(), {}),
    showMoveModal: types.optional(types.boolean, false),
    showRequestModal: types.optional(types.boolean, false),
    fileName: types.optional(types.string, ""),

    items: types.optional(types.array(types.frozen()), []),
    isLoading: types.optional(types.boolean, true)
  })
  .actions(self => ({
    setList(dto: any[]) {
      self.items = dto as IObservableArray;
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      getList();
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const getList = flow(function* getList() {
      self.setLoading(true);
      const rows = self.total && ((self.page * devicesListCountRows + devicesListCountRows) > self.total)
        ? self.total - (self.page * devicesListCountRows)
        : devicesListCountRows;

      const filter: any = {
        Offset: self.page * devicesListCountRows,
        CountRows: rows
      };

      if (self.searchValue) {
        filter.Find = {
          serialnumber: self.searchValue,
          status: self.searchValue
        };
      }

      if (self.sortBy && self.sortDirection) {
        filter.SortBy = self.sortBy;
        filter.SortDirection = self.sortDirection;
      }

      const category = DevicesListCategories.find(i => i.value === self.activeCategory);

      try {
        const result = yield getListDevicesApi(filter, category ? category.type : 0);
        const fileResult = yield getDeviceListCSVApi((category?.type || 0) + "", filter);
        if (fileResult.ok) {
          self.fileName = fileResult.data;
        }
        if (result.ok) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      const filter: any = {
      };

      if (self.searchValue) {
        filter.Find = {
          serialnumber: self.searchValue,
          status: self.searchValue
        };
      }

      try {
        const result = yield getTotalDevicesCountApi(filter);
        if (result.ok) {
          self.allCategoryTotal = result.data.All || 0;
          self.activeCategoryTotal = result.data.Active || 0;
          self.inventoryCategoryTotal = result.data.Inventory || 0;
          self.problemCategoryTotal = result.data.Problem || 0;

          switch (self.activeCategory) {
            case DevicesListCategories[0].value :
              self.total = self.allCategoryTotal;
              break;
            case DevicesListCategories[1].value :
              self.total = self.activeCategoryTotal;
              break;
            case DevicesListCategories[2].value :
              self.total = self.inventoryCategoryTotal;
              break;
            case DevicesListCategories[3].value :
              self.total = self.problemCategoryTotal;
              break;
            default:
              break;
          }
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const openMoveModal = (device: any) => {
      self.selectedDevice = device;
      self.showMoveModal = true;
    };

    const openRequestModal = (device: any) => {
      self.selectedDevice = device;
      self.showRequestModal = true;
    };

    const hideMoveModal = () => {
      self.selectedDevice = {};
      self.showMoveModal = false;
    };

    const hideRequestModal = () => {
      self.selectedDevice = {};
      self.showRequestModal = false;
    };

    const moveDevice = flow(function* moveDevice(deviceId: string | number, clinicId: string | number) {
      try {
        const result = yield moveDeviceApi(deviceId, clinicId);
        if (result.ok) {
          getTotalCount();
          return true;
        } else if (result.error) {
          return result.error;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    });

    const serviceRequestDevice = flow(function* serviceRequestDevice(
      deviceSN: string | number, directContact: string, reasonOfReturn: string) {
      try {
        const result = yield serviceRequestDeviceApi(deviceSN, directContact, reasonOfReturn);
        if (result.ok) {
          getTotalCount();
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    });

    const checkIsCanUse = flow(function* checkIsCanUse(
      item: any,
      status: number,
      idDevice: number | string,
      idPatient?: number | string,
      archiveStatus?: number
    ) {
      try {
        if (item.checkArchiveStatus && archiveStatus && archiveStatus > 0) {
          return false;
        } else if (item.checkInTransitStatus && status === DeviceStatuses.InTransit) {
          return false;
        } else {
          const result = yield checkIsCanUseApi(item.checkUrl, { deviceId: idDevice });
          if (result.ok) {
            return result.data;
          } else {
            return true;
          }
        }
      } catch (error) {
        return true;
      }
    });

    const sendCommandForDevice = flow(function* sendCommandForDevice(
      item: any,
      status: number,
      idDevice: number | string,
      idPatient: number | string,
      data: any
    ) {
      try {
        self.setLoading(true);
        const isCanUse = yield checkIsCanUse(item, status, idDevice, idPatient);
        if (isCanUse) {
          const result = yield sendCommandForDeviceApi(item.url, data);
          if (!result.ok) {
            alert(ErrorMessages.actionCanNotUse);
          }
        } else {
          alert(ErrorMessages.actionCanNotUse);
        }
        getList();
      } catch (error) {
        alert(ErrorMessages.actionCanNotUse);
        getList();
      }
    });

    return {
      setActiveCategory,
      nextPage,
      previousPage,
      getList,
      getTotalCount,
      setSearch,
      setSortBy,
      openMoveModal,
      openRequestModal,
      hideMoveModal,
      hideRequestModal,
      moveDevice,
      serviceRequestDevice,
      checkIsCanUse,
      sendCommandForDevice,
      setFirstPage
    };
  });
