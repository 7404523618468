import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { PatientInfoEventsTab } from "./cl-patient-info-events-tab-reported.presents";
import { ClPatientInfoEventsItem } from "../cl-patient-info-events-item";
import { getEventTypeString } from "../../stores/patientInfo/patientInfo.helper.service";
import { PropsType } from "./cl-patient-info-events-tab-reported.props";
import {
  ClPatientInfoEditIncludeToReport
} from "../cl-patient-info-edit-icnlude-to-report/cl-patient-info-edit-include-to-report";
import { useState } from "react";
import { EventActionCP, sortDirection } from "../../boot/Constants";
import { CLIcon } from "../shared/cl-icon";
import SortIcon from "../../assets/icons/sort.svg";
import { CLCheckbox } from "../shared/cl-checkbox";

const listEvents = [
  { icon: "All", type: "all", id: 0, count: 1 },
  { icon: "Stat", id: 1, count: 1 },
  { icon: "Profile", id: 2, count: 1 },
  { icon: "Rejected", id: 4, count: 1 }
];

export const ClPatientInfoEventsTabReported = observer((props: PropsType) => {
  const {
    activeEventType,
    trackEvent,
    sortEvents,
    changeSortEventsType,
    setEventIncludeToReport
  } = useRootStore().patientInfoStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const [modalReportShow, setModalReportShow] = useState(null);

  const getItemType = (listItem: any) => {
    if (listItem) {
      return getEventTypeString(listItem);
    }
  };

  const isDisplayReport = (listItem: any): boolean => {
    const itemType = getItemType(listItem);
    if ((props.activeFilter === 0 && itemType !== "Rejected"
      || listEvents[props.activeFilter] && listEvents[props.activeFilter].icon === itemType
      && listItem.eventStatus !== 0)) {
      return true;
    } else if (itemType === "Rejected"
      && listEvents[props.activeFilter] && listEvents[props.activeFilter].icon === itemType) {
      return true;
    }
    return false;
  };

  const isDisplayReportDay = (listItems: any): boolean => {
    let isOneItemExist = false;
    listItems.forEach((item: any) => {
      if (isDisplayReport(item)) {
        isOneItemExist = true;
        return true;
      }
    });

    return isOneItemExist;
  };

  const setEvent = (listItem: any) => {
    props.setEvent(listItem);
  };

  const editItem = (listItem: any) => {
    setModalReportShow(listItem);
  };

  const handleCheckbox = async() => {
    setGlobalSpiner(true);
    let ids: (number | string)[] | null = [];
    props.events.map((item: any) => {
      if (item.data) {
        item.data.map((listItem: any) => {
          if (isDisplayReport(listItem)) {
            if (isAllChecked) {
              if (listItem.IncludedToReport === true) {
                if (!ids) {
                  ids = [];
                }
                ids.push(listItem.Id);
              }
            } else {
              if (listItem.IncludedToReport === false) {
                if (!ids) {
                  ids = [];
                }
                ids.push(listItem.Id);
              }
            }
          }
        });
      }
    });
    if (ids && ids.length) {
      if (isAllChecked) {
        await setEventIncludeToReport(ids, false);
      } else {
        await setEventIncludeToReport(ids, true);
      }
      setTimeout(() => {
        setGlobalSpiner(false);
      }, 500);
    } else {
      setGlobalSpiner(false);
    }
  };

  let isAllChecked = true;
  props.events.map((item: any) => {
    if (item.data) {
      item.data.map((listItem: any) => {
        if (isDisplayReport(listItem)) {
          if (listItem.IncludedToReport === false) {
            isAllChecked = false;
          }
        }
      });
    }
  });

  return (
    <PatientInfoEventsTab>
      <div
        className="sort-container"
        onClick={() => {
          changeSortEventsType(sortEvents === sortDirection.DESC ? sortDirection.ASC : sortDirection.DESC);
        }}>
        <CLIcon icon={SortIcon}></CLIcon>
        <div className="text">Sort by date: <span>{sortEvents === sortDirection.DESC ? "Newest" : "Oldest"}</span></div>
      </div>
      {listEvents[props.activeFilter].icon !== "Rejected" && <div className="select-all">
        <CLCheckbox
          handleChange={handleCheckbox}
          isChecked={isAllChecked}
          label={isAllChecked ? "Deselect All" : "Select All"}></CLCheckbox>
      </div>}
      {(sortEvents === sortDirection.DESC ? props.events : [...props.events].reverse()).map((item: any) => {
        if (item && item.data && item.data.length && isDisplayReportDay(item.data)) {
          return (
            <div className="block" key={item.key}>
              <h3 className={"event-date"}>{item.key}</h3>
              <div className={"list"}>
                {(sortEvents === sortDirection.DESC ? item.data : [...item.data].reverse()).map((listItem: any) => {
                  if (isDisplayReport(listItem)) {
                    return (<ClPatientInfoEventsItem
                      key={listItem.Id}
                      item={listItem}
                      isActive={activeEventType === listItem.Id}
                      isNew={listItem.EventActionCP === EventActionCP.STATNew}
                      setEvent={() => setEvent(listItem)}
                      editItem={() => editItem(listItem)}
                      type="reported"
                      trackNewEvent={() => trackEvent(listItem.Id)}
                    ></ClPatientInfoEventsItem>);
                  }
                })}
              </div>
            </div>
          );
        }
      })}
      {modalReportShow && <ClPatientInfoEditIncludeToReport
        isShow={!!modalReportShow}
        item={modalReportShow}
        closeModal={() => setModalReportShow(null)}></ClPatientInfoEditIncludeToReport>}
    </PatientInfoEventsTab>
  );
});
