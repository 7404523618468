import styled from "styled-components";
import { theme } from "../../theme/theme";

export const PatientInfoEvents = styled.div`
  width: 304px;
  position: relative;
  //margin: 24px 0 24px 24px;
  flex: 0 0 304px;
  max-width: 304px;
  
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column!important;
    margin: 24px;
    margin-right: 0;
    padding: 24px 0;
    border-radius: 16px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
    background: ${theme.colors.light};
    .header{
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
    }
  }
  .events-list{
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding-bottom: 170px;
  }
  .event-date{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(72, 72, 72, 0.7);
    padding: 16px;
    border-top: 1px solid ${theme.colors.background};
    border-bottom: 1px solid ${theme.colors.background};
    margin-bottom: 0;
  }
  .events-nav{
    height: 58px;
    display: flex;
    justify-content: space-between;

    padding-left: 5px;
    padding-right: 10px;
  }
  .list{
    padding-top: 8px;
    padding-bottom: 8px;
  }
  h3{
    padding-left: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #484848;
  }
`;
