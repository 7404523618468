import { PropsType } from "./cl-patient-events-nav.props";
import { PatientEventsNavStyle } from "./cl-patient-events-nav.presents";
import { ClPatientEventsIcons } from "../cl-patient-events-icons";

export const ClPatientEventsNav = (props: PropsType) => {
  const handleClick = () => {
    props.setActiveFilter();
  };
  return (
    <PatientEventsNavStyle {...props} onClick={handleClick} className={!props.count ? "disabled" : ""}>
      {!!(props.count && props.count > 0) && <span className={"counter"}>{props.count}</span>}
      {!!(!props.count || props.count === 0) && <span></span>}
      <div className="icon-holder">
        <ClPatientEventsIcons type={props.icon}></ClPatientEventsIcons>
      </div>
      {props.isActive && <div className="bottom-border"></div>}
    </PatientEventsNavStyle>
  );
};
