import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const AdditionalClinicsModel = types
  .model("SelectOptionsModel", {
    value: types.string,
    label: types.string,
    isDisabled: types.maybe(types.boolean),
    id: types.optional(types.string, "")
  });

export const SelectOptionsModel = types
  .model("SelectOptionsModel", {
    value: types.string,
    label: types.string,
    id: types.optional(types.string, ""),
    isDisabled: types.maybe(types.boolean),
    additionalClinics: types.maybe(types.array(AdditionalClinicsModel)),
    startDate: types.maybe(types.string)
  });

export const SelectOptionsDefault = types
  .model("SelectOptionsDefault", {
    value: types.string,
    label: types.string
  });

export interface ISelectOptionsModel extends Instance<typeof SelectOptionsModel> {}
export interface ISelectOptionsModelSnapShot extends SnapshotIn<typeof SelectOptionsModel> {}
export interface IAdditionalClinicsModel extends Instance<typeof AdditionalClinicsModel> {}
