import { CLCard } from "../../components/shared/cl-card";
import { ReportsListStyle } from "./ReportsList.presents";
import { useRootStore } from "../../stores/storeContext";
import { useEffect } from "react";
import { CLReportsListHeader } from "../../components/cl-reports-list-header";
import { CLReportsListTable } from "../../components/cl-reports-list-table";
import { ClAlert2fa } from "../../components/cl-alert-2fa";
import { ClAction2fa } from "../../components/cl-action-2fa";
import { ClAlert2faHero } from "../../components/cl-alert-2fa-hero";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

export const ReportsListPage = () => {
  const {
    getTotalCount
  } = useRootStore().listReportsStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  let isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  const { PortalUserMC, IdCurrentMC } = useRootStore().userStore;

  useEffect(() => {
    isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  }, [PortalUserMC, IdCurrentMC]);

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);

  return (
    <ReportsListStyle>
      <CLCard padding="24px">
        <CLReportsListHeader></CLReportsListHeader>
        <CLReportsListTable></CLReportsListTable>
      </CLCard>
      {isMandatoryMFA ? <ClAlert2fa/> : <ClAlert2faHero/>}
      <ClAction2fa/>
    </ReportsListStyle>
  );
};
