import styled from "styled-components";

export const StudyConfirmModalStyle = styled.div`
  position: absolute;
  max-width: 584px !important;
`;

export const StudyConfirmModalContainerStyle = styled.div`
  .modal-container{
    padding: 40px;
    max-width: 584px !important;
    margin: 0 auto;
  }
  .success-icon {
    img {
      margin: 0 auto;
    }
  }
  .title{
    font-size: 18px;
    line-height: 24px;
    color: #484848;
    margin-bottom: 32px;
    margin-top: 25px;
    text-align: center;
  }
  .content{
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    padding: 24px 0 16px;
    .description {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 24px;
    }
    .info {
      font-size: 14px;
      line-height: 16px;
      margin-top: 24px;
    }
    .list-item {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }
  
  .footer{
    display: flex;
    margin-top: 24px;
    justify-content: center;
    .btn-wrapper{
      //margin: 0 12px;
      &:first-child{
        margin-right: 24px;
      }
    }
  }
  
`;
