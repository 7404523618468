import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const PatientInfoReportsStyle = styled.div`
  padding: ${theme.offsets.mainContentVertical} 0;
  display: flex;

  .spiner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
