import styled from "styled-components";

export const ClChartModalsEventPresents = styled.div`
  display: flex;
  flex-direction: column;
  .actions-wraper {
    //margin-top: -2px;
    display: flex;
    .btn-wrapper {
      margin-left: 16px;
    }
  }
`;
