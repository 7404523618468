import API from "../../boot/Api";
import * as Types from "./api.types";
import { encodeData } from "../handlers/data.handler";

export const getAllClinicDevicesApi = async(jsonFilter: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    jsonFilter: jsonFilter
  });
  const result = API.get(`/ClinicDevices/Free?${encodedData}`);
  return await Types.getResponse(result);
};

export const configureDeviceApi = async(patientId: string, deviceId: string, data: string):
  Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    patientId: patientId,
    deviceId: deviceId,
    jsonDeviceSettings: data
  });
  const result = API.post(
    `/ClinicDevices/ConfigureDevice?${encodedData}`);
  return await Types.getResponse(result);
};

export const startStudyApi = async(patientId: string, deviceId: string, data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    patientId: patientId,
    deviceId: deviceId,
    jsonDeviceSettings: data
  });
  const result = API.post(
    `/ClinicDevices/StartTest?${encodedData}`);
  return await Types.getResponse(result);
};
export const shipToHomeApi = async(patientId: string, data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    patientId: patientId,
    jsonDeviceSettings: data
  });
  const result = API.post(
    `/ClinicDevices/ShipToHome?${encodedData}`);
  return await Types.getResponse(result);
};

export const updateClinicDeviceSettingsTemplate = async(idClinic: string, data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    idClinic: idClinic,
    jsonSettingsTemplate: data
  });
  const result = API.post(
    `/PortalUser/UpdateClinicDeviceSettingsTemplate?${encodedData}`);
  return await Types.getResponse(result);
};

export const updateNotificationsCriteria = async(idPhysician: string, data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    idPhysician: idPhysician,
    jsonNotificationCriteria: data
  });
  const result = API.post(
    "/ClinicPhysicians/UpdateNotificationCriteria", encodedData);
  return await Types.getResponse(result);
};

export const updateAllUsersNotificationsCriteria
  = async(idClinic: string, data: string): Promise<Types.ApiResponse> => {
    const encodedData = encodeData({
      idClinic: idClinic,
      jsonNotificationCriteria: data
    });
    const result = API.post(
      "/ClinicPhysicians/UpdateNotificationCriteriaPhysicians", encodedData);
    return await Types.getResponse(result);
  };
