import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLModal } from "../shared/cl-modal";
import { CLSelect } from "../shared/cl-select";
import {
  PatientInfoCreateEventsModalContainerStyle,
  PatientInfoCreateEventsStyle
} from "./cl-patient-info-create-event.presents";
import { PropsType } from "./cl-patient-info-create-event.props";
import {
  getECGEventDeltaStart,
  getECGEventStart,
  getHRDataURL
} from "../../stores/patientInfo/patientInfo.chartexport.service";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { ArrhythmiaType } from "../../stores/patientInfo/patientInfo.constant";
import { ClChartsEcgPreview } from "../cl-charts-ecg-preview";
import API from "../../boot/Api";
import * as Types from "../../services/api/api.types";
import { encodeData } from "../../services/handlers/data.handler";
import { getListECG } from "../../stores/patientInfo/patientInfo.helper.service";

const saveReport = async(recordingID: string, deviceId: string, jsonSegment: any): Promise<any> => {
  const encodedData = encodeData({
    recordingID: recordingID,
    deviceId: deviceId,
    jsonSegment: JSON.stringify(jsonSegment)
  });
  const result = API
    .post(`ClinicReports/AddEvent?${encodedData}`);
  return await Types.getResponse(result);
};

function getActiveEventStartDate(): [Moment, Moment] {
  const activeEventDate = moment(getECGEventStart());
  const activeEventDate2 = moment(getECGEventStart() + 8000);
  const value = activeEventDate || moment();
  const value2 = activeEventDate2 || moment();
  return [value, value2];
}

export const ClPatientInfoCreateEvent = observer((props: PropsType) => {
  const {
    patient,
    activeDate,
    events,
    activeEventType,
    additionalEvents,
    getPatient,
    activeFolderName
  } = useRootStore().patientInfoStore;

  const [activeChart, setActiveChart] = useState("ecg");
  const [fields, setFields] = useState({
    eventRange: getActiveEventStartDate(),
    eventType: { value: { id: "3", label: "Pause", value: "Pause" } }
  });

  if (!activeDate || !events) {
    return (<></>);
  }

  let date: string;
  if (activeDate.format) {
    date = activeDate.format("MM/DD/yyyy");
  }

  let activeEvent: any;
  if (additionalEvents?.EcgEvents?.length) {
    activeEvent = additionalEvents.EcgEvents[0];
  } else if (activeFolderName) {
    const activeId = getListECG().find((eventEcg: any) => eventEcg.FolderName === activeFolderName)?.Id || 0;
    activeEvent = {
      FolderName: activeFolderName,
      IDReceivedData: activeId
    };
  } else {
    events.forEach((obj: any) => {
      if (date === obj.key) {
        obj.data.forEach((event: any) => {
          if (+event.Id === +activeEventType) {
            activeEvent = event;
          }
        });
      }
    });
  }

  const saveChanges = () => {
    let size = 0;
    if (fields.eventRange[1] && fields.eventRange[0]) {
      size = fields.eventRange[1].valueOf() - fields.eventRange[0].valueOf();
    }

    const data = {
      "IDReceivedData": activeEvent.IDReceivedData,
      "FolderName": activeEvent.FolderName,
      "StartFromBeginRD": getECGEventDeltaStart(),
      "EventType": fields.eventType.value && fields.eventType.value.id,
      "Length": size / 1000
    };

    saveReport(patient.RecordingID, patient.Device.Id, data)
      .then((response) => {
        if (response.ok) {
          props.closeModal();
          getPatient(patient.RecordingID, true);
        } else {
          console.error("Something went wrong");
        }
      });
  };

  const handleFirstDatePick = (val: any) => {
    setFields((prevValue) => ({ ...prevValue, eventRange: [val, fields.eventRange[1]] }));
  };
  const handleSecondDatePick = (val: any) => {
    setFields((prevValue) => ({ ...prevValue, eventRange: [fields.eventRange[0], val] }));
  };

  const selectOption = (ev: any) => {
    setFields((prevValue) => ({ ...prevValue, eventType: { value: ev } }));
  };

  const hrDataURL = getHRDataURL();

  const disabledStartDate = (current: Moment) => {
    return current && (current < moment(getECGEventStart()) || current > moment(fields.eventRange[1]));
  };

  const disabledEndDate = (current: Moment) => {
    return current && (current < moment(getECGEventStart()) || current < moment(fields.eventRange[0]));
  };

  return (
    <PatientInfoCreateEventsStyle>
      <CLModal
        show={props.isShow}
        handleHideModal={() => props.closeModal()}
        title={"Create Event"}>
        <PatientInfoCreateEventsModalContainerStyle>
          <div className="modal-container">
            <div className="title">
                            Create Event
            </div>
            <div className="tabs">
              <ul className="tab-nav">
                <li className={activeChart === "ecg" ? "active" : ""}
                  onClick={() => setActiveChart("ecg")}>ECG
                </li>
                <li className={activeChart === "hr" ? "active" : ""}
                  onClick={() => setActiveChart("hr")}>HR
                </li>
              </ul>

              {activeChart === "ecg" && <div className="chart-ecg" id="chart-ecg-preview">
                <div className="chart">
                  <p className="chart-time">{moment(getECGEventStart()).format("MM/DD/yyyy hh:mm:ss A")}</p>
                  <ClChartsEcgPreview></ClChartsEcgPreview>
                </div>
              </div>}
              {activeChart === "hr" && <div className="chart-hr">
                <img src={hrDataURL} alt=""/>
              </div>}

              <div className="form">
                <div className="form-field form-range">
                  <label>
                                        Event Range
                  </label>
                  <div className="input-field-range">
                    <DatePicker
                      className={"cl-picker"}
                      popupStyle={{ zIndex: 10500 }}
                      value={fields.eventRange[0]}
                      format={"MM/DD/yyyy hh:mm:ss A"}
                      onChange={handleFirstDatePick}
                      disabledDate={disabledStartDate}
                      showTime/>
                    <DatePicker
                      className={"cl-picker"}
                      popupStyle={{ zIndex: 10500 }}
                      value={fields.eventRange[1]}
                      format={"MM/DD/yyyy hh:mm:ss A"}
                      onChange={handleSecondDatePick}
                      disabledDate={disabledEndDate}
                      showTime/>
                  </div>
                </div>
                <div className="form-field">
                  <label>
                                        Event Type
                  </label>
                  <CLSelect
                    width="100%"
                    options={ArrhythmiaType}
                    selectOption={selectOption}
                    name={"type"}
                    defaultValue={fields.eventType.value}></CLSelect>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="btn-wrapper">
                <CLLightButton
                  click={props.closeModal}
                  text="Cancel"
                  width="200px"></CLLightButton>
              </div>

              <div className="btn-wrapper">
                <CLBlueButton
                  click={saveChanges}
                  text="Save"
                  width="200px"></CLBlueButton>
              </div>
            </div>
          </div>
        </PatientInfoCreateEventsModalContainerStyle>

      </CLModal>

    </PatientInfoCreateEventsStyle>
  );
});
