import { types, applySnapshot, flow, Instance, SnapshotIn } from "mobx-state-tree";
import {
  getBillingData,
  getUsageDataApi,
  getUtilizationDataApi,
  setBilledApi
} from "../../services/api/billing.api";
import moment from "moment";
import { listBillingSort, sortDirection } from "../../boot/Constants";

const getCount = (items: any) => {
  const obj: any = {};
  items.forEach((item: any) => {
    if (!obj[item.Mode]) {
      obj[item.Mode] = 1;
    } else {
      obj[item.Mode]++;
    }
  });
  const arr = [];
  for (const key in obj) {
    arr.push({
      title: key,
      value: obj[key]
    });
  }
  return arr as any;
};

export const BillingModel = types
  .model("BillingModel", {
    items: types.frozen([]),
    usageData: types.frozen([]),
    usageDataAvg: types.optional(types.number, 0),
    utilizationeData: types.frozen([]),
    startDate: types.optional(types.string, moment().subtract(1, "months").format("MM/DD/yyyy")),
    endDate: types.optional(types.string, moment().format("MM/DD/yyyy")),
    isCalculateDuplicate: types.optional(types.boolean, false),
    sortBy: types.optional(types.string, listBillingSort.startdate),
    sortDirection: types.optional(types.number, sortDirection.ASC),
    isLoading: types.optional(types.boolean, true),
    isLoadingUtilization: types.optional(types.boolean, true),
    countMode: types.frozen([])
  })
  .actions(self => {
    const resetForm = () => {
      applySnapshot(self, {});
    };
    const setIsLoading = (value: boolean) => {
      self.isLoading = value;
    };
    const setIsLoadingUtilization = (value: boolean) => {
      self.isLoadingUtilization = value;
    };
    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getItems();
    };

    const getItems = flow(function* getItems(startDate?: string, endDate?: string, isCalcDuplicate: boolean = false) {
      const filter: any = {};
      setIsLoading(true);
      if (startDate) {
        self.startDate = startDate;
      }
      if (endDate) {
        self.endDate = endDate;
      }
      if (isCalcDuplicate) {
        self.isCalculateDuplicate = isCalcDuplicate;
      }
      if (self.sortBy && self.sortDirection) {
        filter.SortBy = self.sortBy;
        filter.SortDirection = self.sortDirection;
      }
      try {
        const response = yield getBillingData(
          startDate || self.startDate,
          endDate || self.endDate,
          !self.isCalculateDuplicate,
          filter);
        setIsLoading(false);
        if (response.ok && response.data) {
          self.items = response.data;
          self.countMode = getCount(response.data);
          return response.data;
        }
        self.items = [];
        self.countMode = [];
        return [];
      } catch (error) {
        setIsLoading(false);
        return [];
      }
    });

    const setBilled = flow(function* setBilled(recordingId: string, isBilled = false) {
      setIsLoading(true);
      const response = yield setBilledApi(recordingId, isBilled);
      if (response.ok) {
        yield getItems();
        setIsLoading(false);
        return true;
      } else {
        setIsLoading(false);
        return false;
      }
    });
    const getUtilizationData = flow(function* getItems(startDate?: string, endDate?: string) {
      setIsLoadingUtilization(true);
      if (startDate) {
        self.startDate = startDate;
      }
      if (endDate) {
        self.endDate = endDate;
      }
      try {
        const responseUsage = yield getUsageDataApi(startDate || self.startDate, endDate || self.endDate);
        const responseUtilization = yield getUtilizationDataApi(startDate || self.startDate, endDate || self.endDate);

        if (responseUsage.ok && responseUsage.data && responseUsage.data.Data.length) {
          self.usageData = responseUsage.data.Data;
          self.usageDataAvg = responseUsage.data.AvgDevicePerDay;
        } else {
          self.usageData = [];
          self.usageDataAvg = 0;
        }

        if (responseUtilization.ok && responseUtilization.data && responseUtilization.data.length) {
          self.utilizationeData = responseUtilization.data;
        } else {
          self.utilizationeData = [];
        }
        setIsLoadingUtilization(false);
      } catch (error) {
        setIsLoadingUtilization(false);
      }
    });

    return {
      getItems,
      setIsLoading,
      setSortBy,
      setBilled,
      setIsLoadingUtilization,
      resetForm,
      getUtilizationData
    };
  })
  .actions(self => ({
    setIsCalculateDuplicate(value: boolean) {
      self.isCalculateDuplicate = value;
      self.getItems();
    }
  }));

export interface IBillingModel extends Instance<typeof BillingModel> {
}

export interface IBillingModelSnapShot extends SnapshotIn<typeof BillingModel> {
}
