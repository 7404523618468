
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { useEffect, useState } from "react";
import { PropsType } from "./cl-maps-autocomplete.props";

interface AddressDto {
  [key: string]: string[] | string
}
export const ClMapsAutocomplete = (props: PropsType) => {
  const [address, setAddress] = useState<any>(props.value);

  const getAddressObject = (addressComponents: any) => {
    const ShouldBeComponent: AddressDto = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: ["administrative_area_level_1"],
      city: ["locality"],
      country: ["country"],
      subpremise: ["subpremise"]
    };

    const address: AddressDto = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
      state: "",
      subpremise: ""
    };

    addressComponents.forEach((component: any) => {
      for (const shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };

  useEffect(() => {
    const func = async() => {
      const geocodeObj = address && address?.value && (await geocodeByPlaceId(address?.value.place_id));
      const addressObject = geocodeObj && getAddressObject(geocodeObj[0].address_components);
      props.onChange && addressObject && props.onChange(addressObject);
    };
    func();
  }, [address]);

  // useEffect(() => {
  //   const func = async() => {
  //     const geocodeObj = await geocodeByAddress(props.value);
  //     const addressObject = geocodeObj && getAddressObject(geocodeObj[0].address_components);
  //     props.onChange && props.onChange(addressObject);
  //   };
  //   func();
  // }, [props.value]);

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyDsIYIBmJwFbIk58bQZHoWrWDFRMNzl6tc"
        debounce={1000}
        minLengthAutocomplete={10}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["us"]
          }
        }}
        apiOptions={{ language: "en", region: "us" }}
        selectProps={{
          isClearable: true,
          value: address,
          onChange: (val: any) => {
            setAddress(val);
          },
          placeholder: "Search Address...",
          styles: {
            input: (provided: any) => ({
              ...provided,
              height: "40px",
              padding: "8px 0",
              boxShadow: 0,
              "&:hover": {
                border: "1px solid purple"
              }
            }),
            control: () => ({
              color: "#cecece",
              display: "flex",
              "border-radius": "10px",
              border: "1px solid #E5E5E5 !important",
              "box-shadow": "0px 4px 4px rgb(0 0 0 / 5%), -4px -4px 20px rgb(0 0 0 / 5%) !important"
            }),
            indicatorsContainer: () => ({
              color: "#484848",
              display: "flex",
              padding: "8px"
            }),
            indicatorSeparator: () => ({
              display: "none"
            }),
            singleValue: (provided: any) => ({
              ...provided,
              boxShadow: 0,
              "&:hover": {
                border: "1px solid purple"
              }
            })
          }
        }}
      />
    </div>
  );
};
