import styled from "styled-components";
import { theme } from "../../theme/theme";

export const StudyCreateModalStyle = styled.div`
  position: absolute;
`;

export const StudyCreateModalContainerStyle = styled.div`
  .modal-container{
    padding: 40px;
  }
  .radio-group {
    margin-bottom: 16px;
    h6 {
      margin-bottom: 21px;
    }
  }
  .radio-field {
    margin-bottom: 10px;
  }
  .input-field {
    margin-top: 10px;
    position: relative;
    .info{
      position: absolute;
      bottom: 0;
      display: inline-block;
      z-index: 100000000;
      right: 0;
      font-size: 12px;
      margin: 10px;
    }
     textarea {
      border: 1px solid ${theme.colors.greyBorder};
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px;
      resize: none;
      height: 96px !important;
      width: 100%;
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
    }

    textarea::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      opacity: 0.5;
    }

    .error {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.red};
      margin-top: 4px;
    }

    .error-textarea {
      border: 1px solid ${theme.colors.red};
    }
  }
  .footer{
    display: flex;
    justify-content: flex-end;
    .btn-wrapper{
      //margin: 0 12px;
      &:first-child{
        margin-right: 24px;
      }
    }
  }
  .title{
    font-size: 24px;
    line-height: 28px;
    color: #484848;
    margin-bottom: 16px;
  }
 
  .description{
    font-size: 14px;
    line-height: 16px;
  }
  .footer{
    margin-top: 60px;
  }
  
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .table{
    margin: 64px 0;
    padding: 6px 0;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
  }
  .field {
    width: 25%;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;
    .label{
      font-size: 14px;
      line-height: 16px;
      color: #6D6D6D;
      margin-bottom: 8px;
    }
    .value{
      font-size: 16px;
      line-height: 18px;
      color: #484848;
    }
  }
  .checkbox-container{
    margin-top: 20px;
    .checkbox-name {
      font-size: 16px;
    }
  }

`;
