import styled from "styled-components";
import { theme } from "../../theme/theme";

export const SuportModalContainerStyle = styled.div`
  .modal-container{
    padding: 40px;
  }
  .footer{
    display: flex;
    justify-content: flex-end;
    .btn-wrapper{
      //margin: 0 12px;
      &:first-child{
        margin-right: 24px;
      }
    }
  }
  .title{
    position: absolute;
    top: 40px;
    left: 40px;
    font-size: 24px;
    line-height: 28px;
    color: #484848;
  }

  .form{
    max-width: 440px;
    margin: 20px auto;
  }
  .form-field{
    margin: 20px 0;
  }
  .footer{
    margin-top: 60px;
  }
  .whats-new {
    margin-top: 60px;
    textarea {
      border: 1px solid ${theme.colors.greyBorder};
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px;
      resize: none;
      min-height: 356px !important;
      width: 100%;
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
    }

    textarea::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      opacity: 0.5;
    }

    .error {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.red};
      margin-top: 4px;
    }

    .error-textarea {
      border: 1px solid ${theme.colors.red};
    }
  }
`;

export const SupportStyle = styled.div`
  padding: ${theme.offsets.mainContentVertical} ${theme.offsets.mainContent};
  display: flex;
  .edit-news-block {
    margin-top: 11px;
  }
  .left-content {
    width: 430px;
    &.centered-content {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      & > div {
        width: 48%;
        margin-top: 0;
      }
      .news {
        margin-top: 0;
      }
    }
    .hint {
      margin-top: 11px;
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
        color: ${theme.colors.text};
      }
    }
    .contacts {
      .info {
        border-bottom: 1px solid ${theme.colors.background};
        padding-bottom: 16px;
        margin-bottom: 16px;

        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: ${theme.colors.text};
        }

        .content {
          display: flex;
          flex-wrap: wrap;

          .item {
            margin-right: 32px;
          }

          .item:last-child {
            margin-right: 0;
          }

          .content-title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: ${theme.colors.placeholder};
            padding-top: 16px;
            padding-bottom: 8px;
          }

          .content-value {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: ${theme.colors.text};
          }
        }
      }

      .info:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .news {
      margin-top: 11px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: ${theme.colors.text};
        padding-bottom: 24px;
      }

      .info {
        border-bottom: 1px solid ${theme.colors.background};
        padding-bottom: 16px;
        margin-bottom: 16px;

        .date {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: ${theme.colors.placeholder};
        }

        .title {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: ${theme.colors.text};
          padding-bottom: 16px;
          padding-top: 8px;
        }

        .text {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: ${theme.colors.text};
          white-space: pre-line;
        }
      }

      .info:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .right-content {
    padding-left: 24px;
    flex: 1;
    
    .specifications {
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: ${theme.colors.text};
      }

      .files {
        padding-top: 24px;
        padding-bottom: 32px;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        .file {
          width: 120px;
          margin-right: 12px;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 24px;
          cursor: pointer;

          .name {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: ${theme.colors.text};
            text-align: center;
            padding-top: 7px;
          }
        }
      }
    }

    .video-section {
      margin-top: 11px;

      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: ${theme.colors.text};
        padding-top: 24px;
      }

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: ${theme.colors.text};
      }

      .content {
        margin-top: 8px;
        width: 100%;
        height: 700px;
        max-height: 700px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
`;
