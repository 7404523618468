import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClPatientInfoEventsItemStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px 0px 16px 12px;
  cursor: pointer;
  position: relative;

  img {
    display: block;
    margin-right: 4px;
  }

  font-size: 16px;
  line-height: 18px;

  .title {
    color: ${theme.colors.text};
  }


  .bottom-border {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.active {
    background: rgba(38, 86, 186, 0.08);

    .title {
      color: ${theme.colors.blue};
    }

    .bottom-border {
      display: block;
      height: 4px;
      background: ${theme.colors.blue};
    }
  }

  &.new {
    .eventDate {
      font-weight: bold;
      color: black;
    }
  }

  .eventType {
    margin-left: auto;
    max-width: 80px;

    & > div {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .eventDate {
    font-size: 14px;
    line-height: 16px;
    color: #484848;

    .duration {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: ${theme.colors.duration};
      margin-top: 4px;
    }
    .caption {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: ${theme.colors.duration};
      margin-top: 4px;
      max-width: 140px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .three-dots {
    padding: 3px 7px 7px 7px;
  }

  .form-field {
    position: relative;
    z-index: 2;
  }

  .tooltip-container {
    position: absolute;
    top: 100%;
    right: 0;
    background: #FFF;
    width: 210px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    z-index: 100;
    padding: 0 14px;

    .tooltip-item {
      display: flex;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid #E5E5E5;

      &:last-child {
        border-bottom: 0;
      }

      &.delete {
        color: #F45B5B;
      }

      &.tooltip-item-name {
        //padding: 15px 0 15px 40px;
        font-weight: 500;
        color: #555555;
      }
    }
  }
`;
