import { PropsType } from "./cl-input.props";
import { InputStyle } from "./cl-input.presents";

export const CLInput = (props: PropsType) => {
  const handleChange = (event: any) => {
    if (!props.handleChange) return;
    props.handleChange(event);
  };

  const handleFocus = (event: any) => {
    if (!props.onFocus) return;
    props.onFocus(event);
  };

  const handleMouseDown = (event: any) => {
    if (!props.onMouseDown) return;
    props.onMouseDown(event);
  };

  const handleTouchEnd = (event: any) => {
    if (!props.onTouchEnd) return;
    props.onTouchEnd(event);
  };

  const handleBlur = (event: any) => {
    if (!props.onBlur) return;
    props.onBlur(event);
  };

  const handleIconClick = (event: any) => {
    if (!props.iconClick) return;
    props.iconClick(event);
  };

  let autoComplete = props.autoCompleteName;
  if (!props.autoCompleteName) autoComplete = props.autoComplete ? "on" : "off";

  return (
    <InputStyle icon={props.icon} width={props.width} label={props.label} className={props.isInvalid ? "invalid" : ""}>
      {props.label && <label>{props.label}</label>}
      <input
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onMouseDown={handleMouseDown}
        onTouchEnd={handleTouchEnd}
        value={props.value}
        type={props.type}
        name={ props.name || "name"}
        disabled={props.disabled}
        autoComplete={autoComplete}
        id={props.testId}
      ></input>
      {props.icon && <img src={props.icon} alt="" onClick={handleIconClick}></img>}
      <div className="invalid-text">{props.invalidText}</div>
    </InputStyle>
  );
};
