import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLAppHeaderOnlyLogo } from "../../components/cl-app-header";
import { useHistory } from "react-router-dom";
import { recaptchaKey } from "../../boot/Constants";
import { useEffect, useState } from "react";
import { VQAuthLogin } from "vivaquant-components-library";

export const LoginPage = observer(() => {
  const { login, validateCode } = useRootStore().userStore;
  const {
    email,
    password,
    invalidEmail,
    invalidPassword,
    // tokenReCaptcha,
    isSubmit,
    isShowPassword,
    isErrorLogin,
    isLoading,
    setEmail,
    setPassword,
    setSubmit,
    setShowPassword,
    setErrorLogin,
    setLoading,
    setTokenReCaptcha,
    cleanData
  } = useRootStore().loginFormStore;
  const history = useHistory();

  const [isTwoFactor, setIsTwoFactor] = useState(false);
  const [telNumber, setTelNumber] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [resentCode, setResentCode] = useState(false);
  const [errorCode, setErrorCode] = useState(false);

  const onSubmit = async(event) => {
    event.preventDefault();
    setSubmit();
    // [Temp]: Disabled recapcha validation
    // if (invalidEmail || invalidPassword || !tokenReCaptcha || !email || !password) { return; }
    if (invalidEmail || invalidPassword || !email || !password) { return; }
    setLoading(true);
    const result = await login(email, password);
    if (result) {
      if (typeof result === "object" && result.tel) {
        setIsTwoFactor(true);
        setTelNumber(result.tel);
      } else {
        setIsTwoFactor(false);
        setTelNumber("");
        cleanData();
        history.push("/patients-list");
      }
    } else {
      setErrorLogin(true);
    }
    setLoading(false);
  };

  const onValidateCode = async(event) => {
    event.preventDefault();
    setLoading(true);
    const result = await validateCode(email, password, authCode);
    if (result) {
      cleanData();
      history.push("/patients-list");
    } else {
      setErrorCode(true);
    }
    setLoading(false);
  };

  const forgotPass = () => {
    history.push("/forgot-password");
  };

  const showPassword = () => {
    setShowPassword();
  };

  const handleVerifyRecaptcha = (token) => {
    setTokenReCaptcha(token || "");
  };

  const getTwoFactorAuthenticationMessage = () => {
    const replaced = telNumber.replace(/.(?=....)/g, "*");
    const sentText = resentCode ? "resent" : "sent";
    return `A message with a verification code has been ${sentText} to your phone number ${replaced}.
    Enter the code to continue.`;
  };

  const handleOnChangeAuthCode = (res) => {
    setAuthCode(res);
    setErrorCode(false);
  };

  const retrySendCode = (event) => {
    setResentCode(true);
    onSubmit(event);
  };

  useEffect(() => {
    if (history.location.state && history.location.state.message) {
      const state = { ...history.location.state };
      delete state.message;
      history.replace({ ...history.location, state });
    }
  }, []);

  return (
    <VQAuthLogin
      header={(<CLAppHeaderOnlyLogo></CLAppHeaderOnlyLogo>)}
      isTwoFactor={isTwoFactor}
      telNumber={telNumber}
      authCode={authCode}
      errorCode={errorCode}

      setEmail={setEmail}
      setPassword={setPassword}

      isLoading={isLoading}
      isSubmit={isSubmit}
      invalidEmail={invalidEmail}
      invalidPassword={invalidPassword}
      isShowPassword={isShowPassword}
      message={history.location.state && history.location.state.message ? history.location.state.message : ""}
      isErrorLogin={isErrorLogin}
      onSubmit={onSubmit}
      email={email}
      password={password}
      recaptchaKey={recaptchaKey}

      showPassword={showPassword}
      forgotPass={forgotPass}
      handleVerifyRecaptcha={handleVerifyRecaptcha}
      getTwoFactorAuthenticationMessage={getTwoFactorAuthenticationMessage}
      handleOnChangeAuthCode={handleOnChangeAuthCode}
      onValidateCode={onValidateCode}
      retrySendCode={retrySendCode}
    ></VQAuthLogin>
  );
});
