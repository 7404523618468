import { CLColors } from "./colors";
import { CLOffsets } from "./offsets";
import { CLSize } from "./size";

export const theme = {
  colors: CLColors,
  offsets: CLOffsets,
  size: CLSize
};

export type CLTheme = typeof theme;
