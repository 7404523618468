import styled from "styled-components";

export const ClChartsEcgStyles = styled.div`
  position: relative;
  //min-height: 300px;
  width: 100%;

  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .chart-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    font-size: 16px;

    .selected-date {
      font-size: 16px;
      line-height: 20px;
      color: #484848;
    }
  }
  
  .dropdown{
    display: flex;
    .dropdown-icon-wrapper{
      margin-right: 8px;
    }
    //flex-direction: ;
  }
  .selected-date{
    text-transform: lowercase;
  }

  .holder {
    max-width: 100%;
    overflow: auto;

    & > div {
      //height: 250px;
      width: 100%;

      & > div {
        //height: 240px !important;
      }
    }
  }

  .holder-short {
    max-width: 100%;
    overflow: auto;
    padding: 20px 0 0;
    margin-top: -40px;
    background: #FFF;

    & > div {
      height: 80px;
      width: 100%;

      & > div {
        //height: 80px !important;
      }
    }
  }
`;
