import * as Types from "./api.types";
import API from "../../boot/Api";
import { encodeData } from "../handlers/data.handler";

export const getBillingData = async(
  startDate: string,
  endDate: string,
  isCalculateDuplicate: boolean,
  jsonFilter: any): Promise<any> => {
  const encodedData = encodeData({
    startDT: startDate,
    endDT: endDate,
    jsonFilter: JSON.stringify(jsonFilter),
    isCalculateDublicates: isCalculateDuplicate
  });
  const result = API.get(`HSData/BillingData?${encodedData}`);
  return await Types.getResponse(result);
};
export const setBilledApi = async(
  recordingId: string,
  isBilled: boolean): Promise<any> => {
  const encodedData = encodeData({
    recordingId: recordingId,
    isBilled: isBilled
  });
  const result = API.post(`HSData/SetBilled?${encodedData}`);
  return await Types.getResponse(result);
};

export const getUsageDataApi = async(startDate: string, endDate: string): Promise<any> => {
  const encodedData = encodeData({
    startDT: startDate,
    endDT: endDate
  });
  const result = API.get(`ClinicDevices/DeviceUsage?${encodedData}`);
  return await Types.getResponse(result);
};

export const getUtilizationDataApi = async(startDate: string, endDate: string): Promise<any> => {
  const encodedData = encodeData({
    startDT: startDate,
    endDT: endDate
  });
  const result = API.get(`ClinicDevices/DeviceUtilization?${encodedData}`);
  return await Types.getResponse(result);
};
