import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const BillingUtilizationStyle = styled.div`
  padding: ${theme.offsets.mainContentVertical} ${theme.offsets.mainContent};

  .br {
    height: 24px;
  }
`;
