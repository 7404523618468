import { IWiFiSetupModelSnapShot } from "./wifi-setup.store";

export const InitialState: IWiFiSetupModelSnapShot = {
  id: "0",
  ssid: {
    name: "SSID",
    value: "",
    type: "input",
    fieldId: "ssid"
  },
  password: {
    name: "Password",
    value: "",
    type: "password",
    fieldId: "password"
  },
  name: {
    name: "Name",
    value: "",
    type: "input",
    fieldId: "name"
  },
  email: {
    name: "Email",
    value: "",
    type: "input",
    fieldId: "email"
  },
  phoneNumber: {
    name: "Phone Number",
    value: "",
    type: "number",
    fieldId: "phoneNumber"
  }
};
