import { ClDevicesModalMoveWrapper } from "./cl-devices-modal-move.presents";
import { CLModalCloseBtnStyle } from "../shared/cl-modal/cl-modal.presents";
import { Modal } from "react-bootstrap";
import Close from "../../assets/icons/close.svg";
import { CLIcon } from "../shared/cl-icon";
import { CLLightButton, CLBlueButton } from "../shared/cl-button";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component/cl-dynamic-form-component";
import { IUserFormModel } from "../../stores/createUser/user.model";
import { message } from "antd";
import { confirmAlert } from "react-confirm-alert";

export const ClDevicesModalMove = observer(() => {
  const { selectedDevice, showMoveModal, hideMoveModal, moveDevice } = useRootStore().listDevicesStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { clinics } = useRootStore().userStore;
  const toClinicsList = clinics.filter((item: any) => {
    return !selectedDevice.Clinic || item.value !== selectedDevice.Clinic.Id;
  });

  const onSubmit = (event: any) => {
    event.preventDefault();
    setGlobalSpiner(true);
    moveDevice(selectedDevice.Id, event.target.toClinic.value)
      .then((result: any) => {
        if (result && result.error) {
          setGlobalSpiner(false);
          hideMoveModal();
          return message.error({ content: result.error, duration: 2 });
        }
        if (result) {
          hideMoveModal();
          confirmAlert({
            message: "Device moved!",
            overlayClassName: "cl-success-modal",
            buttons: [
              {
                label: "Ok",
                onClick: () => {}
              }
            ]
          });
        } else {
          message.error({ content: "Error, Something went wrong!", duration: 2 });
        }
        setGlobalSpiner(false);
      });
  };
  const handleChange = () => {};

  return (
    <Modal
      centered
      show={showMoveModal}
      dialogClassName="invite-admin-modal">
      <Modal.Body>
        <CLModalCloseBtnStyle onClick={hideMoveModal}>
          <CLIcon icon={Close}></CLIcon>
        </CLModalCloseBtnStyle>
        {selectedDevice && <ClDevicesModalMoveWrapper>
          <div className="form-container">
            <div className="title">Move Device</div>
            <form onSubmit={onSubmit}>
              <div className="fields-container">
                <CLDynamicFormComponent
                  item={{
                    name: "Serial Number",
                    value: selectedDevice.SerialNumber,
                    type: "input",
                    fieldId: "serial",
                    isDisabled: true
                  } as IUserFormModel}
                  customClass="invite-field"
                  handleChange={handleChange}></CLDynamicFormComponent>
                <CLDynamicFormComponent
                  item={{
                    name: "Current Clinic",
                    value: selectedDevice.Clinic ? selectedDevice.Clinic.Name : "",
                    type: "input",
                    fieldId: "curentClinic",
                    isDisabled: true
                  } as IUserFormModel}
                  customClass="invite-field"
                  handleChange={handleChange}></CLDynamicFormComponent>
                <CLDynamicFormComponent
                  item={{
                    name: "To Clinic",
                    value: toClinicsList[0],
                    defaultValue: toClinicsList[0],
                    type: "select",
                    options: toClinicsList,
                    fieldId: "toClinic"
                  } as IUserFormModel}
                  customClass="invite-field"
                  handleChange={handleChange}></CLDynamicFormComponent>
              </div>
              <div className="btn-container">
                <CLLightButton type="button" text="Cancel" click={hideMoveModal}/>
                <CLBlueButton type="submit" text="Move Device" click={() => {}}/>
              </div>
            </form>
          </div>
        </ClDevicesModalMoveWrapper>}
      </Modal.Body>
    </Modal>
  );
});
