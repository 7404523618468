import API from "../../boot/Api";
import * as Types from "./api.types";
import { encodeData } from "../handlers/data.handler";

export const getClinicCellPhoneApi = async(): Promise<Types.ApiResponse> => {
  const PortalUserAC = localStorage.getItem("PortalUserAC");
  const result = API.get("/PortalUser/ClinicCellPhone?idClinic=" + PortalUserAC);
  return await Types.getResponse(result);
};
export const saveClinicCellPhoneApi = async(phone: string | number): Promise<Types.ApiResponse> => {
  const PortalUserAC = localStorage.getItem("PortalUserAC");
  const encodedData = encodeData({
    idClinic: PortalUserAC,
    clinicCellPhone: phone
  });
  const result = API.post("/PortalUser/UpdateClinicCellPhone", encodedData);
  return await Types.getResponse(result);
};

export const getTotalAdministrationCountApi = async(jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("/PortalUser/UsersCounter", {
    params: { jsonFilter: jsonFilter }
  });
  return await Types.getResponse(result);
};

export const getListAdministrationApi = async(jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("/PortalUser/AllUsers", {
    params: { jsonFilter: jsonFilter }
  });
  return await Types.getResponse(result);
};

export const inviteAdminApi = async(data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    jsonInviteData: data
  });
  const result = API.post(`/PortalUser/Invite?${encodedData}`);
  return await Types.getResponse(result);
};

export const studyEnrollmentChangeApi = async(id: string, data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    recordingId: id,
    requestInfo: data
  });
  const result = API.post(`/PortalUser/StudyEnrollmentChange?${encodedData}`);
  return await Types.getResponse(result);
};

export const updateAdminApi = async(data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    jsonPortalUserCP: data
  });
  const result = API.post(`/PortalUser/Update?${encodedData}`);
  return await Types.getResponse(result);
};
export const updateWIFIAdminApi = async(data: any, params: any): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    itContactInfoJson: params,
    ...data
  });
  const result = API.post("/PortalUser/UpdateWiFiParameters", encodedData);
  return await Types.getResponse(result);
};

export const deleteAdminApi = async(data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    jsonPortalUserCP: data
  });
  const result = API.post(`/PortalUser/Delete?${encodedData}`);
  return await Types.getResponse(result);
};

export const changeRoleApi = async(id: string | number, inviting: boolean): Promise<Types.ApiResponse> => {
  const result = API.post("/PortalUser/SetInvited", { id, isInvite: inviting });
  return await Types.getResponse(result);
};

export const getAllIndicationsApi = async(): Promise<Types.ApiResponse> => {
  const result = API.get("/ClinicPatients/AllIndications");
  return await Types.getResponse(result);
};

export const getDefaultIndicationsAPI = async(): Promise<Types.ApiResponse> => {
  const result = API.get("/ClinicPatients/DefaultIndications");
  return await Types.getResponse(result);
};

export const getClinicIndicationsAPI = async(): Promise<Types.ApiResponse> => {
  const result = API.get("/ClinicPatients/ClinicIndications");
  return await Types.getResponse(result);
};

export const updateClinicIndicationsAPI = async(data: any): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    jsonIds: data
  });
  const result = API.post(`/ClinicPatients/UpdateClinicIndications?${encodedData}`);
  return await Types.getResponse(result);
};

export const updatePhysicianNPI
  = async(idPhysician: string | number, NPI: string | number): Promise<Types.ApiResponse> => {
    const encodedData = encodeData({
      idPhysician: idPhysician,
      NPI: NPI
    });
    const result = API.post("/ClinicPhysicians/UpdatePhysicianNPI", encodedData);
    return await Types.getResponse(result);
  };
