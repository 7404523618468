import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const TooltipStyle = styled.div`
  .cl-tooltip {
    border: 1px solid ${theme.colors.background};
    border-radius: 4px;
    opacity: 1;
    background: ${theme.colors.light};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.text};
    padding: 8px;
    opacity: 1 !important;
  }

  .cl-tooltip::after,
  .cl-tooltip::before {
    content: none;
  }
  .cl-tooltip::before {
    height: 7px !important;
  }
  .cl-tooltip::after {
    border-right: 1px solid ${theme.colors.background} !important;
    border-top: 1px solid ${theme.colors.background} !important;
  }
`;
