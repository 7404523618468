import styled from "styled-components";
import { theme } from "../../../theme/theme";
import { PropsType } from "./cl-spinner.props";

export const SpinerStyle = styled.div<PropsType>`
  height: ${props => props.containerHeight ? props.containerHeight : "120px"};
  width: ${props => props.containerWidth ? props.containerWidth : "120px"};

  box-shadow: ${props => props.boxShadow
    ? props.boxShadow
    : "0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05)"};
  border-radius: 16px;
  position: relative;
  background: ${theme.colors.light};

`;
