import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLPatiensCreateWrapper = styled.div`
 position: relative;
  padding: 20px 60px;
  background: #FFF;

  .vq-multiselect__menu {
    padding: 0 !important;

    .vq-multiselect__menu-list {
      padding: 0 16px;
    }
  }
  .extra-padding {
    padding: 0 10px;
    width: 100%;
    display: flex;
    & > div {
      width: 33%;
      flex-wrap: wrap;
      padding: 10px;
      position: relative;
    }
  }
  .button-container {
    padding: 0 10px;
  }
  .width-33 {
    width: 33%;
  }
  .padding-10 {
    padding: 10px !important;
  }
  .container{
    padding-top: 20px;
    padding-bottom: 200px;
    display: flex;
    border-bottom: 1px solid ${theme.colors.background};
    max-width: 1024px;
    margin: 0 auto;
    // align-items: center;
    flex-wrap: wrap;
    position: relative;
  }
  .upload-container {
    position: absolute;
    right: 0;
  }
  .back{
    position: absolute;
    top: 42px;
    left:20px;
    cursor: pointer;
  }
  .pagination {
    padding-bottom: 7px;
    margin-left: auto;
  }
  
  .field {
    margin-top: 16px;
    padding: 10px;
    .label {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 18px;
    }
    label {
      margin-bottom: 10px;
    }
  }

  h1 {
    flex: 0 0 100%;
    font-size: 24px;
    line-height: 28px;
    padding: 0 10px;
  }
  .link {
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
    color: ${theme.colors.blue};
  }
  .patient-create-block {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .button-holder {
    margin-right: 20px;
  }
  
  h3 {
    flex: 0 0 100%;
    margin: 20px 0;
    padding: 0 10px;
    font-size: 16px;
    line-height: 18px;
  }

  .cl-select__value-container--is-multi.cl-select__value-container--has-value .cl-select__multi-value{
    display: flex !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .form-block {
    width: 33%;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;

    label {
      font-size: 12px;
      line-height: 14px;
      width: 100%;
      position: relative;
    }

    .optional-flag {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 12px;
      line-height: 14px;
      color: #8A8A8A;
    }

    .checkbox-name {
      font-size: 14px;
      line-height: 16px;
    }

    &.invalid {
      input {
        border: 1px solid ${theme.colors.red};
      }

      .ant-picker {
        border: 1px solid ${theme.colors.red};

        input {
          border: 0;
        }
      }

      .cl-select__control {
        border: 1px solid ${theme.colors.red} !important;
      }
    }
  }

  .from-group-state {
    width: 33%;
    flex-wrap: wrap;
    padding: 0;
    display: flex;

    .form-block {
      width: 100%;
    }

    .form-control-state {
      width: 50%;
      flex-wrap: wrap;
    }

    .form-control-zip {
      width: 50%;
      flex-wrap: wrap;
    }
  }

  .ant-picker {
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #E5E5E5;
    color: #484848;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px;
    width: 100%;
  }

  .w100 {
    width: 100%;
  }
`;

export const CLPatiensCreateModalWrapper = styled.div`
  .modal-container{
    padding: 40px;
  }
  .footer{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .btn-wrapper{
      &:first-child{
        margin-right: 24px;
      }
    }
  }
  .title{
    font-size: 24px;
    line-height: 28px;
    color: #484848;
    margin-bottom: 16px;
  }

  .description{
    font-size: 14px;
    line-height: 16px;
  }
`;

export const CLPatiensCreateDublicateModalWrapper = styled(CLPatiensCreateModalWrapper)`

  .list-dublicate {
    max-height: 500px;
    overflow: auto;
    margin-top: 48px;
    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 16px;
      border-top: 1px solid ${theme.colors.background};

      .name {
        font-size: 18px;
      }
    }

    .list-item:last-child {
      border-bottom: 1px solid ${theme.colors.background};
    }
  }
`;
