import moment from "moment";

// "1/11/2021 16:19"
export const formatDate = (date: string, checkPending?: boolean) => {
  if (checkPending && moment(date).year() === 1) {
    return "Pending";
  } else if (moment(date).year() === 1) {
    return "-";
  }

  return moment(date).format("M/DD/YYYY hh:mm");
};

export const isNullDate = (date: string) => {
  return !date || moment(date).year() === 1;
};

// "1/11/2021 16:19:00"
export const formatDateSeconds = (date: string) => {
  return moment(date).format("M/DD/YYYY hh:mm:ss a");
};

// 16:19:00"
export const formatDateMinutesSeconds = (date: string) => {
  return moment(date).format("hh:mm:ss a");
};

// "November 29, 2020"
export const formatDateWithNameMonth = (date: string) => {
  return moment(date).format("MMMM DD, YYYY");
};

// "09:14:21 pm"
export const formatTimeReport = (date: string) => {
  return moment(date).format("hh:mm:ss a");
};

// "December 1, 09:14:21 pm"
export const formatTimeWithNameMonth = (date: string) => {
  return moment(date).format("MMMM DD, hh:mm:ss a");
};

// "Jan 1"
export const formatDateWithShortNameMonth = (date: string) => {
  return moment(date).format("MMM D");
};

export const diffCount = (start: string, end: string, type: moment.unitOfTime.Diff = "days") => {
  return moment(end).diff(moment(start), type);
};

export const getProgresOfDaysText = (start: string, end: string) => {
  const allDays = diffCount(start, end);
  const getCurentDay = diffCount(start, moment().format());

  return `Day ${getCurentDay > allDays ? allDays : getCurentDay} of ${allDays}`;
};

export const getProgresOfDaysPercentage = (start: string, end: string) => {
  const allDays = diffCount(start, end);
  const getCurentDay = diffCount(start, moment().format());
  const percentage = getCurentDay / allDays * 100;

  return percentage > 100 ? 100 : percentage;
};

export const getCountDaysByDates = (start: string, end?: string) => {
  return (end ? moment(end) : moment()).diff(start, "days");
};

// 00:05:00:20
export const secondsToTimeString = (delta: number) => {
  const minTwoDigits = (num: number) => {
    return (num < 10 ? "0" : "") + num;
  };

  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  const seconds = Math.floor(delta % 60);

  return `${minTwoDigits(days)}:${minTwoDigits(hours)}:${minTwoDigits(minutes)}:${minTwoDigits(seconds)}`;
};
