import { types, applySnapshot, flow, Instance, SnapshotIn } from "mobx-state-tree";
import {
  getAllIndicationsApi,
  getClinicIndicationsAPI, updateClinicIndicationsAPI
} from "../../services/api/administration.api";
import { InitialStateAdminICD10 } from "./initial.state";

export const AdminICD10Model = types
  .model("AdminICD10Model", {
    id: types.identifier,
    countActive: types.optional(types.number, 0),
    show: types.optional(types.boolean, false),
    icd10: types.frozen([])
  })
  .actions(self => {
    const setActiveIcd = (arr: any) => {
      self.icd10 = arr;
    };
    const setCount = (count: number) => {
      self.countActive = count;
    };

    return {
      setActiveIcd,
      setCount
    };
  })
  .actions(self => {
    const resetForm = () => {
      applySnapshot(self, InitialStateAdminICD10);
    };
    const setChecked = (active: any, value: string) => {
      const activeItem: any = self.icd10.find((item: any) => item.id === active.id);
      if (activeItem) {
        activeItem.isChecked = value;
      }
      if (value === "true") {
        self.setCount(self.countActive + 1);
      } else {
        self.setCount(self.countActive - 1);
      }
    };
    const initForm = async() => {
      const all: any = await getAllIndicationsApi();
      const selected: any = await getClinicIndicationsAPI();
      let count = 0;
      const resultArr = all.data.map((item: any) => {
        const isCheckedItemExist = selected.data.find((activeValue: any) => item.Id === activeValue.Id);
        isCheckedItemExist && count++;
        return {
          value: item.Code + " " + item.Description,
          isChecked: isCheckedItemExist && "true" || "false",
          id: item.Id + ""
        };
      });
      self.setCount(count);
      self.setActiveIcd(resultArr);
    };
    const openModalICD10 = async() => {
      self.show = true;
    };

    const closeModal = () => {
      self.show = false;
      resetForm();
    };

    const saveForm = flow(function* saveForm() {
      const arrActiveItems = self.icd10.filter((item: any) => item.isChecked === "true");
      const str = JSON.stringify(arrActiveItems.map((item: any) => item.id));
      try {
        const response = yield updateClinicIndicationsAPI(str);
        if (response.ok && response.data) {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    });
    return {
      openModalICD10,
      closeModal,
      initForm,
      setChecked,
      saveForm
    };
  });

export interface IAdminICD10Model extends Instance<typeof AdminICD10Model> {}
export interface IAdminModelICD10SnapShot extends SnapshotIn<typeof AdminICD10Model> {}
