import { BeatsType } from "../cl-charts-canvas/cl-charts-canvas.service";

export const getNumbersChart = (Gstate: any, data: any, from: number, to: number) => {
  if (data.Intervals && data.Intervals.length) {
    const returnData = [];
    const chartData: BeatsType[] = [];
    for (let i = 0; i < data.Intervals.length; i++) {
      const pick = data.Intervals[i].RPeakLocation;
      if (+pick > from && +pick < to) {
        returnData.push(data.Intervals[i]);
      }
    }
    for (let i = 0; i < returnData.length; i++) {
      chartData.push({
        x: (returnData[i].RPeakLocation - from) * 5 + 80,
        y: 1.35 * Gstate.mmmV,
        indexLabel: returnData[i].HrInterval + ""
      });
    }
    return chartData;
  }
};

export const get8SecondData = (Gstate: any, time: number, dataBeats2: any) => {
  const beats = dataBeats2 || Gstate.dataBeats;
  let from = (time - 4000) / 5;
  let to = (time + 4000) / 5;
  const data1: BeatsType[] = [];
  const data2: BeatsType[] = [];
  if (from < 0) {
    from = 0;
    to = (8000) / 5;
  }

  if (beats.DataSecondary && beats.DataPrimary) {
    let n = 0;
    for (let i = from; i < to; i++) {
      n++;
      if (beats.DataSecondary[i] || beats.DataSecondary[i] === 0) {
        data1.push({ x: n * 5, y: beats.DataSecondary[i] - Gstate.mV });
      }
      if (beats.DataPrimary[i] || beats.DataPrimary[i] === 0) {
        data2.push({ x: n * 5, y: beats.DataPrimary[i] + Gstate.mV });
      }
    }
  }

  const dataNumbers = getNumbersChart(Gstate, beats, from, to);

  return {
    data1,
    data2,
    dataNumbers
  };
};

export const setEventChart = (EventStart: number) => {
  const points = [
    { x: EventStart * 5, y: -0.49 },
    { x: EventStart * 5, y: 0.5 },
    { x: EventStart * 5 + 8000, y: 0.5 },
    { x: EventStart * 5 + 8000, y: -0.49 },
    { x: EventStart * 5, y: -0.49 }
  ];
  return points;
};

export const getStripLines = (Gstate: any, stripLines: any, stripLinesShort: any) => {
  if (Gstate && Gstate.dataShort && Gstate.data) {
    const countStripesShort = Math.round(Gstate.dataShort.length * 10 / 1000);
    let n = (Math.floor(Gstate.data[0].x / 500) + 0) * 500;
    while (n < Gstate.data[Gstate.data.length - 1].x) {
      stripLines.push({ value: n, color: "rgb(237, 105, 226)" });
      n += 200;
    }
    stripLines[0].value = 10;
    for (let i = 0; i < countStripesShort; i++) {
      stripLinesShort.push({ value: i * 1000, color: "#000000" });
    }
  }
};

export const findBiggestPoint = (Gstate: any) => {
  let isAllPointBig = true;
  Gstate.dataShort.forEach((item: any) => {
    if (item.y < 0.5 && item.y > 0) {
      isAllPointBig = false;
    }
  });
  return isAllPointBig;
};

export const findActiveEvent = (events: any, searchEvent: any) => {
  if (searchEvent) {
    for (let i = 0; i < events.length; i++) {
      for (let j = 0; j < events[i].data.length; j++) {
        const activeItem = events[i].data[j];
        if (+activeItem.Id === +searchEvent) {
          return activeItem;
        }
      }
    }
  }
  for (let i = 0; i < events.length; i++) {
    for (let j = 0; j < events[i].data.length; j++) {
      const activeItem = events[i].data[j];
      if (activeItem.EventActionCP !== 3 && activeItem.EventSource === 77) {
        return activeItem;
      }
    }
  }
  for (let i = 0; i < events.length; i++) {
    for (let j = 0; j < events[i].data.length; j++) {
      const activeItem = events[i].data[j];
      if (activeItem.EventActionCP !== 3) {
        return activeItem;
      }
    }
  }
  if (events && events[0] && events[0].data && events[0].data[0] && events[0].data[0].FolderName) {
    return events[0].data[0];
  }
};
