import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLDevicesListCategoriesWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  border-bottom: 1px solid ${theme.colors.background};
  align-items: flex-end;
  justify-content: space-between;
  .nav {
    display: flex;
    align-items: flex-end;
  }
  .pagination {
    padding-bottom: 7px;
    
  }

  .right-block {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }
  .contacts {
    .info {
      padding-bottom: 16px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: ${theme.colors.text};
      }

      .content {
        display: flex;
        flex-wrap: wrap;

        .item {
          margin-right: 32px;
        }

        .item:last-child {
          margin-right: 0;
        }

        .content-title {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: ${theme.colors.placeholder};
          padding-top: 16px;
          padding-bottom: 8px;
        }

        .content-value {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: ${theme.colors.text};
        }
      }
    }
  }
`;
