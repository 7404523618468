import { PropsType } from "./cl-select-search.props";
import { SelectStyle } from "../cl-select/cl-select.presents";
import Select, { components } from "react-select";
import React, { useEffect, useRef, useState } from "react";
import { CLInput } from "../cl-input";

const { MenuList } = components;

const CustomMenuList = ({ selectProps, ...props }: any) => {
  const { onInputChange, inputValue, onMenuInputFocus } = selectProps;

  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"]
  };

  return (
    <div className="search-input-wrapper">
      <CLInput
        testId={"inputSearchId"}
        type="text"
        value={inputValue}
        handleChange={(e: any) =>
          onInputChange(e.currentTarget.value, {
            action: "input-change"
          })
        }
        onMouseDown={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          e.target?.focus();
        }}
        onTouchEnd={(e: any) => {
          e.stopPropagation();
          e.target?.focus();
        }}
        onFocus={onMenuInputFocus}
        placeholder="Search..."
        {...ariaAttributes}
      />
      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};
const getSelect = (props: PropsType, type?: string) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const containerRef = useRef<any>(null);
  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);
    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);
  const onSelect = (option: any) => {
    props.selectOption(option);
  };

  const onInputChange = (search: string) => {
    setInputValue(search);
  };

  const onMenuOpen = () => {
    props.onMenuOpen && props.onMenuOpen();
  };
  const onMenuClose = () => {
    props.onMenuClose && props.onMenuClose();
  };
  const onDomClick = (e: any) => {
    const menu = containerRef.current.querySelector(".cl-select__menu");
    if (!containerRef.current.contains(e.target) || !menu || !menu.contains(e.target)) {
      setIsFocused(false);
      setInputValue("");
    }
  };
  const selectProps: any = {
    isMulti: props.isMulti,
    isSearchable: false,
    closeMenuOnSelect: props.closeMenuOnSelect,
    isClearable: true,
    options: props.options,
    onInputChange: onInputChange,
    onChange: onSelect,
    onMenuOpen: onMenuOpen,
    onMenuClose: onMenuClose,
    hideSelectedOptions: !props.showSelectedOptions,
    defaultValue: props.defaultValue,
    name: props.name || "select",
    onMenuInputFocus: () => setIsFocused(true),
    components: {
      MenuList: CustomMenuList
    },
    menuIsOpen: isFocused || undefined,
    isFocused: isFocused || undefined,
    inputValue: inputValue,
    isDisabled: props.isDisabled,
    classNamePrefix: "cl-select",
    placeholder: props.placeholder ? props.placeholder : "Select..."
  };

  if (props.value) {
    selectProps.value = props.value;
  }
  return (
    <SelectStyle width={props.width} padding={props.padding} type={type}>
      <div ref={containerRef}>
        <Select {...selectProps}></Select>
      </div>
    </SelectStyle>
  );
};

export const ClSelectSearch = (props: PropsType) => {
  return getSelect(props);
};
