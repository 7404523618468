import { observer } from "mobx-react-lite";
import { SuportModalContainerStyle, SupportStyle } from "./Support.presents";
import { CLCard } from "../../components/shared/cl-card";
import { SupportInfo, SupportInfoCVAUSA } from "../../boot/Constants";
import { useRootStore } from "../../stores/storeContext";
import { useEffect, useState } from "react";
import { CLIcon } from "../../components/shared/cl-icon";
import PdfIcon from "../../assets/icons/pdf.svg";
import ReactPlayer from "react-player";
import previewSupportVideo from "../../assets/img/preview-support-video.jpg";
import preview1SupportVideo from "../../assets/img/preview_1_supprort_video.jpg";
import { CLBlueButton, CLLightButton } from "../../components/shared/cl-button";
import { CLModal } from "../../components/shared/cl-modal";
import { ClAlert } from "../../components/shared/cl-alert";
import { isCVAUSAMC } from "../../stores/patientInfo/patientInfo.helper.service";

export const SupportPage = observer(() => {
  const { setGlobalSpiner } = useRootStore().globalStore;
  const {
    getFiles,
    getVideos,
    updateNews,
    files,
    videos,
    downloadURI,
    getNews,
    news,
    stringNews
  } = useRootStore().supportStore;
  const { IdCurrentMC, usersGroupSTR } = useRootStore().userStore;
  const isSuperUser = usersGroupSTR === "Superuser";
  const [isModalShow, setIsModalShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [newsModel, setNewsModel] = useState(stringNews || "");
  const [isNewsValid, setIsNewsValid] = useState(true);
  const isROCMC = IdCurrentMC + "" === "20";
  const isCVAUSA = isCVAUSAMC(IdCurrentMC);
  const supportInfoData = isCVAUSA ? SupportInfoCVAUSA : SupportInfo;

  const handleNewsChange = (event) => {
    setNewsModel(event.target.value);
  };

  useEffect(() => {
    setNewsModel(stringNews);
  }, [stringNews]);

  useEffect(() => {
    setGlobalSpiner(true);
    Promise.all([getFiles(), getVideos(), getNews()]).then(() => { setGlobalSpiner(false); });
  }, []);

  const handleDownloadClick = (ev, file) => {
    ev.preventDefault();
    downloadURI(file.Name, file.URL);
  };

  const getItemValue = (item) => {
    const value = item.valueFunction && item.valueFunction(IdCurrentMC) || item.value;
    if (item.type === "email") {
      return (<div className="content-value">
        <a href={`mailto:${value}`}>{value}</a>
      </div>);
    } else {
      return (<div className="content-value">{value}</div>);
    }
  };

  const getItemTitle = (item) => {
    const value = item.titleFunction && item.titleFunction(IdCurrentMC) || item.title;
    return (<div className="content-title">{value}</div>);
  };

  const openEditNewsModal = () => {
    setIsModalShow(true);
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
      setIsNewsValid(true);
      return true;
    } catch (e) {
      setIsNewsValid(false);
      return false;
    }
  };

  const saveNewChanges = async() => {
    if (isJsonString(newsModel)) {
      setGlobalSpiner(true);
      const result = await updateNews(newsModel);
      if (result) {
        closeModal();
        await getNews();
        setGlobalSpiner(false);
      } else {
        setGlobalSpiner(false);
      }
    } else {
      setShowAlert(true);
    }
  };

  const closeModal = () => {
    setIsModalShow(false);
  };

  return (
    <SupportStyle>
      <div className={!isROCMC ? "left-content" : "left-content centered-content"}>
        <div className="contacts">
          <CLCard padding="24px">
            {supportInfoData.map((info, index1) => <div className="info" key={"info_" + index1}>
              <div className="title">{info.title}</div>
              <div className="content">
                {info.content.map((item, index2) => <div
                  className="item"
                  key={"info_" + index1 + "_content_" + index2}>
                  {getItemTitle(item)}
                  {getItemValue(item)}
                </div>)}
              </div>
            </div>)}
          </CLCard>
        </div>
        <div className="hint">
          <CLCard padding="24px">
            <div className="info" key={"hint"}>
              <div className="title">Hint</div>
              <div className="content">
                <p>For report change request, right-click a study on Patient page,
                  select Edit Enrollment and fill in required fields.</p>
              </div>
            </div>
          </CLCard>
        </div>
        <div>
          {news.length
            ? <div className="news">
              <CLCard padding="24px">
                <div className="title">What’s new?</div>
                {news.map((info, index) => <div className="info" key={"news_" + index}>
                  <div className="date">{info.date}</div>
                  <div className="title">{info.title}</div>
                  <div className="text">{info.text}</div>
                  <ul className="list">{
                    info.list && info.list.map((listItem, index2) => <li
                      key={"news_" + index + "_list_" + index2}>
                      {listItem}</li>)
                  }</ul>
                </div>)}
              </CLCard>
            </div>
            : null}
          {isSuperUser
            ? <div className={"edit-news-block"}><CLBlueButton
              width="100%"
              text="Edit What's New"
              click={openEditNewsModal}>
            </CLBlueButton></div>
            : null}
        </div>
      </div>
      {!isROCMC && <div className="right-content">
        <div className="specifications">
          <CLCard padding="24px">
            <div className="title">Specifications</div>
            {files && <div className="files">
              {files.map((item, index) => <div
                className="file"
                key={"file_" + index}
                onClick={(ev) => { handleDownloadClick(ev, item); }}>
                <CLIcon icon={PdfIcon} height="52px" width="52px"></CLIcon>
                <div className="name">{item.Name}</div>
              </div>)}
            </div>}
          </CLCard>
        </div>
        <div className="video-section">
          <CLCard padding="24px">
            <div className="title">Training video</div>
            {videos.map((item, index) => <div key={"video_" + index}>
              <div className="name">{item.Name}</div>
              <div className="content">
                <ReactPlayer
                  controls={true}
                  width="100%"
                  height="100%"
                  light={index === 0 ? preview1SupportVideo : previewSupportVideo}
                  url={item.URL}
                  playing={true} />
              </div>
            </div>)}
          </CLCard>
        </div>
      </div>}

      <ClAlert
        show={showAlert}
        onHide={() => { setShowAlert(false); }}
        msg={"News section has wrong format, please double check"}/>

      <CLModal show={isModalShow} handleHideModal={closeModal} title={"Edit What's New"}>
        <SuportModalContainerStyle>
          <div className="modal-container" id="modal-container">
            <div className="title">
              Edit What's New
            </div>
            <div className="whats-new">
              <textarea
                className={isNewsValid ? "" : "error-textarea"}
                placeholder="Enter report summary"
                onChange={handleNewsChange}
                value={newsModel}></textarea>
            </div>
            <div className="footer">
              <div className="btn-wrapper">
                <CLLightButton click={closeModal} text="Cancel" width="200px"></CLLightButton>
              </div>

              <div className={"btn-wrapper"}>
                <CLBlueButton click={saveNewChanges} text="Save" width="200px"></CLBlueButton>
              </div>
            </div>
          </div>
        </SuportModalContainerStyle>

      </CLModal>
    </SupportStyle>
  );
});
