import styled from "styled-components";

export const CLReportsListHeaderWrapper = styled.div`
  .group {
    display: flex;
    align-items: flex-end;
  }

  .margin-block-right {
    margin-right: 24px;
  }

  .filter-group {
    display: flex;
    width: 100%;
    padding-top: 16px;
    & > div {
      margin-right: 16px;
    }
  }
  .create {
    margin-left: auto;
  }

  .pagination {
    margin-left: auto;
  }

  .date-picker{
    display: flex;
    .icon {
      display: flex;
      align-items: center;
    }
    .ant-picker {
      border: 0;
      .ant-picker-input > input {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        width: 85px;
      }
    }
    .ant-picker-range-separator{
      //display: none;
      text-align: center;
      padding: 0;
      .anticon {
        width: 6px;
        height: 7px;
        border-top: 1px solid rgba(0, 0, 0, 0.85);
        margin: 0 auto;
        svg {
          display: none;
        }
      }
      
    }
    .ant-picker-suffix {
      display: none;
    }
  }
`;
