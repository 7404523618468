import styled from "styled-components";

export const PatientInfoCreateEventsStyle = styled.div`
  position: absolute;
`;

export const PatientInfoCreateEventsModalContainerStyle = styled.div`
  .modal-container {
    padding: 40px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .btn-wrapper {
      //margin: 0 12px;
      &:first-child {
        margin-right: 24px;
      }

      &.disabled {
        button {
          background: #7b7b7b;
          pointer-events: none;
        }

      }
    }
  }

  .title {
    position: absolute;
    top: 40px;
    left: 40px;
    font-size: 24px;
    line-height: 28px;
    color: #484848;
  }

  #chart-ecg-preview {
    overflow: visible !important;
    //width: 600px !important;
    //height: 330px !important;
  }
  .chart-time{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-left: 6px;
  }
  .chart-ecg,
  .chart-hr {
    width: 100%;
    max-width: 100%;
    margin: 20px 0;
    padding: 10px;

    img {
      width: 100%;
      display: block;
    }
  }

  .form {
    max-width: 440px;
    margin: 20px auto;
  }

  .form-field {
    margin: 20px 0;

    .ant-picker-range {
      width: 100%;
      height: 48px;
      border-radius: 10px;
      border: 1px solid #E5E5E5;

      &.ant-picker-focused {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 5%), -4px -4px 20px rgb(0 0 0 / 5%);
      }

      .ant-picker-panel-container {
        border-radius: 10px;
      }
    }
  }

  .footer {
    margin-top: 60px;
  }

  .tabs {
    //display: flex;
    //margin: 20px;
    padding-top: 15px;
  }

  .tab-nav {
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex: 0 0 1;
    align-items: center;
    justify-content: center;

    & > li {
      list-style: none;
      display: block;
      padding: 16px;
      width: 140px;
      text-align: center;
      border: 1px solid #E5E5E5;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #484848;

      &:first-child {
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }

      &.active {
        background: #457EF5;
        border: 1px solid #457EF5;
        color: #FFFFFF;
      }
    }
  }
`;
