import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClAppHeaderNotificationWraper = styled.div<{isOpen?:boolean}>`
  display: ${props => props.isOpen ? "block" : "none"};
  background: ${theme.colors.light};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 24px;
  position: absolute;
  z-index: 11;
  width: 430px;
  height: 400px;
  right: ${theme.offsets.mainContent};
  top: calc(${theme.size.header} + 11px);
  cursor: default;
  overflow: hidden;
  .problem-devices-block {
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    border-bottom: 1px solid ${theme.colors.greyBorder};
    &:hover {
      .title {
        color: ${theme.colors.blue};
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 18px;
    color: ${theme.colors.text};
    padding-bottom: 8px;
  }

  .not-found {
    .title {
      font-weight: 300;
      font-size: 14px;
      color: ${theme.colors.text};
    }
  }

  .list {
    overflow: auto;
    height: 330px;
    
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      opacity: 0.7;
      padding-top: 16px;
      padding-bottom: 8px;
    }

    .notification-item {
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      cursor: pointer;
      border-bottom: 1px solid ${theme.colors.background};

      .icon {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #E7E7E7;

        &.new {
          background: #FFEAEA;
        }
      }

      .info {
        padding-left: 16px;
        display: flex;
        flex-direction: column;

        .caption {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: ${theme.colors.text};
          padding-bottom: 4px;
        }
  
        .date {
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: ${theme.colors.duration};
          padding-bottom: 4px;
        }

        .date:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
`;
