import { Modal } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { PropsType } from "./cl-notes-modal.props";
import { CLModalCloseBtnStyle } from "../shared/cl-modal/cl-modal.presents";
import Close from "../../assets/icons/close.svg";
import { CLIcon } from "../shared/cl-icon";
import { ClNoteModalWrapper } from "./cl-notes-modal.presents";
import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { useState } from "react";

export const CLNotesModal = observer((props: PropsType) => {
  const [physicianNote, setPhysicianNote] = useState(props.physicianNote || "");
  const handleSummaryChange = (event: any) => {
    setPhysicianNote(event.target.value);
  };

  const saveChanges = async() => {
    props.onSaveComment?.(physicianNote);
  };

  const isDisabled = physicianNote.length > 350;

  return (
    <Modal
      centered
      show={props.isShow}
      dialogClassName="invite-admin-modal">
      <Modal.Body>
        <CLModalCloseBtnStyle onClick={() => props.closeModal()}>
          <CLIcon icon={Close}></CLIcon>
        </CLModalCloseBtnStyle>
        <ClNoteModalWrapper>
          <div className="form-container">
            <div className="title">Notes</div>
            <div className="wrapper">
              <div className="note-title">
                <p>Operators Note</p>
                <p className="text-size">Max 1600 charts</p>
              </div>
              <div className="note-container">{props.note}</div>
            </div>
            {!props.isOperatorOnlyView
              ? <div className="wrapper">
                <div className="note-title">
                  <p>Physician Note</p>
                  <p className={isDisabled ? "text-error" : "text-size"}>Max 350 charts</p>
                </div>
                <textarea
                  className="note-textarea"
                  placeholder="Enter report summary"
                  value={physicianNote}
                  onChange={handleSummaryChange}></textarea>
              </div>
              : null}
            <div className="btn-container">
              <CLLightButton type="button" text="Close" click={() => props.closeModal()}/>
              {!props.isOperatorOnlyView
                ? <CLBlueButton
                  isDisabled={isDisabled}
                  type="submit"
                  text="Save" click={saveChanges}/>
                : null}
            </div>
          </div>
        </ClNoteModalWrapper>
      </Modal.Body>
    </Modal>
  );
});
