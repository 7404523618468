import styled from "styled-components";

export const PatientStudyStyle = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: #FFF;
  margin-top: 40px;
  border-radius: 16px;
  margin-bottom: 150px;
  box-shadow: 8px 8px 16px rgb(0 0 0 / 4%);
  min-height: 300px;
  &.no-shadow {
    box-shadow: none;
  }
  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;
