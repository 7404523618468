import { cast, flow, types } from "mobx-state-tree";
import API from "../boot/Api";
import { encodeData } from "../services/handlers/data.handler";

export const UploadInsuranceCardImagesModel = types
  .model("UploadInsuranceCardImagesModel", {
    show: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    isUploaded: types.optional(types.boolean, false),
    progressUpload: types.optional(types.number, 0),
    errorMessages: types.maybeNull(types.array(types.string))
  })
  .actions(self => ({
    setProgress(progress: number) {
      self.progressUpload = progress;
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setUploaded(isUploaded: boolean) {
      self.isUploaded = isUploaded;
    }
  }))
  .actions(self => {
    const resetForm = () => {
      self.isLoading = false;
      self.isUploaded = false;
      self.setProgress(0);
      self.errorMessages = null;
    };

    const openModal = () => {
      self.show = true;
    };

    const closeModal = () => {
      self.show = false;
      resetForm();
    };

    const addImages = flow(function* addImages(files: FileList, idPatient: number) {
      try {
        self.setLoading(true);
        const formData = new FormData();
        Array.from(files).forEach((file: File) => {
          formData.append("files[]", file);
        });
        const encodedData = encodeData({
          idPatient: idPatient
        });
        const result = yield API.post(`PortalUser/UploadInsuranceCardInfo?${encodedData}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            self.setProgress(percentCompleted);
          }
        });

        setTimeout(() => {
          self.setLoading(false);
          self.setUploaded(false);
        }, 1000);

        if (result?.status === 200) {
          self.errorMessages = null;
          resetForm();
          return true;
        }
      } catch (e) {
        console.error(e);
        self.errorMessages = cast(["Error, Something went wrong!"]);
        self.isLoading = false;
        self.isUploaded = false;
        return false;
      }
    });
    // const getImageCount = flow(function* getImageCount(idPatient: number) {
    //   const encodedData = encodeData({
    //     idPatient
    //   });
    //   const result = yield API.post(`ClinicPatients/GetPatientAdditionalInfoCountCardImages?${encodedData}`);
    //   if (result.ok) {
    //     console.log("reault", result.data);
    //   }
    // });
    const getImageData = flow(function* getImageCount(idPatient: number) {
      const encodedData = encodeData({
        idPatient
      });
      const result = yield API.post("ClinicPatients/GetPatientAdditionalInfoCardImage", encodedData);
      if (result.status === 200) {
        return result.data;
      }
    });
    const deleteImageData = flow(function* getImageCount(idPatient: number, imageUrl: string) {
      self.setLoading(true);
      const result = yield API.delete("ClinicPatients/DeleteAdditionalInfoCardImage", {
        data: {
          cardImageURL: decodeURI(imageUrl),
          id: idPatient
        }
      });
      if (result.status === 200) {
        self.setLoading(false);
        return result.data;
      }
      self.setLoading(false);
    });

    return {
      openModal,
      closeModal,
      resetForm,
      getImageData,
      deleteImageData,
      addImages
    };
  });
