import { observer } from "mobx-react-lite";
import { CLPatientInfoNotificationsStyle } from "./cl-patient-info-notifications.presents";
import { CLBlueButton } from "../shared/cl-button/cl-button";
import { CLSelect, CLSelectSmall } from "../shared/cl-select";
import { CLCheckbox } from "../shared/cl-checkbox";
import { ClRadio } from "../shared/cl-radio";
import { useEffect, useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { message } from "antd";
import { CLNotificationPhysicians } from "../cl-notification-physicians";
import { setRadioState, setSelectState } from "../../services/handlers/notifications.handler";
import { NotificationCriteria } from "../../boot/enums/NotificationCriteria";
import { NotificationRadioStates } from "../../boot/enums/NotificationRadioStates";
import { NotificationSelectNames } from "../../boot/enums/NotificationSelectNames";
import html2canvasModule from "html2canvas";
import { ROCTELID } from "../../boot/Constants";

export const ClPatientInfoNotifications = observer((props: {
  notificationCriteria: any,
  isAllUsers: any
}) => {
  const {
    updateNotifications,
    updateAllUsersNotifications,
    isAdminsRightsAvailable,
    isStandartRightsAvailable,
    idPhysician,
    IdCurrentMC,
    firstName,
    lastName
  } = useRootStore().userStore;
  const {
    getClinicPhysicians,
    getUserFromList,
    updateClinicDisclaimer,
    activePhysicians
  } = useRootStore().notificationStore;
  const [state, setState] = useState({
    radio: setRadioState(props.notificationCriteria),
    select: setSelectState(props.notificationCriteria),
    checkbox: {
      ch1: "false",
      ch2: "false",
      ch3: "false",
      ch4: "false",
      ch5: "false",
      ch6: "false"
    }
  });
  const ClinicName = IdCurrentMC + "" === ROCTELID + "" ? "ROC TELEMETRY" : "RhythmExpress";

  useEffect(() => {
    setState(prevState => {
      return {
        ...prevState,
        radio: setRadioState(props.notificationCriteria),
        select: setSelectState(props.notificationCriteria)
      };
    });
  }, [props.notificationCriteria]);

  // const selectOption = (event: any) => {
  //   console.log(event, props);
  // };

  const handleChange = (event: any, name: string) => {
    const value = event.target.checked + "";
    setState((prevState: any) => {
      return {
        ...prevState,
        checkbox: {
          ...prevState.checkbox,
          [name]: value
        }
      };
    });
  };

  const handleChangeRadioF = (name: string, value: string) => {
    setState((prevState: any) => {
      return {
        ...prevState,
        checkbox: {
          ...prevState.checkbox,
          [name]: value
        }
      };
    });
  };

  const handleChangeRadio = (name: string, value: string) => {
    setState((prevState: any) => {
      return {
        ...prevState,
        radio: {
          ...prevState.radio,
          [name]: value
        }
      };
    });
  };

  const handleChangeSelect = (name: string, value: string) => {
    const getSelectData = (prevState: any) => {
      let data = {
        ...prevState.select,
        [name]: { ...prevState.select[name], value: value }
      };

      if (name === NotificationSelectNames.P2_V1
        && value > state.select[NotificationSelectNames.P2_V2].value) {
        data = {
          ...data,
          [NotificationSelectNames.P2_V2]: { ...prevState.select[NotificationSelectNames.P2_V2], value: value }
        };
      } else if (name === NotificationSelectNames.P2_V2
        && state.select[NotificationSelectNames.P2_V1].value > value) {
        data = {
          ...data,
          [NotificationSelectNames.P2_V1]: { ...prevState.select[NotificationSelectNames.P2_V1], value: value }
        };
      }

      return data;
    };

    setState((prevState: any) => {
      return {
        ...prevState,
        select: getSelectData(prevState)
      };
    });
  };

  const isSaveDisabled = () => {
    if ((state.checkbox.ch1 === "true" || state.checkbox.ch2 === "true")
    && (state.checkbox.ch3 !== "false")
    && (state.checkbox.ch5 === "true" && state.checkbox.ch6 === "true")
    ) {
      return false;
    }
    return true;
  };

  const buttonClassName = isSaveDisabled() ? "disabled button-holder" : "button-holder";

  const getPicture = (isToALlUsers: boolean, selectedIdPhysician?: number | string) => {
    const hideElements = document.getElementsByClassName("hide-on-print");
    const selectElements = document.getElementById("global-container")?.getElementsByClassName("cl-select__control");
    if (selectElements?.length) {
      Array.from(selectElements).forEach(element => {
        element.classList.add("no-box-shadow");
      });
    }
    if (hideElements) {
      Array.from(hideElements).forEach(element => {
        element.classList.add("no-print");
      });
    }

    const studyElement = document.getElementById("global-container");
    const spString = isToALlUsers ? "0" : selectedIdPhysician + "";
    const fileName = "disclaimer_cp" + idPhysician + "_sp_" + spString + ".png";
    if (studyElement !== null) {
      return html2canvasModule(studyElement, {
        scrollX: 0,
        scrollY: 0,
        allowTaint: true,
        useCORS: true,
        logging: false
      }).then(function(canvas) {
        // document.body.appendChild(canvas);
        // let dataURL = canvas.toDataURL();
        canvas.toBlob((blob: Blob | null) => {
          if (blob) {
            const formData = new FormData();
            formData.append("my-file", blob, fileName);
            updateClinicDisclaimer(isToALlUsers, spString, formData);
          }
        });
      }).then(() => {
        if (selectElements?.length) {
          Array.from(selectElements).forEach(element => {
            element.classList.remove("no-box-shadow");
          });
        }
        if (hideElements?.length) {
          Array.from(hideElements).forEach(element => {
            element.classList.remove("no-print");
          });
        }
        return true;
      });
    }
  };
  const saveChanges = async(selectedIdPhysician?: number | string, isToAll?: boolean) => {
    const key = "save";
    message.loading({ content: "Loading...", key });
    const data: any = {};
    for (const [key, value] of Object.entries(state.radio)) {
      const num = value === NotificationCriteria[1] ? 1 : value === NotificationCriteria[2] ? 2 : 3;
      data[key] = num;
    }
    for (const item in state.select) {
      data[item] = state.select[item].value;
    }
    if ((props.isAllUsers && !selectedIdPhysician) || isToAll) {
      await getPicture(true);
      const isUserExistInClinic = !!getUserFromList(idPhysician);
      updateAllUsersNotifications(data, isUserExistInClinic).then((response: boolean) => {
        getClinicPhysicians();
        if (response) {
          message.success({ content: "Notifications successfully update!", key, duration: 2 });
        } else {
          message.error({ content: "Something went wrong!", key, duration: 2 });
        }
      });
    } else if (props.isAllUsers && selectedIdPhysician) {
      await getPicture(false, selectedIdPhysician);
      updateNotifications(data, selectedIdPhysician).then((response: boolean) => {
        getClinicPhysicians();
        if (response) {
          message.success({ content: "Notifications successfully update!", key, duration: 2 });
        } else {
          message.error({ content: "Something went wrong!", key, duration: 2 });
        }
      });
    } else {
      await getPicture(false, idPhysician);
      updateNotifications(data).then((response: boolean) => {
        if (response) {
          message.success({ content: "Notifications successfully update!", key, duration: 2 });
        } else {
          message.error({ content: "Something went wrong!", key, duration: 2 });
        }
      });
    }
  };

  const isSaveAvailable = isAdminsRightsAvailable || isStandartRightsAvailable || !props.isAllUsers;
  let isAnyChanges = false;

  if (props.notificationCriteria && Object.keys(props.notificationCriteria).length) {
    isAnyChanges = true;
  }

  const getSelectEl = (selectName: string, standardSelect?: boolean, isAllUsers? : boolean) => {
    const value = state.select[selectName].options.find((item: any) =>
      item.value === state.select[selectName].value);
    const props = {
      options: state.select[selectName].options,
      defaultValue: value,
      value: value,
      isDisabled: isAllUsers ? true : state.select[selectName].disabled,
      selectOption: (el: any) => { handleChangeSelect(selectName, el.value); },
      isSearchable: false
    };

    return standardSelect
      ? <CLSelect {...props} width="100%"></CLSelect>
      : <CLSelectSmall {...props}></CLSelectSmall>;
  };

  const getSelect = (selectName: string, standardSelect?: boolean) => {
    return state.select[selectName]
      ? getSelectEl(selectName, standardSelect, !isSaveAvailable)
      : "";
  };

  const getRadio = (radioName: string, criteria: string) => {
    return radioName && criteria
      ? (
        <div className="radio-overlay">
          <ClRadio
            handleChange={() => handleChangeRadio(radioName, criteria)}
            value={criteria}
            isChecked={state.radio[radioName] === criteria}
            disabled={!isSaveAvailable}
            name={radioName}
          ></ClRadio>
        </div>
      )
      : "";
  };

  return (
    <CLPatientInfoNotificationsStyle id="global-container">
      <div>
        <div className="header-container">
          <h1>Review notifications for clinic physicians</h1>
          {props.isAllUsers && <CLNotificationPhysicians/>}
        </div>
        <div className="overlay">
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Notification Criteria</th>
                <th>24/7 Call + email</th>
                <th>Office hours call + email</th>
                <th>Do Not Notify (Summary Report Only)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Ventricular Fibrillation/Torsades de Pointes
                </td>
                <td></td>
                <td>{getRadio(NotificationRadioStates.VentricularFib, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.VentricularFib, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.VentricularFib, NotificationCriteria[3])}</td>
              </tr>
              <tr>
                <td rowSpan={2}>
                  Ventricular Tachycardia
                </td>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2 pull-right">
                          <div className="text">HR &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.VTR1_V1)}</div>
                          <div className="text">bpm for &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.VTR1_V2)}</div>
                          <div className="text">beats</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.VentricularTach, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.VentricularTach, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.VentricularTach, NotificationCriteria[3])}</td>
              </tr>
              <tr>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.VTR2_V1)}</div>
                          <div className="text">bpm &#60; HR &#60;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.VTR2_V2)}</div>
                          <div className="text">bpm for &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.VTR2_V3)}</div>
                          <div className="text">beats</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.VentricularTachSecond, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.VentricularTachSecond, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.VentricularTachSecond, NotificationCriteria[3])}</td>
              </tr>
              <tr>
                <td className="test" rowSpan={2}>
                  Atrial fibrillation and or/flutter (AF/AFL)
                </td>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2 pull-right">
                          <div className="text">HR &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.AF1_V1)}</div>
                          <div className="text">bpm for &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.AF1_V2)}</div>
                          <div className="text">minutes</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="select">
                    {getSelect(NotificationSelectNames.AF1_V3, true)}
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.AtrialFib, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.AtrialFib, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.AtrialFib, NotificationCriteria[3])}</td>
              </tr>
              <tr>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.AF2_V1)}</div>
                          <div className="text">bpm &#60; HR &#60;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.AF2_V2)}</div>
                          <div className="text">bpm for &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.AF2_V3)}</div>
                          <div className="text">minutes</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select">
                    {getSelect(NotificationSelectNames.AF2_V4, true)}
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.NewOnsetAF, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.NewOnsetAF, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.NewOnsetAF, NotificationCriteria[3])}</td>
              </tr>

              <tr>
                <td rowSpan={2}>
                  Bradycardia
                </td>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2 pull-right">
                          <div className="text">HR &#60;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.BR1_V1)}</div>
                          <div className="text">bpm for &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.BR1_V2)}</div>
                          <div className="text">seconds</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.SevereBradycardia, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.SevereBradycardia, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.SevereBradycardia, NotificationCriteria[3])}</td>
              </tr>
              <tr>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.BR2_V1)}</div>
                          <div className="text">bpm &#60; HR &#60;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.BR2_V2)}</div>
                          <div className="text">bpm for &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.BR2_V3)}</div>
                          <div className="text">seconds</div>
                        </div>
                      </div>
                    </div>
                    <div className="select">
                      {getSelect(NotificationSelectNames.BR2_V4, true)}
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.Bradycardia, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.Bradycardia, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.Bradycardia, NotificationCriteria[3])}</td>
              </tr>

              <tr>
                <td rowSpan={2}>
                  Pause
                </td>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2"></div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2 pull-right">
                          <div className="text">interval &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.P1_V1)}</div>
                          <div className="text">seconds</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.Asystole, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.Asystole, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.Asystole, NotificationCriteria[3])}</td>
              </tr>
              <tr>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2">
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.P2_V1)}</div>
                          <div className="text">&#60; interval &le;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.P2_V2)}</div>
                          <div className="text">seconds</div>
                        </div>
                      </div>
                    </div>
                    <div className="select">
                      {getSelect(NotificationSelectNames.P2_V3, true)}
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.Pause, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.Pause, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.Pause, NotificationCriteria[3])}</td>
              </tr>

              <tr>
                <td>
                  SVT
                </td>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2 pull-right">
                          <div className="text">HR &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.SVT_V1)}</div>
                          <div className="text">bpm for &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.SVT_V2)}</div>
                          <div className="text">seconds</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.Tachycardia, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.Tachycardia, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.Tachycardia, NotificationCriteria[3])}</td>
              </tr>

              <tr>
                <td>
                  Second-degree AV Block
                </td>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2">
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2 pull-right">
                          <div className="text">interval &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.SDAVB_V)}</div>
                          <div className="text">seconds</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.DegAVBlock_Mobitz, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.DegAVBlock_Mobitz, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.DegAVBlock_Mobitz, NotificationCriteria[3])}</td>
              </tr>

              <tr>
                <td>
                  Complete Heart Block
                </td>
                <td>
                  <div className="t">
                    <div className="t-row">
                      <div className="t-cell">
                        <div className="table-cell2">
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2 pull-right">
                          <div className="text">interval &ge;</div>
                        </div>
                      </div>
                      <div className="t-cell">
                        <div className="table-cell2">
                          <div className="input">{getSelect(NotificationSelectNames.CHB_V)}</div>
                          <div className="text">seconds</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{getRadio(NotificationRadioStates.DegHB, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.DegHB, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.DegHB, NotificationCriteria[3])}</td>
              </tr>

              <tr>
                <td>
                  Fainting
                </td>
                <td></td>
                <td>{getRadio(NotificationRadioStates.Fainting, NotificationCriteria[1])}</td>
                <td>{getRadio(NotificationRadioStates.Fainting, NotificationCriteria[2])}</td>
                <td>{getRadio(NotificationRadioStates.Fainting, NotificationCriteria[3])}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="questions">
          <div className="block">
            <p className="caption">
              By clicking the boxes below, you are acknowledging that you have read and agree with these disclaimers
            </p>
          </div>
          <div className="block">
            <h3>Notification Method</h3>
            <p className="description">Check all that apply</p>
            <div className="list">
              <div className="item">
                <CLCheckbox
                  handleChange={(event: any) => handleChange(event, "ch1")}
                  value={state.checkbox.ch1}
                  label={"MD authorizes any office personnel to take urgent notifications during business hours"}
                  disabled={false}
                  name={"name"}></CLCheckbox>
              </div>
              <div className="item">
                <CLCheckbox
                  handleChange={(event: any) => handleChange(event, "ch2")}
                  value={state.checkbox.ch2}
                  label={
                    "MD authorizes any RN, NP or PA to make patient specific verbal notification changes "
                    + " after hours. I understand that all verbal orders will only be in place for 24 hours apart "
                    + "from weekends and holidays. After that authorization will be removed by the end of the next "
                    + "business day."}
                  disabled={false}
                  name={"name"}></CLCheckbox>
              </div>
            </div>
          </div>

          <div className="block">
            <h3>When notifying physician; if {ClinicName} reaches a voicemail:</h3>
            <p className="description">Select appropriate method</p>
            <div className="list">
              <div className="item">
                <ClRadio
                  handleChange={() => handleChangeRadioF("ch3", "checked3")}
                  value={"checked3"}
                  isChecked={state.checkbox.ch3 === "checked3"}
                  label={"Make Two (2) Attempts in 15 minutes increments"}
                  name={"ch3"}
                  id="checked3"
                ></ClRadio>
              </div>
              <div className="item">
                <ClRadio
                  handleChange={() => handleChangeRadioF("ch3", "checked4")}
                  value={"checked4"}
                  isChecked={state.checkbox.ch3 === "checked4"}
                  label={"Consider notification complete after first attempt"}
                  name={"ch3"}
                  id="checked4"
                ></ClRadio>
              </div>
            </div>
          </div>

          <div className="block">
            <h3>Disclaimer</h3>
            <p className="description">Check below that you read and agreed</p>
            <div className="list">
              <div className="item">
                <CLCheckbox
                  handleChange={(event: any) => handleChange(event, "ch5")}
                  value={state.checkbox.ch5}
                  label={"Full Disclosure data available for 30 days after archiving study"}
                  disabled={false}
                  name={"name"}></CLCheckbox>
              </div>
              <div className="item">
                <CLCheckbox
                  handleChange={(event: any) => handleChange(event, "ch6")}
                  value={state.checkbox.ch6}
                  label={ClinicName + " is not an emergency response system"}
                  disabled={false}
                  name={"name"}></CLCheckbox>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="signature">
              {/* <p className="date">{moment().format("MM/DD/yyyy")}</p> */}
              <p className="date">{firstName} {lastName}</p>
              <div className="item-physician">
                <p className="value-item">{firstName} {lastName}</p>
              </div>
            </div>
          </div>
          {!props.isAllUsers && isSaveAvailable && isAnyChanges && <div className="buttons hide-on-print">
            <div className={buttonClassName}>
              <CLBlueButton
                text={"Save"}
                click={() => saveChanges()}/>
            </div>
          </div>}
          {props.isAllUsers && <div className="buttons hide-on-print">
            {!isStandartRightsAvailable && isSaveAvailable && isAnyChanges && <div className={buttonClassName}>
              <CLBlueButton
                text={"Apply to all clinic physicians"}
                click={() => saveChanges(0, true)}/>
            </div>}
            {((isStandartRightsAvailable && idPhysician) || !isStandartRightsAvailable)
            && isSaveAvailable && isAnyChanges && <div className={buttonClassName}>
              <CLBlueButton
                text="Apply to selected physician"
                click={() => saveChanges(activePhysicians?.Id)}/>
            </div>}
          </div>}
        </div>
      </div>
    </CLPatientInfoNotificationsStyle>
  );
});
