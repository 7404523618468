import styled from "styled-components";
import { theme } from "../../theme/theme";

export const PatientInfoEventsTab = styled.div`
  .event-date{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(72, 72, 72, 0.7);
    padding: 16px;
    border-top: 1px solid ${theme.colors.background};
    border-bottom: 1px solid ${theme.colors.background};
    margin-bottom: 0;
  }
  .events-nav{
    height: 58px;
    display: flex;
    justify-content: space-between;

    padding-left: 5px;
    padding-right: 10px;
  }
  .list{
    padding-top: 8px;
    padding-bottom: 8px;
  }
  h3 {
    padding-left: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #484848;
  }
  
  .select-all{
    padding: 12px 16px;
  }

  .sort-container {
    cursor: pointer;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    align-items: center;

    .text {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      padding-left: 6px;
      
      span {
        font-weight: bold;
      }
    }
  }
`;
