import { observer } from "mobx-react-lite";
import { PatientEventsIcons } from "./cl-patient-events-icons.presents";
import All from "../../assets/icons/events-all.svg";
import Confirmet from "../../assets/icons/events-confirmedEv.svg";
import Rejected from "../../assets/icons/events-rejected.svg";
import Source from "../../assets/icons/events-source.svg";
import Stat from "../../assets/icons/events-stat.svg";
import Profile from "../../assets/icons/events-profile.svg";
import { CLIcon } from "../shared/cl-icon";
import { PropsType } from "./cl-patient-events-icons.props";
import { getEventTypeString } from "../../stores/patientInfo/patientInfo.helper.service";

export const ClPatientEventsIcons = observer((props: PropsType) => {
  const getSrcType = () => {
    if (props.type) {
      switch (props.type) {
        case "All":
          return All;
        case "Confirmet":
          return Confirmet;
        case "Rejected":
          return Rejected;
        case "Source":
          return Source;
        case "Stat":
          return Stat;
        case "Profile":
          return Profile;
      }
      return All;
    } else {
      return All;
    }
  };
  if (props.type) {
    return (
      <PatientEventsIcons>
        <CLIcon icon={getSrcType()} width="24px" height="24px" padding="11px"></CLIcon>
      </PatientEventsIcons>
    );
  } else if (!props || !props.item || !props.item.EventStatus) {
    return (<></>);
  } else {
    const eventStatus = props.item.EventStatus;
    if (eventStatus === 0) {
      return (<></>);
    }
    const getSrc = () => {
      const eventType = getEventTypeString(props.item);
      switch (eventType) {
        case "Confirmet":
          return Confirmet;
        case "Stat":
          return Stat;
        case "Rejected":
          return Rejected;
        case "Source":
          return Source;
        case "Profile":
          return Profile;
        default:
          return Confirmet;
      }
    };

    return (
      <PatientEventsIcons>
        <CLIcon icon={getSrc()} width="24px" height="24px" padding="2px"></CLIcon>
      </PatientEventsIcons>
    );
  }
});
