import { CLBillingUtilizationChartUtilizationWraper } from "./cl-billing-utilization-chart-utilization.presents";
import { observer } from "mobx-react-lite";
import CanvasJSReact from "../../assets/charts/@canvasjs/react-charts/canvasjs.react";
import { useRootStore } from "../../stores/storeContext";
import { CLSpiner } from "../shared/cl-spinner";
import NoResultIcon from "../../assets/icons/no-result.svg";
import { ClIconNoResult } from "../shared/cl-icon";
import { theme } from "../../theme/theme";
import { chartUtilizationOptions } from "../../boot/Constants";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const CLBillingUtilizationChartUtilization = observer(() => {
  const { isLoadingUtilization, utilizationeData } = useRootStore().billingStore;

  const getOptionsUtilization = () => {
    const options = {
      ...chartUtilizationOptions,
      toolTip: { ...chartUtilizationOptions.toolTip, content: "{y}" },
      data: [
        {
          type: "stackedColumn",
          color: theme.colors.green,
          dataPoints: utilizationeData.map((item: any) => {
            return {
              label: item.SerialNumber,
              y: item.CountHolter
            };
          })
        },
        {
          type: "stackedColumn",
          color: theme.colors.red,
          dataPoints: utilizationeData.map((item: any) => {
            return {
              label: item.SerialNumber,
              y: item.CountHolterPlus
            };
          })
        },
        {
          type: "stackedColumn",
          color: theme.colors.yellow,
          dataPoints: utilizationeData.map((item: any) => {
            return {
              label: item.SerialNumber,
              y: item.CountER
            };
          })
        },
        {
          type: "stackedColumn",
          color: theme.colors.blue,
          dataPoints: utilizationeData.map((item: any) => {
            return {
              label: item.SerialNumber,
              y: item.CountMCT
            };
          })
        }
      ]
    };

    return <CanvasJSChart options={options}></CanvasJSChart>;
  };

  return (
    <CLBillingUtilizationChartUtilizationWraper>
      {(!isLoadingUtilization && utilizationeData.length > 0) && <div>
        <div className="header">
          <div className="header-item title">Utilization</div>
          <div className="header-item info"></div>
          <div className="header-item legend">
            <div className="item">
              <div className="color color-holter"></div>
              <div className="text">Holter</div>
            </div>
            <div className="item">
              <div className="color color-holter-plus"></div>
              <div className="text">Holter+ MCT</div>
            </div>
            <div className="item">
              <div className="color color-event"></div>
              <div className="text">Event</div>
            </div>
            <div className="item">
              <div className="color color-mct"></div>
              <div className="text">MCT</div>
            </div>
          </div>
        </div>
        <div className="label">Studies</div>
        <div className="chart">
          {getOptionsUtilization()}
        </div>
      </div>}
      {isLoadingUtilization && <div className="spiner"><CLSpiner></CLSpiner></div>}
      {(!isLoadingUtilization && !utilizationeData.length) && <div className="no-result-section">
        <div className="no-result-content">
          <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
          <div className="text">Sorry, no usage data</div>
        </div>
      </div>}
    </CLBillingUtilizationChartUtilizationWraper>
  );
});
