import styled from "styled-components";
import { theme } from "../../theme/theme";

export const PatientInfoStyles = styled.div`
    .content{
      padding-left: ${theme.offsets.mainContent};
      padding-right: ${theme.offsets.mainContent};
      padding-bottom: 20px;
    }
`;
