import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const ICD10Style = styled.div`
  position: relative;
  padding: ${theme.offsets.mainContentVertical} ${theme.offsets.mainContent};
  display: flex;
  flex-wrap: wrap;
  min-height: 800px;
  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;
