import { observer } from "mobx-react-lite";
import { Route, Switch, Redirect } from "react-router-dom";
import { PatientsListPage } from "../pages/PatientsList";
import { DevicesListPage } from "../pages/DevicesList";
import { CreateUserPage } from "../pages/CreateUserPage";
import { useRootStore } from "../stores/storeContext";
import { PatientInfoPage } from "../pages/PatientInfo";
import { LoginPage } from "../pages/Login";
import { ForgotPasswordPage } from "../pages/ForgotPassword";
import { RecoveryPasswordPage } from "../pages/RecoveryPassword";
import { ClinicPortalPage } from "../pages/ClinicPortal";
import { AdministrationListPage } from "../pages/AdministrationList";
import { StudyCreatePage } from "../pages/StudyCreate";
import { CLSpiner } from "../components/shared/cl-spinner";
import { Notifications } from "../pages/Notifications";
import { BillingPage } from "../pages/Billing";
import { SupportPage } from "../pages/Support";
import { UserPage } from "../pages/User/User.page";
import { ReportsListPage } from "../pages/ReportsList";
import { NotificationsMobilePage } from "../pages/NotificationsMobile";
import { DeviceMode } from "./Constants";
import React from "react";
import { InsuranceInfoPage } from "../pages/InsuranceInfo";

export const Routers = observer(() => {
  const { setMobile, clearIsMobile, detectMode, detectIsMobile } = useRootStore().userStore;
  useRootStore().userStore.checkAuthorized();
  const isMobile = detectMode() === DeviceMode.mobile;

  if (typeof useRootStore().userStore.isAuth === "undefined") {
    return (<></>);
  } else {
    if (!isMobile) {
      clearIsMobile();
    } else {
      setMobile(isMobile);
    }
    const stylesForMobileDevice = !isMobile && detectIsMobile() ? { minWidth: "980px" } : { minWidth: "auto" };

    return (
      <div style={stylesForMobileDevice}>
        {!isMobile && detectIsMobile()
          ? <meta name="viewport" content="user-scalable = no"></meta>
          : <meta content="width=device-width, initial-scale=1"></meta>}
        {useRootStore().globalStore.showGlobalSpiner && <div className="global-spiner">
          <CLSpiner></CLSpiner>
        </div>}
        {useRootStore().userStore.isAuth && <ClinicPortalPage></ClinicPortalPage>}
        {useRootStore().userStore.isAuth && !isMobile && <Switch>
          <Route path='/patients-list' render={() => <PatientsListPage />} />
          <Route path='/devices-list' render={() => <DevicesListPage />} />
          <Route path='/billing' render={() => <BillingPage />} />
          <Route path='/create-patient' render={() => <CreateUserPage />} />
          <Route path="/edit-patient/:patientId" render={() => <CreateUserPage />} />
          <Route path='/patient-info/:patientId' render={() => <PatientInfoPage />} />
          <Route path='/study-create/:patientId' render={() => <StudyCreatePage />} />
          <Route path='/insurance-info/:patientId' render={() => <InsuranceInfoPage />} />
          <Route path="/administration-list" render={() => <AdministrationListPage />} />
          <Route path="/notifications" render={() => <Notifications />} />
          <Route path="/support" render={() => <SupportPage />} />
          <Route path="/user" render={() => <UserPage />} />
          <Route path="/reports-list" render={() => <ReportsListPage />} />
          <Redirect from='*' to='/patients-list' />
        </Switch>
        }
        {useRootStore().userStore.isAuth && isMobile && <Switch>
          <Route path='/notifications-mobile' render={() => <NotificationsMobilePage />} />
          <Route path='/patient-info/:patientId' render={() => <PatientInfoPage />} />
          <Redirect from='*' to='/notifications-mobile' />
        </Switch>
        }
        {!useRootStore().userStore.isAuth && <Switch>
          <Route path="/login" render={() => <LoginPage />} />
          <Route path="/forgot-password" render={() => <ForgotPasswordPage />} />
          <Route path="/recovery-password/:userId" render={() => <RecoveryPasswordPage />} />
          <Route path="/create-password/:userId" render={() => <RecoveryPasswordPage />} />
          <Redirect from='*' to='/login' />
        </Switch>
        }
      </div>
    );
  }
});
