import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClChartsEcgStyles = styled.div`
  position: relative;
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  background: ${theme.colors.light};
  padding: 24px;
  margin-bottom: 24px;
  min-height: 466px;
  width: 100%;

  .spiner {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    #ecg {
      background: transparent;
    }
  }
  
  h3{
    font-size: 18px;
  }

  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    font-size: 16px;
    .dropdown, .calipers-btn {
      display: flex;
      align-items: center;
    }
    .border-between {
      & > div {
        position: relative;
      }
      & > div + div::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        margin-top: -8px;
        display: block;
        width: 1px;
        height: 16px;
        background: #D8D8D8;
      }
    }
    .selected-date {
      font-size: 16px;
      line-height: 20px;
      color: #484848;
    }
  }
  .calipers-btn{
    padding: 4px 16px;
    border-radius: 4px;
  }
  
  .dropdown{
    display: flex;
    margin-right: 10px;
    .dropdown-icon-wrapper{
      margin-right: 8px;
      padding-top: 2px;
    }
    .dropdown-toggle{
      padding-top: 4px;
    }
    //flex-direction: ;
  }
  .selected-date{
    text-transform: lowercase;
  }

  .holder {
    max-width: 100%;
    overflow: auto;

    & > div {
      height: 250px;
      width: 100%;

      & > div {
        height: 240px !important;
      }
    }
  }

  .holder-short {
    max-width: 100%;
    overflow: auto;

    & > div {
      height: 80px;
      width: 3800px;

      & > div {
        height: 80px !important;
      }
    }
  }
  .control{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
`;
