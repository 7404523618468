import { observer } from "mobx-react-lite";
import { PatientEventsType } from "./cl-patient-events-type.presents";
import { PropsType } from "./cl-patient-events-type.props";
import { getEventTypeConstant } from "./cl-patient-events-type.constant";

export const ClPatientEventsType = observer((props: PropsType) => {
  if (!props || !props.EventType) {
    return (<></>);
  }

  const type = getEventTypeConstant(+props.EventType);
  const isSymptomatic = props.EventType && props.EventSource === 78;

  return (
    <PatientEventsType className=
      {`${(type?.trim() === "Symptomatic") || isSymptomatic ? "type2" : ""} ${props.isActive ? "active" : ""}`}>
      {type}
    </PatientEventsType>
  );
});
