import { observer } from "mobx-react-lite";
import { AdministrationListStyle } from "./AdministrationList.presents";
import { useRootStore } from "../../stores/storeContext";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { UserManagementPage } from "./userManagementPage";
import { ICD10Page } from "./icd10Page";
import { ReportsTabs } from "../../boot/Constants";
import { CLNavItem } from "../../components/shared/cl-nav-item";
import { Notifications } from "../Notifications";
import { CLCard } from "../../components/shared/cl-card";
import { SettingsPage } from "./settingsPage";
import { ClAlert2fa } from "../../components/cl-alert-2fa";
import { ClAction2fa } from "../../components/cl-action-2fa";
import { ClAlert2faHero } from "../../components/cl-alert-2fa-hero";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

export const AdministrationListPage = observer(() => {
  const { getTotalCount } = useRootStore().listAdministrationStore;
  const { url } = useRouteMatch();
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { PortalUserAC, PortalUserMC, IdCurrentMC } = useRootStore().userStore;
  const location = useLocation();
  let isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");

  useEffect(() => {
    isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  }, [PortalUserMC, IdCurrentMC]);

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => {
      setGlobalSpiner(false);
    });
  }, [PortalUserAC]);

  return (
    <AdministrationListStyle>
      <div className="header">
        <div className="nav-wraper">
          {ReportsTabs.map((item) => (<CLNavItem
            key={item.id}
            link={`${url}${item.link}`}
            title={item.title}
            isActive={!!((url + item.link).indexOf(location.pathname) + 1)}
            fontSize={"16px"}/>))}
        </div>
      </div>
      <div className="content">
        <Switch>
          <Route exact path={url} render={() => <Redirect to={`${url}/user-management`} />} />
          <Route path={`${url}/user-management`} render={() => <UserManagementPage />} />
          <Route path={`${url}/icd10`} render={() => <ICD10Page />} />
          <Route path={`${url}/settings`} render={() => <SettingsPage />} />
          <Route path={`${url}/notifications`} render={() => (
            <div className="wrap">
              <CLCard padding="24px">
                <Notifications />
              </CLCard>
            </div>)} />
        </Switch>
      </div>
      {isMandatoryMFA ? <ClAlert2fa/> : <ClAlert2faHero/>}
      <ClAction2fa/>
    </AdministrationListStyle>
  );
});
