import styled from "styled-components";
import { theme } from "../../theme/theme";

export const PatientStudyInfoStyle = styled.div`
  position: relative;
  width: 888px;
  margin: 0 auto;
  //display: flex;
  //flex: 1;

  .back {
    //position: absolute;
    display: block;
    width: 30px;
    margin-right: 15px;
  }
  .is-paced{
    display: inline-block;
    margin-left: 10px;
  }
  .info {
    display: block;
    span {
      color: ${theme.colors.placeholder}
    }
    .title{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: ${theme.colors.text}
    };
  }
  h1 {
    flex: 0 0 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 28px;
  }
  .fillInsurance {
    margin-bottom: 16px;
  }
  h2 {
    flex: 0 0 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 20px;
  }
  
  .block{
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 30px;
    padding-top: 40px;
  }

  .row {
    margin-right: -10px;
    margin-left: -10px;
    //flex: 0 0 100%;
    //display: flex;
    //flex-wrap: wrap;
    //margin-top: 20px;
  }

  .field {
    width: 25%;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;
    .label{
      font-size: 14px;
      line-height: 16px;
      color: #6D6D6D;
      margin-bottom: 8px;
    }
    .value{
      font-size: 16px;
      line-height: 18px;
      color: #484848;
    }
    &.w100 {
      width: 100%;
    }
  }
  
  .view {
    margin-top: 30px;
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #457EF5;
      cursor: pointer;
    }
  }
  
  
`;
