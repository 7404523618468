import { observer } from "mobx-react-lite";
import { CLCard } from "../../../components/shared/cl-card";
import { BillingUtilizationStyle } from "./BillingUtilization.presents";
import { CLBillingUtilizationChartUsage } from "../../../components/cl-billing-utilization-chart-usage";
import { CLBillingUtilizationChartUtilization } from "../../../components/cl-billing-utilization-chart-utilization";
import { useRootStore } from "../../../stores/storeContext";
import { FCMID } from "../../../boot/Constants";

export const BillingUtilizationPage = observer(() => {
  const { IdCurrentMC } = useRootStore().userStore;

  const isFMC = IdCurrentMC + "" === FCMID + "";

  return (
    <BillingUtilizationStyle>
      {!isFMC && <CLCard padding="24px">
        <CLBillingUtilizationChartUsage></CLBillingUtilizationChartUsage>
      </CLCard>}
      <div className="br"></div>
      <CLCard padding="24px">
        <CLBillingUtilizationChartUtilization></CLBillingUtilizationChartUtilization>
      </CLCard>
    </BillingUtilizationStyle>
  );
});
