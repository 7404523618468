import { observer } from "mobx-react-lite";
import { CLPatientReportsListWraper } from "./cl-patient-reports-list.presents";
import { useRootStore } from "../../stores/storeContext";
import { ReportTypesNav, ReportType, ReportTypesColorSchema } from "../../boot/Constants";
import { CLCard } from "../shared/cl-card";
import { CLIcon, ClIconNoResult } from "../shared/cl-icon";
import { theme } from "../../theme/theme";
import { formatTimeReport } from "../../services/handlers/date.handler";
import SignedIcon from "../../assets/icons/report-signed.svg";
import ContentLoader from "react-content-loader";
import NoResultIcon from "../../assets/icons/no-result-reports.svg";
import { ClTooltip } from "../shared/cl-tooltip";

export const CLPatientReportsList = observer(() => {
  const {
    reportsAll,
    reportsDaily,
    reportsStat,
    reportsSummary,
    reportsSummaryM,
    activeType,
    activeReport,
    setActiveType,
    setActiveReport,
    isLoading
  } = useRootStore().reportStore;

  const navClick = (type: number) => {
    setActiveType(type);
  };

  const setActiveReportClick = (item: any) => {
    setActiveReport(item);
  };

  const getCountReports = (type: number) => {
    const getCount = (data: []) => {
      let count = 0;
      data.forEach((item: any) => {
        count = count + item.data.length;
      });
      return count;
    };

    switch (type) {
      case -1:
        return getCount(reportsAll);
      case ReportType.Daily:
        return getCount(reportsDaily);
      case ReportType.Stat:
        return getCount(reportsStat);
      case ReportType.Summary:
        return getCount(reportsSummary);
      case ReportType.SummaryM:
        return getCount(reportsSummaryM);
      default:
        return 0;
    }
  };

  const getActiveList = () => {
    switch (activeType) {
      case -1:
        return reportsAll;
      case ReportType.Daily:
        return reportsDaily;
      case ReportType.Stat:
        return reportsStat;
      case ReportType.Summary:
        return reportsSummary;
      case ReportType.SummaryM:
        return reportsSummaryM;
      default:
        return [];
    }
  };

  const getLoadContent = () => {
    const items = [];
    for (let i = 0; i < 2; i++) {
      items.push(<div className="list-container">
        <div className="day-title">
          <ContentLoader title="" width="100" height="20">
            <rect rx="4" ry="4" width="100" height="20" />
          </ContentLoader>
        </div>
        <div className="report-item">
          <div className="time">
            <ContentLoader title="" width="230" height="60">
              <rect rx="4" ry="4" width="230" height="60" />
            </ContentLoader>
          </div>
        </div>
        <div className="report-item">
          <div className="time">
            <ContentLoader title="" width="230" height="60">
              <rect rx="4" ry="4" width="230" height="60" />
            </ContentLoader>
          </div>
        </div>
      </div>);
    }
    return items;
  };

  const identifyAdditionalReportType = (type: number) => {
    switch (type) {
      case ReportType.Baseline:
        return "BL  ";
      default:
        return "";
    }
  };

  const getItemTooltip = (type: number) => {
    const noIconType = identifyAdditionalReportType(type);
    if (noIconType) {
      return noIconType;
    }
    return ReportTypesColorSchema.map((nav: any) => nav.type === type
      && <div className="report-type" style={{ background: nav.color }}>{nav.tooltip}</div>);
  };

  return (
    <CLPatientReportsListWraper>
      <CLCard paddingTop="24px" paddingBottom="24px" width="280px">
        <div className="title">Reports</div>
        <div className="nav-container">
          {ReportTypesNav.map((item: any) => <div
            className={`
              nav-item
              ${item.type === activeType ? " active" : ""}`}
            onClick={() => {
              if (getCountReports(item.type) === 0) { return; }
              navClick(item.type);
            }}>
            <ClTooltip
              id={`eventNav${item.id}`}
              class="cl-tooltip"
              tooltipBlock={<div>{item.tooltip}</div>}>
              <div className="nav-item-container"></div>
              <CLIcon
                icon={item.icon}
                count={getCountReports(item.type)}
                borderColorCount={theme.colors.background}
              ></CLIcon>
            </ClTooltip>
          </div>)}
        </div>
        {(!isLoading && reportsAll.length > 0) && <div className="list-container">
          {getActiveList().map((day: any) => <div>
            {day.data.length > 0 && <div>
              <div className="day-title">{day.title}</div>
              {day.data.map((item: any) =>
                <div
                  className={`report-item${activeReport.Id === item.Id ? " active-item" : ""}`}
                  onClick={() => { setActiveReportClick(item); }}>
                  {ReportTypesColorSchema.map((nav: any) => nav.type === item.ReportType
                    && <span className="report-type">{getItemTooltip(item.ReportType)}</span>)}
                  <div className="time">
                    <span>{identifyAdditionalReportType(item.ReportType)}</span>
                    {formatTimeReport(item.ReportDate)}
                  </div>
                  {item.IsSigned && <div className="signed"><CLIcon icon={SignedIcon}></CLIcon></div>}
                </div>)}
            </div>}
          </div>)}
        </div>}
        {(!isLoading && reportsAll.length === 0) && <div className="no-results">
          <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
          <div>There are no reports yet</div>
        </div>}

        {isLoading && getLoadContent()}
      </CLCard>
    </CLPatientReportsListWraper>
  );
});
