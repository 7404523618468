import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { useEffect, useState } from "react";
import { CLLightButton, CLBlueButton } from "../shared/cl-button";
import { message } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { ClWiFiSetupModalWrapper } from "./cl-wifi-setup-modal.presents";
import InputMask from "react-input-mask";
import PasswordHideIcon from "../../assets/icons/password-hide.svg";
import PasswordShowIcon from "../../assets/icons/password-show.svg";
import { CLInput } from "../shared/cl-input";
import { ErrorMessages } from "../../boot/Constants";
import Edit from "../../assets/icons/edit-blue.svg";
import { CLIcon } from "../shared/cl-icon";
import { isFeatureFlagActive } from "../../services/feature-flag.service";
import { isFCMMC, isROCTELMC } from "../../stores/patientInfo/patientInfo.helper.service";

export const ClWifiSetupModal = observer(() => {
  const {
    validateFormFields,
    ssid,
    password,
    saveForm,
    initData,
    initITContactData,
    name,
    email,
    phoneNumber
  } = useRootStore().wifiSetupStore;

  const {
    phoneNumber: phoneNumberAdmin,
    saveClinicPhoneNumber,
    getClinicPhoneNumber,
    getTotalCount
  } = useRootStore().listAdministrationStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const {
    PortalUserAC,
    IdCurrentMC,
    isAdminsRightsAvailable,
    isUseWiFi,
    wifiSettingsTemplate,
    itContacts,
    updateUserData
  } = useRootStore().userStore;

  const isFCM = isFCMMC(IdCurrentMC);
  const isROCMC = isROCTELMC(IdCurrentMC);

  const getContactInfo = () => {
    if (isROCMC) {
      return {
        supportEmail: "support@roctele.com",
        mailToEmail: "mailto:support@roctele.com"
      };
    } else if (isFCM) {
      return {
        supportEmail: "clinicsupport@fcminc.com",
        mailToEmail: "mailto:clinicsupport@fcminc.com"
      };
    } else {
      return {
        supportEmail: "support@rhythmexpressecg.com",
        mailToEmail: "mailto:support@rhythmexpressecg.com"
      };
    }
  };

  const [phoneNumberField, setPhoneNumberFiled] = useState(phoneNumberAdmin || "");
  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isITContactsExist, setIsITContactsExist] = useState(false);
  const [isITContactsEdit, setIsITContactsEdit] = useState(false);

  const isWifiAvailable = isAdminsRightsAvailable && isFeatureFlagActive("wifiAvailable") && isUseWiFi;

  useEffect(() => {
    getClinicPhoneNumber();
  }, [PortalUserAC]);

  const initInfo = () => {
    initData(wifiSettingsTemplate);
    initITContactData(itContacts);
    setIsITContactsExist(itContacts && !!itContacts.Name && !!itContacts.Phone && !!itContacts.Email);
  };

  useEffect(() => {
    initInfo();
  }, [wifiSettingsTemplate.ssid]);

  useEffect(() => {
    setPhoneNumberFiled(phoneNumberAdmin);
  }, [phoneNumberAdmin]);

  const showSuccessMessage = () => {
    confirmAlert({
      message: "Settings was updated successfully!",
      overlayClassName: "cl-success-modal",
      buttons: [
        {
          label: "Ok",
          onClick: () => {}
        }
      ]
    });
  };
  const validateForm = () => {
    const { errors, isValid } = validateFormFields();
    setErrors(errors);
    return isValid;
  };
  const showPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  const handleChangePhone = (event: any) => {
    setPhoneNumberFiled(event.target.value);
  };
  const handleChangePhoneNumber = (event: any) => {
    phoneNumber.setValue(event.target.value);
  };
  const handleChangeField = (event: any, target: any) => {
    target.setValue(event.target.value);
  };
  const updateClinicPhoneNumber = async(isShowLoading?: boolean) => {
    if (isShowLoading) {
      setGlobalSpiner(true);
    }
    const result = await saveClinicPhoneNumber(phoneNumberField);
    setGlobalSpiner(false);
    if (isShowLoading) {
      showSuccessMessage();
    }
    return result;
  };

  const saveFormRequest = async() => {
    return await saveForm();
  };

  const submit = async() => {
    setGlobalSpiner(true);
    let formResponse;
    if (isWifiAvailable) {
      formResponse = await saveFormRequest();
    }
    const phoneResponse = await updateClinicPhoneNumber();
    if (formResponse && isWifiAvailable) {
      updateUserData();
      getTotalCount();
      reset();
      setGlobalSpiner(false);
      showSuccessMessage();
    } else if (phoneResponse) {
      showSuccessMessage();
    } else {
      setGlobalSpiner(false);
      message.error({ content: "Error, Something went wrong!", duration: 2 });
    }
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (!isWifiAvailable) {
      if (!phoneNumberField) {
        setFormInvalid(true);
      } else {
        updateClinicPhoneNumber(true);
      }
    } else {
      if (!validateForm()) {
        setFormInvalid(true);
      } else {
        setFormInvalid(false);
        confirmAlert({
          message: "Confirm changes to Wi-Fi setup",
          overlayClassName: "cl-confirm-modal",
          buttons: [
            {
              label: "Cancel",
              onClick: () => {}
            },
            {
              label: "Change",
              className: "confirm-btn",
              onClick: () => submit()
            }
          ]
        });
      }
    }
  };

  const reset = (isInit?: boolean) => {
    setFormInvalid(false);
    setErrors({});
    setIsITContactsEdit(false);
    if (isInit) {
      initInfo();
    }
  };

  const isInvalidFiled = (name: string) => {
    return formInvalid && errors && errors[name];
  };

  return (
    <ClWiFiSetupModalWrapper>
      <div className="form-container">
        { isWifiAvailable ? <div className="title">Wi-Fi Setup</div> : null }
        { isWifiAvailable ? <p className="sub-title">RX-1mini requires 2.4GHz , primary Wi-Fi network</p> : null }
        { isWifiAvailable
          ? <div className="fields-combine-container">
            <p className="label">IT Contact Info</p>
            {isITContactsExist && !isITContactsEdit
              && <div className="field-container">
                <div className="field">
                  <p className="label2">Full Name</p>
                  <p className="value">{name.value}</p>
                </div>
                <div className="field">
                  <p className="label2">Email</p>
                  <p className="value">{email.value}</p>
                </div>
                <div className="field">
                  <p className="label2">Phone Number</p>
                  <p className="value">{phoneNumber.value}</p>
                </div>
                <div
                  className="edit-icon"
                  onClick={() => setIsITContactsEdit(true)}><CLIcon icon={Edit}></CLIcon></div>
              </div>
            }
          </div>
          : null
        }
        <form onSubmit={onSubmit} autoComplete="off">
          { isWifiAvailable
            ? <div>
              <div className="fields-container">
                {!isITContactsExist || isITContactsEdit
                  ? <>
                    <CLInput
                      label="Full Name"
                      name={`name${PortalUserAC}`}
                      maxLength={100}
                      placeholder="Full Name"
                      value={name.value}
                      testId={`name${PortalUserAC}`}
                      type="text"
                      width="100%"
                      isInvalid={isInvalidFiled("name")}
                      invalidText={"Please enter Full Name"}
                      handleChange={(event: any) => handleChangeField(event, name)}
                      autoCompleteName={`name${PortalUserAC}`}
                    ></CLInput>
                    <CLInput
                      label="Email"
                      name={`email${PortalUserAC}`}
                      testId={`email${PortalUserAC}`}
                      placeholder="Email"
                      maxLength={100}
                      value={email.value}
                      type={"email"}
                      width="100%"
                      isInvalid={isInvalidFiled("email")}
                      invalidText={ErrorMessages.wrongEmail}
                      handleChange={(event: any) => handleChangeField(event, email)}
                      autoCompleteName={`email${PortalUserAC}`}
                    ></CLInput>
                    <div className="invite-field">
                      <p>Phone Number</p>
                      <div className="form-field">
                        <InputMask
                          mask="+1 (999) 999 9999"
                          className={isInvalidFiled("phoneNumber") ? "input-mask invalid" : "input-mask"}
                          onChange={handleChangePhoneNumber}
                          placeholder={"Phone Number"}
                          width="100px"
                          value={phoneNumber.value}/>
                        {isInvalidFiled("phoneNumber")
                          ? <div className="invalid-text">Phone Number Invalid</div>
                          : null
                        }
                      </div>
                      <div className="br"></div>
                    </div>
                  </>
                  : null}
                <CLInput
                  label="SSID"
                  name={`ssid${PortalUserAC}`}
                  maxLength={100}
                  placeholder="SSID"
                  value={ssid.value}
                  testId={`ssid${PortalUserAC}`}
                  type="text"
                  width="100%"
                  isInvalid={isInvalidFiled("ssid")}
                  invalidText={"Please enter SSID"}
                  handleChange={(event: any) => handleChangeField(event, ssid)}
                  autoCompleteName={`ssid${PortalUserAC}`}
                ></CLInput>
                <CLInput
                  label="Password"
                  name={`passwordssid${PortalUserAC}`}
                  testId={`passwordssid${PortalUserAC}`}
                  placeholder="Password"
                  maxLength={100}
                  value={password.value}
                  type={isShowPassword ? "text" : "password"}
                  width="100%"
                  isInvalid={isInvalidFiled("password")}
                  invalidText={ErrorMessages.wrongPassword}
                  handleChange={(event: any) => handleChangeField(event, password)}
                  icon={isShowPassword ? PasswordHideIcon : PasswordShowIcon}
                  iconClick={showPassword}
                  autoCompleteName={"new-password"}
                ></CLInput>
                <p className="info">{`
                  If internal wifi network is not available, please send request to purchase access point
                  to `}<a href={getContactInfo().mailToEmail}>{getContactInfo().supportEmail}</a></p>
              </div>
            </div>
            : null
          }
          <div className="title">Phone Number</div>
          <div className="fields-container">
            <div className="invite-field">
              <p>Mobile phone for text notifications:</p>
              <div className="form-field">
                <InputMask
                  mask="+1 (999) 999 9999"
                  className={"input-mask"}
                  onChange={handleChangePhone}
                  placeholder={"Cell Phone Number"}
                  width="100px"
                  value={phoneNumberField}/>
              </div>
            </div>
          </div>
          <div className="btn-container">
            <CLLightButton type="button" text="Cancel" click={() => reset(true)}/>
            <CLBlueButton type="submit" text={"Save"} click={() => {}}/>
          </div>
        </form>
      </div>
    </ClWiFiSetupModalWrapper>
  );
});
