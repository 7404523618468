import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLAppHeaderOnlyLogo } from "../../components/cl-app-header";
import { SuccessMessages } from "../../boot/Constants";
import { useHistory } from "react-router";
import { VQAuthForgotPasssword } from "vivaquant-components-library";

export const ForgotPasswordPage = observer(() => {
  const { forgotPassword } = useRootStore().userStore;
  const {
    email,
    invalidEmail,
    isSubmit,
    isErrorForgot,
    isLoading,
    setEmail,
    setSubmit,
    setErrorForgot,
    setLoading,
    cleanData
  } = useRootStore().forgotFormStore;
  const history = useHistory();

  const onSubmit = async(event) => {
    event.preventDefault();
    setSubmit();
    if (invalidEmail || !email) { return; }
    setLoading(true);
    const url = `${window.location.origin}/recovery-password/`;
    const result = await forgotPassword(email, url);
    if (result) {
      cleanData();
      history.push({
        pathname: "/login",
        state: { message: SuccessMessages.successForgot }
      });
    } else {
      setErrorForgot(true);
    }
    setLoading(false);
  };

  return (
    <VQAuthForgotPasssword
      header={(<CLAppHeaderOnlyLogo></CLAppHeaderOnlyLogo>)}
      isLoading={isLoading}
      isErrorForgot={isErrorForgot}
      onSubmit={onSubmit}
      email={email}
      setEmail={setEmail}
      isSubmit={isSubmit}
      invalidEmail={invalidEmail}
    ></VQAuthForgotPasssword>
  );
});
