import styled from "styled-components";

export const NotificationsMobileStyle = styled.div`
  //position: relative;
  //display: flex;
  //flex-wrap: wrap;
  //min-height: 500px;
  width: auto;
  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;
