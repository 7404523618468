import API from "../../boot/Api";
import * as Types from "./api.types";
import { encodeData } from "../handlers/data.handler";

export const getUserApi = async(email: string, password: string): Promise<Types.ApiResponse> => {
  const result = API.post("PortalUser", {
    login: email,
    password: password
  });
  return await Types.getResponse(result);
};

export const getCurentUserApi = async(): Promise<Types.ApiResponse> => {
  const result = API.get("PortalUser/UpdatedPortalUser");
  return await Types.getResponse(result);
};

export const getMonitoringCenterApi = async(): Promise<Types.ApiResponse> => {
  const result = API.get("PortalUser/MonitoringCenters");
  return await Types.getResponse(result);
};

export const invitePatientToAppApi = async(id: string | number): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    idPatient: id
  });
  const result = API.post("PortalUser/InvitePatient", encodedData);
  return await Types.getResponse(result);
};

export const loginApi = async(email: string, password: string): Promise<Types.ApiResponse> => {
  const result = API.post("PortalUser/SendPassCode", {
    login: email,
    password: password
  });
  return await Types.getResponse(result);
};

export const validateCodeApi = async(email: string, code: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    emailOrUserName: email,
    code: code
  });
  const result = API.post("PortalUser/ValidatePassCode", encodedData);
  return await Types.getResponse(result);
};

export const updateDisclaimerApi
  = async(isAll: boolean, idPhysician: string, formData: FormData): Promise<Types.ApiResponse> => {
    const encodedData = encodeData({
      isForAllClinicPhysicians: isAll,
      idPhysician
    });
    const result = API.post(`PortalUser/UserDisclaimerAcknowledgement?${encodedData}`, formData);
    return await Types.getResponse(result);
  };

export const forgotPasswordApi = async(email: string, url: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    userEmail: email,
    urlPortal: url
  });
  const result = API.post("PortalUser/SendMessagePWD", encodedData);
  return await Types.getResponse(result);
};

export const recoveryPasswordApi = async(password: string, userId: number | string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    idPortalUser: userId,
    newUserP: password
  });
  const result = API.post("PortalUser/SetPWD", encodedData);
  return await Types.getResponse(result);
};

let time = 0;
let lastResponse: any;
export const getNotificationsApi
  = async(idClinic: string | number, idPhysician: string | number): Promise<Types.ApiResponse> => {
    if (time && time + 5000 > new Date().getTime()) return lastResponse;
    time = new Date().getTime();
    const encodedData = encodeData({
      idClinic: idClinic,
      idPhysician: idPhysician
    });
    const result = API.get(`ClinicReports/NotificationEvents?${encodedData}`);
    lastResponse = Types.getResponse(result);
    return await Types.getResponse(result);
  };

let timeD = 0;
let lastResponseD: any;
export const getProblemDevicesApi = async(): Promise<Types.ApiResponse> => {
  if (timeD && timeD + 5000 > new Date().getTime()) return lastResponseD;
  timeD = new Date().getTime();
  const result = API.get("ClinicDevices/GetProblemDevices");
  lastResponseD = Types.getResponse(result);
  return await Types.getResponse(result);
};

export const getSupportFilesApi = async(): Promise<Types.ApiResponse> => {
  const result = API.get("PortalUser/SupportPdfData");
  return await Types.getResponse(result);
};

export const updateNewsApi = async(dataStr: string): Promise<Types.ApiResponse> => {
  const result = API.post("PortalUser/UpdateWhatsNew", { FileContent: dataStr });
  return await Types.getResponse(result);
};

export const getSupportVideoApi = async(): Promise<Types.ApiResponse> => {
  const result = API.get("PortalUser/SupportVideoData");
  return await Types.getResponse(result);
};

export const getSupportNewsApi = async(): Promise<Types.ApiResponse> => {
  const result = API.get("PortalUser/WhatsNew");
  return await Types.getResponse(result);
};

export const updateUserProfileApi = async(data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    jsonUserProfile: data
  });
  const result = API.post(`/PortalUser/UpdateUserProfile?${encodedData}`);
  return await Types.getResponse(result);
};

export const profileSendCodeApi = async(email: string, phone: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    email: email,
    phone: phone
  });
  const result = API.post("PortalUser/SendPassCodeProfile", encodedData);
  return await Types.getResponse(result);
};

export const profileValidateCodeApi = async(emailOrUserName: string, code: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    emailOrUserName: emailOrUserName,
    code: code
  });
  const result = API.post("PortalUser/ValidatePassCodeProfile", encodedData);
  return await Types.getResponse(result);
};

export const postUserMandatoryMFA = async(formData: FormData): Promise<Types.ApiResponse> => {
  const result = API.post("PortalUser/UserMandatoryMFA", formData);
  return await Types.getResponse(result);
};

export const getStatReportByRecording = async(recordingId: string, folderName: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    recordingID: recordingId,
    folderName: folderName
  });
  const result = API.get(`ClinicReports/STATReports?${encodedData}`);
  return await Types.getResponse(result);
};

export const setEventsActionSTAT = async(idEvents: number[] | string[], status: number | string): Promise<any> => {
  const encodedData = encodeData({
    jsonIdEvents: JSON.stringify(idEvents),
    actionSTAT: status
  });
  const result
    = API.post(`ClinicReports/SetEventsActionSTAT?${encodedData}`);
  return await Types.getResponse(result);
};
