import { useRef, useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { CLBlueButton, CLRedButton } from "../shared/cl-button";
import { CLModal } from "../shared/cl-modal";
import { CL2faWrapper } from "./cl-action-2fa.presets";
import Signature, { SignatureRef } from "@uiw/react-signature";
import html2canvasModule from "html2canvas";
import { useHistory } from "react-router";
import { ClAlert } from "../shared/cl-alert";
import { CLSpiner } from "../shared/cl-spinner";

export const ClAction2fa = observer(() => {
  const { isAction2FA, setIsAction2FA, firstName, lastName, idPhysician, updateMFAction } = useRootStore().userStore;
  const $svg = useRef<SignatureRef>(null);
  const history = useHistory();

  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const onHide = () => {
    setIsAction2FA(false);
    setIsValid(false);
    setIsLoading(false);
  };
  const onHideAlert = () => {
    setShowAlert(false);
  };

  const handleClick = () => {
    getPicture();
  };
  const handleClickSecurity = () => {
    onHide();
    history.push("/user/profile", { highlight2AF: true });
  };
  const handleClear = (e: any) => {
    e.preventDefault();
    $svg.current?.clear();
    setIsValid(false);
  };
  const handleClickSignature = () => {
    setIsValid(true);
  };
  const handleUpdateMFAction = async(formData: FormData) => {
    return await updateMFAction(formData);
  };

  const movaCanvasToBlob = (canvas: any): Promise<Blob | null> => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(null);
        }
      });
    });
  };

  const getPicture = () => {
    setIsLoading(true);
    const hideElements = document.getElementsByClassName("hide-on-print");
    const selectElements = document.getElementById("global-container")?.getElementsByClassName("cl-select__control");
    if (selectElements?.length) {
      Array.from(selectElements).forEach(element => {
        element.classList.add("no-box-shadow");
      });
    }
    if (hideElements) {
      Array.from(hideElements).forEach(element => {
        element.classList.add("no-print");
      });
    }

    const studyElement = document.getElementById("global-container");
    const fileName = "disclaimer_2fa" + idPhysician + ".png";
    if (studyElement !== null) {
      return html2canvasModule(studyElement, {
        scrollX: 0,
        scrollY: 0,
        allowTaint: true,
        useCORS: true,
        logging: false
      }).then(function(canvas) {
        // document.body.appendChild(canvas);
        // let dataURL = canvas.toDataURL();
        return movaCanvasToBlob(canvas).then((blob: Blob | null) => {
          if (blob) {
            const formData = new FormData();
            formData.append("my-file", blob, fileName);
            return handleUpdateMFAction(formData);
          }
        });
      }).then(() => {
        if (selectElements?.length) {
          Array.from(selectElements).forEach(element => {
            element.classList.remove("no-box-shadow");
          });
        }
        if (hideElements) {
          Array.from(hideElements).forEach(element => {
            element.classList.remove("no-print");
          });
        }
        onHide();
        return true;
      });
    }
  };

  const renderSignatureComponent = () => {
    return (
      <div className="signature-wrapper">
        <a className={"link-button hide-on-print"} onClick={handleClear}>Clear</a>
        <Signature
          height={180}
          ref={$svg}
          onClick={handleClickSignature}
          onTouchEnd={handleClickSignature}
          options={{
            size: 6,
            smoothing: 0.46,
            thinning: 0.73,
            streamline: 0.5,
            easing: (t) => t,
            simulatePressure: true,
            last: true,
            start: {
              cap: true,
              taper: 0,
              easing: (t) => t
            },
            end: {
              cap: true,
              taper: 0,
              easing: (t) => t
            }
          }}
        />
      </div>
    );
  };

  const renderFieldComponent = () => {
    return (
      <div className="border-container">
        <div className="fields-wrapper">
          <div className="field-wrapper">
            <p className="label">Full Name</p>
            <p className="field">{firstName} {lastName}</p>
          </div>
          <div className="field-wrapper">
            <p className="label">Signature</p>
            {renderSignatureComponent()}
          </div>
        </div>
      </div>
    );
  };

  const renderChild = () => {
    return (<CL2faWrapper>
      <div className="modal-container" id="global-container">
        <div className="title">
          Please, confirm your action
        </div>
        <p className="description">
          <span>{ `Two-factor authentication reduces the risk of a data breach.
          If you choose to use RhythmAccess portal without two-factor authentication,
          please sign below acknowledging that you understand and accept the following risks:`}</span>
          <ul>
            <li>Increased vulnerability to unauthorized access to their account.</li>
            <li>Greater likelihood of data theft, and patient information exposure.</li>
            <li>Possible inability to recover lost or compromised data due to security breaches.</li>
          </ul>
        </p>
        {isLoading ? <div className="spiner"><CLSpiner></CLSpiner></div> : null}
        {renderFieldComponent()}
        <div className="footer hide-on-print">
          <div className="btn-wrapper">
            <CLRedButton
              isDisabled={!isValid || isLoading}
              click={handleClick}
              text="Confirm"
              width="200px"></CLRedButton>
          </div>
          <div className="btn-wrapper">
            <CLBlueButton
              isDisabled={isLoading}
              click={handleClickSecurity}
              text="Open Security Settings"
              width="200px"></CLBlueButton>
          </div>
        </div>
      </div>
    </CL2faWrapper>);
  };

  return (
    <CLModal
      title=""
      show={isAction2FA}
      hideCloseBtn={true}
      backdrop={"static"}
      dialogClassName="modal-dialog-medium2"
      handleHideModal={onHide}
    >
      {renderChild()}
      <ClAlert
        show={showAlert}
        onHide={onHideAlert}
        msg={"Something goes wrong! Please try again."}/>
    </CLModal>
  );
});
