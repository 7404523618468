import { CLDevicesHeaderWrapper } from "./cl-devices-list-header.presents";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLInput } from "../shared/cl-input";
import { useMemo } from "react";
import debounce from "lodash.debounce";
import SearchIcon from "../../assets/icons/search.svg";
import { CLIcon } from "../shared/cl-icon";
import LoadIcon from "../../assets/icons/export.svg";

export const CLDevicesListHeader = observer(() => {
  const {
    setSearch,
    searchValue,
    getTotalCount,
    fileName,
    setFirstPage
  } = useRootStore().listDevicesStore;

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
    refreshData();
  };

  const getURL = () => {
    if (!fileName) return "";
    const csvFile = new Blob([fileName], { type: "text/csv" });
    return window.URL.createObjectURL(csvFile);
  };

  const refreshData = useMemo(
    () => debounce(() => {
      setFirstPage();
      getTotalCount();
    }, 1000), []);

  return (
    <CLDevicesHeaderWrapper>
      <div className="margin-block-right">
        <CLInput
          handleChange={handleSearchChange}
          placeholder="Search"
          icon={SearchIcon}
          value={searchValue}
          width="375px"></CLInput>
        {fileName && <div
          className="download_link">
          <CLIcon icon={LoadIcon}/>
          <a
            href={getURL()}
            download={"device_list.csv"}
            target="_blank" rel="noreferrer">Export .csv</a>
        </div>}
      </div>
    </CLDevicesHeaderWrapper>
  );
});
