import { ClPatientInfoChartsStyle } from "./cl-patient-info-charts.present";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ClChartsCanvas } from "../cl-charts-canvas";
import { ClChartsEcg } from "../cl-charts-ecg";
import { getEventTypeString, getListECG } from "../../stores/patientInfo/patientInfo.helper.service";
import { CLSpiner } from "../shared/cl-spinner";
import { checkNextECG, checkPrevECG, getDateEnd, getDateStart } from "../cl-charts-canvas/cl-charts-canvas.service";
import { getActiveReportedItem } from "../cl-patient-info-header";

export const ClPatientInfoCharts = observer((props: any) => {
  const {
    activeEventType,
    isEmptyEvents,
    activeECG,
    additionalEvents,
    setAdditionalEvents,
    setActiveFolderName,
    setActiveDate,
    setActiveEventType
  } = useRootStore().patientInfoStore;

  const setActiveFolderName2 = (folderName: string) => {
    setActiveFolderName(folderName);
  };

  const nextECG = () => {
    const folderName = checkNextECG(props.activeFolderName, getListECG());
    setActiveFolderName(folderName + "");
  };

  const prevECG = () => {
    const folderName = checkPrevECG(props.activeFolderName, getListECG());
    setActiveFolderName(folderName + "");
  };

  const setPrevDay = () => {
    const activeDay = props.activeDate.subtract(1, "days");
    setDay(activeDay);
  };

  const setNextDay = () => {
    const activeDay = props.activeDate.add(1, "days");
    setDay(activeDay);
  };

  const setDay = (ev: any) => {
    const date = ev.format("MM/DD/yyyy");
    let isAnyEvents = false;
    props.events.forEach((obj: any) => {
      if (date === obj.key) {
        const event = getActiveReportedItem(obj.data);
        setActiveDate(ev);
        setActiveFolderName(event.FolderName + "");
        setActiveEventType(+event.Id);
        isAnyEvents = true;
      }
    });
    if (!isAnyEvents) {
      setActiveDate(ev, true);
    }
  };

  let isDisabledClick = false;

  if (props.patient && props.patient.RecordingID && props.patient.Device && props.patient.Device.DeviceSettings) {
    isDisabledClick = props.patient.Device.DeviceSettings.OperatingMode === 2;
  }
  if (!props.activeDate || !props.activeDate.format) {
    return (
      <ClPatientInfoChartsStyle>
        <div className="alert">
          No Patient Information. Please connect with Operator.
        </div>
      </ClPatientInfoChartsStyle>
    );
  }

  if (props.patient && props.patient.RecordingID && props.patient.Device && props.patient.Device.DeviceStatistics) {
    const dateStart = getDateStart(props.patient);
    const dateEnd = getDateEnd(props.patient);
    let date = moment(dateEnd).format("MM/DD/yyyy");
    if (props.activeDate && props.activeDate.format) {
      date = props.activeDate.format("MM/DD/yyyy");
    }

    const symtomaticEvents: any = [];
    let ecgEvents: any = {};

    if (additionalEvents && additionalEvents.EcgEvents) {
      if (additionalEvents.EcgEvents[0]) {
        ecgEvents = additionalEvents.EcgEvents[0];
      } else {
        ecgEvents = null;
      }
    } else if (props.events) {
      props.events.forEach((obj: any) => {
        if (date === obj.key) {
          obj.data.forEach((event: any) => {
            if (+event.Id === +activeEventType) {
              ecgEvents = event;
            }
          });
        }
      });
    }
    if (props.events) {
      props.events.forEach((obj: any) => {
        if (date === obj.key) {
          obj.data.forEach((event: any) => {
            const eventType = getEventTypeString(event);
            if (eventType && eventType !== "Rejected") {
              symtomaticEvents.push(event);
            }
          });
        }
      });
    }
    return (
      <ClPatientInfoChartsStyle>
        {props.activeFolderName && <ClChartsEcg patientId={props.patient.RecordingID}
          folderName={props.activeFolderName}
          ecgEvent={ecgEvents} selectedDay={date} isEmptyEvents={isEmptyEvents}
          nextEcg={nextECG} prevEcg={prevECG}></ClChartsEcg>}

        <ClChartsCanvas patientId={props.patient.RecordingID} selectedDay={date} events={symtomaticEvents}
          setActiveFolderName={setActiveFolderName2} dateStart={dateStart} setAdditionalEvents={setAdditionalEvents}
          activeEvent={ecgEvents} activeECG={activeECG} dateEnd={dateEnd} isDisabledClick={isDisabledClick}
          prevDay={setPrevDay} nextDay={setNextDay}
        ></ClChartsCanvas>
      </ClPatientInfoChartsStyle>
    );
  }
  if (props.patient && props.patient.Device) {
    return (<ClPatientInfoChartsStyle>
      <div className="alert">
                    No Patient Information. Please connect with Operator.
      </div>
    </ClPatientInfoChartsStyle>
    );
  }
  return (
    <ClPatientInfoChartsStyle>
      <div className="spiner"><CLSpiner></CLSpiner></div>
    </ClPatientInfoChartsStyle>
  );
});
