import styled from "styled-components";
import { PropsType } from "./cl-nav-item.props";
import { theme } from "../../../theme/theme";

export const NavItemStyle = styled.div<PropsType>`
  text-align: center;
  box-sizing: border-box;
  width: auto;
  position: relative;
  height: 100%;

  a {
    text-decoration: none;
    background: ${theme.colors.light};
    color: ${theme.colors.text};
    padding-right: 24px;
    padding-left: 24px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: ${props => props.fontSize ? props.fontSize : "18px"};
    line-height: ${props => props.fontSize ? props.fontSize + 2 : "20px"};
    white-space: nowrap;
  }

  .bottom-border {
    height: 3px;
    background: ${theme.colors.blue};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
