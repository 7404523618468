import styled from "styled-components";

export const ClChartSelectDatePresents = styled.div`
  display: flex;
  flex-direction: column;
  .date {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .ant-picker-input{
    flex-direction: row-reverse;
    .ant-picker-suffix{
      margin-right: 10px;
    }
  }
  .btn-wrapper{
    margin-right:12px;
    margin-left: 12px;
    position: relative;
    &.disabled{
      pointer-events: none;
    }
    &:last-child{
      margin-right: 0;
    }
  }
`;
