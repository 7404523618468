import styled from "styled-components";
import { theme } from "../../theme/theme";

export const HeaderMobileStyle = styled.div<{shadow?: boolean}>`
  .container {
    background: ${theme.colors.light};
    ${props => props.shadow ? "box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);" : ""}
    height: 60px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
  }
  
  &.center {
    justify-content: center;
  }
  .icon-settings{
    margin: 0 15px;
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
      display: inline-block;
    }  
    img {
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .logo {
    padding-top: 5px;
    height: 40px;
    width: auto;
  }
  .settings-container {
    padding: 6px 16px 16px;
    background: #FFF;
    border-top: 1px solid #E5E5E5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }  

  .logo-access {
    height: 64px;
    width: auto;
    margin-top: 24px;
  }

  .left-element {
    margin-left: auto;
  }

  .notification {
    cursor: pointer;
    .label {
      margin: 10px 0 8px 0;
      color: #6D6D6D;
      font-size: 12px;
      line-height: 14px;
    }  
    .cl-select__value-container {
      padding-top: 8px !important;
    }
  }

  .profile-img {
    box-shadow: 8px 8px 16px rgba(38, 86, 186, 0.04);
    border-radius: 8px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    margin-left: 24px;
    margin-right: 8px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .notification-count {
    margin-left: 24px;
    margin-right: 8px;
  }

  .cl-select__value-container {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: 38px;
  }
`;
