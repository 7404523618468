import { observer } from "mobx-react-lite";
import { ClWifiSetupModal } from "../../../components/cl-wifi-setup-modal";
import { SettingsPageStyle } from "./settings.presents";

export const SettingsPage = observer(() => {
  return (
    <SettingsPageStyle>
      <div className="wrap">
        <ClWifiSetupModal/>
      </div>
    </SettingsPageStyle>
  );
});
