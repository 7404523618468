import styled from "styled-components";
import { theme } from "../../theme/theme";

import { ClAdministrationInviteModalWrapper }
  from "../cl-administration-invite-modal/cl-administration-invite-modal.presents";

export const ClNoteModalWrapper = styled(ClAdministrationInviteModalWrapper)`
  .form-container {
    .wrapper {
      margin: 24px 0;
    }
    .note-container {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${theme.colors.text};
      padding: 24px;
      border: 1px solid ${theme.colors.background};
      border-radius: 10px;
      min-height: 250px;
    }
    .note-title {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
    p{
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
    .text-size {
      color: #8A8A8A;
    }
    .text-error {
      color: ${theme.colors.red};
    }
    .note-textarea{
      height: 250px;
      width: 100%;
      border: 1px solid ${theme.colors.background};
      box-sizing: border-box;
      border-radius: 10px;
      padding: 16px;
      resize: none;
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
    }
  }
`;
