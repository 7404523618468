import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLLightButton, CLBlueButton } from "../shared/cl-button";
import { message } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { ClAdministrationICD10ModalWrapper } from "./cl-administration-icd10.presents";
import { CLCheckbox } from "../shared/cl-checkbox";
import { useHistory } from "react-router";

export const ClAdministrationIcd10 = observer(() => {
  const {
    icd10,
    saveForm,
    setChecked,
    countActive
  } = useRootStore().adminICD10Store;

  const { getTotalCount } = useRootStore().listAdministrationStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const history = useHistory();

  const onSubmit = (event: any) => {
    event.preventDefault();

    setGlobalSpiner(true);
    saveForm()
      .then((result: boolean) => {
        if (result) {
          getTotalCount();
          setGlobalSpiner(false);
          confirmAlert({
            message: "ICD10 updated",
            overlayClassName: "cl-success-modal",
            buttons: [
              {
                label: "Ok",
                onClick: () => {}
              }
            ]
          });
        } else {
          setGlobalSpiner(false);
          message.error({ content: "Error, Something went wrong!", duration: 2 });
        }
      });
  };

  const handleChange = (event: any, item: any) => {
    const value = event.target.checked + "";
    setChecked(item, value);
  };

  return (
    <ClAdministrationICD10ModalWrapper>
      <div className="header-container">
        <h1>Review ICD-10 for clinic</h1>
      </div>
      <div className="overlay">
        <div className="form-container">
          <form onSubmit={onSubmit}>
            <div className={"fields-container" + " count" + countActive}>
              {icd10.map((item: any) => (
                <CLCheckbox
                  handleChange={(event: any) => handleChange(event, item)}
                  isChecked={item.isChecked === "true"}
                  label={item.value}
                  name={"icd10" + item.id}></CLCheckbox>
              ))}
            </div>
            <div className="btn-container">
              <CLLightButton type="button" text="Back" click={() => history.goBack()}/>
              <CLBlueButton type="submit" text={"Save"} click={() => {}}/>
            </div>
          </form>
        </div>
      </div>
    </ClAdministrationICD10ModalWrapper>
  );
});
