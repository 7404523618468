import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClAdministrationInviteModalWrapper = styled.div`
  .title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.text};
  }

  .form-container {
    padding: 40px;

    .fields-container {
      padding-top: 60px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .invite-field {
        width: 100%;
        max-width: 580px;
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;

      div:last-child {
        margin-left: 24px;
      }
    }
  }

  .content-info {
    display: flex;
    width: 100%;
    max-width: 580px;
    padding-left: 10px;
    padding-bottom: 14px;

    .content-item {
      padding-right: 40px;
  
      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: ${theme.colors.placeholder};
        padding-bottom: 4px;
      }
    
      .text {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: ${theme.colors.text};
      }
    }
  }
`;
