import styled from "styled-components";

export const CL2faWrapper = styled.div`
  .modal-container{
    padding: 24px;
  }
  .footer{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .btn-wrapper{
      &:first-child{
        margin-right: 24px;
      }
    }
  }
  .title{
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: #484848;
    margin-bottom: 24px;
  }

  .description{
    background: #FEEEEE;
    border-radius: 10px;
    padding: 16px;
    font-size: 14px;
    line-height: 18px;
    color: #484848;
  }
  .icon-wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
`;
