import { PropsType } from "./cl-icon.props";
import { IconStyle, IconStyleSort, IconStyleNoIcon, IconStyleDropdown } from "./cl-icon.presents";

export const CLIcon = (props: PropsType) => {
  return (
    <IconStyle {...props}>
      <img src={props.icon} alt=""></img>
    </IconStyle>
  );
};

export const ClIconSort = (props: PropsType) => {
  return (
    <IconStyleSort>
      <img src={props.icon} alt=""></img>
    </IconStyleSort>
  );
};

export const ClIconNoResult = (props: PropsType) => {
  return (
    <IconStyleNoIcon>
      <img src={props.icon} alt=""></img>
    </IconStyleNoIcon>
  );
};

export const ClIconDropdown = (props: PropsType) => {
  return (
    <IconStyleDropdown>
      <img src={props.icon} alt=""></img>
    </IconStyleDropdown>
  );
};
