import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLBillingUtilizationChartUsageWraper = styled.div`
  min-height: 200px;
  .header {
    display: flex;
    .header-item {
      flex: 1;
    }
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: ${theme.colors.text};
    }

    .info {
      text-align: center;
      margin-top: -2px;
      
      .number {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -1px;
        color: ${theme.colors.text};
        display: inline-block;
      }

      .summary {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: ${theme.colors.placeholder};
        display: inline-block;
        width: 70px;
        text-align: left;
        padding-left: 4px;
        vertical-align: super;
      }
    }
  }

  .label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${theme.colors.placeholder};
    padding-bottom: 16px;
    padding-top: 24px;
  }

  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .no-result-section {
    height: 50vh;
    display: flex;

    .no-result-content {
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .text {
        margin-top: 16px;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        color: ${theme.colors.text};
      }
    }
  }
`;
