import { useRootStore } from "../../stores/storeContext";
import { useState } from "react";
import { CLGreenButton, CLLightButton } from "../shared/cl-button";
import { ClChartModalsEventPresents } from "./cl-chart-modals-event.presents";
import { ClPatientInfoCreateEvent } from "../cl-patient-info-create-event/cl-patient-info-create-event";
import { ClPatientInfoIncludeToReport } from "../cl-patient-info-include-to-report/cl-patient-info-include-to-report";
import { observer } from "mobx-react-lite";

export const ClChartModalsEvent = observer(() => {
  const {
    events,
    activeTab,
    isEmptyEvents,
    activeFolderName
  } = useRootStore().patientInfoStore;
  const [modalShow, setModalShow] = useState(false);
  const [modalReportShow, setModalReportShow] = useState(false);

  const openCreateEvent = () => {
    setModalShow(true);
  };
  const openCreateReport = () => {
    setModalReportShow(true);
  };

  return (
    <ClChartModalsEventPresents>
      {location.pathname.indexOf("/events") >= 0 && <div className="actions-wraper">
        {(Object.keys(events).length === 0 || activeTab === "reports")
          && <div className={isEmptyEvents ? "btn-wrapper disabled" : "btn-wrapper"}>
            <CLLightButton
              padding={"10px"}
              click={openCreateReport}
              text="Include on Report"
              width="160px"></CLLightButton>
          </div>}
        {(Object.keys(events).length === 0 || activeTab === "events")
          && <div className={isEmptyEvents ? "btn-wrapper disabled" : "btn-wrapper"}>
            <CLGreenButton
              padding={"10px"}
              click={openCreateEvent}
              text="Create Event"
              width="160px"></CLGreenButton>
          </div>}
      </div>}

      {modalShow && <ClPatientInfoCreateEvent
        isShow={modalShow}
        activeFolderName={activeFolderName}
        closeModal={() => setModalShow(false)}></ClPatientInfoCreateEvent>}
      {modalReportShow && <ClPatientInfoIncludeToReport
        isShow={modalReportShow}
        activeFolderName={activeFolderName}
        closeModal={() => setModalReportShow(false)}></ClPatientInfoIncludeToReport>}
    </ClChartModalsEventPresents>
  );
});
