import { observer } from "mobx-react-lite";
import { UserManagementPageStyle } from "./userManagement.presents";
import { CLAdministrationListCategories } from "../../../components/cl-administration-list-categories";
import { CLAdministrationListTable } from "../../../components/cl-administration-list-table";
import { CLAdministrationListHeader } from "../../../components/cl-administration-list-header";
import { CLCard } from "../../../components/shared/cl-card";

export const UserManagementPage = observer(() => {
  return (
    <UserManagementPageStyle>
      <CLCard padding="24px">
        <CLAdministrationListHeader></CLAdministrationListHeader>
        <CLAdministrationListCategories></CLAdministrationListCategories>
        <CLAdministrationListTable></CLAdministrationListTable>
      </CLCard>
    </UserManagementPageStyle>
  );
});
