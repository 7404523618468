import { CLAdministrationListTableWraper } from "./cl-administration-list-table.presents";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { Table } from "react-bootstrap";
import { sortDirection, listAdministrationSort, administrationListCountRows } from "../../boot/Constants";
import { ClIconSort, ClIconNoResult, CLIcon } from "../shared/cl-icon";
import { CLSpiner } from "../shared/cl-spinner";
import { CLDropdown } from "../shared/cl-dropdown";
import SortListIcon from "../../assets/icons/sort-list.svg";
import ContentLoader from "react-content-loader";
import NoResultIcon from "../../assets/icons/no-result.svg";
import ActionIcon from "../../assets/icons/dots.svg";
import OnIcon from "../../assets/icons/on.svg";
import { confirmAlert } from "react-confirm-alert";
import { getUserGroup, isAdminsGroup } from "../../stores/helpers/user.store.helpers";

export const CLAdministrationListTable = observer(() => {
  const {
    items,
    isLoading,
    setSortBy,
    changeRole
  } = useRootStore().listAdministrationStore;

  const { openModal } = useRootStore().inviteAdminStore;
  const { inviting, idUser } = useRootStore().userStore;

  const getLoadContent = () => {
    const items = [];
    for (let i = 0; i < administrationListCountRows; i++) {
      items.push(<tr key={i.toString()}>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="140" height="26">
            <rect rx="4" ry="4" width="140" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="120" height="26">
            <rect rx="4" ry="4" width="120" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="120" height="26">
            <rect rx="4" ry="4" width="120" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
        <td className="action">
          <ContentLoader rtl={true} title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
      </tr>);
    }

    return items;
  };

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  const getAction = (item: any) => {
    const actions = [
      {
        text: "Edit",
        disabled: false,
        color: "",
        action: () => {
          openModal(item, idUser);
        }
      }
    ];

    if (item.IdLicenseRequest && idUser !== item.Id) {
      const isInviting = !isAdminsGroup(item);
      actions.push({
        text: "Change role",
        disabled: false,
        color: "",
        action: () => {
          confirmAlert({
            message: isInviting
              ? "Upgrade user to Admin"
              : "Downgrade user to Standard",
            overlayClassName: "cl-confirm-modal",
            buttons: [
              {
                label: "Cancel",
                onClick: () => {}
              },
              {
                label: "Change",
                className: "confirm-btn",
                onClick: () => changeRole(item.IdLicenseRequest, isInviting)
              }
            ]
          });
        }
      });
    }

    // We may need this functionality
    // if (!item.IsPhysician) {
    //   actions.push({
    //     text: "Delete",
    //     disabled: false,
    //     color: theme.colors.red,
    //     action: () => {
    //       confirmAlert({
    //         message: "Delete administrator?",
    //         overlayClassName: "cl-confirm-modal",
    //         buttons: [
    //           {
    //             label: "Cancel",
    //             onClick: () => {}
    //           },
    //           {
    //             label: "Delete",
    //             className: "delete-btn",
    //             onClick: () => deleteAdmin(item)
    //           }
    //         ]
    //       });
    //     }
    //   });
    // }

    return actions;
  };

  return (
    <CLAdministrationListTableWraper>
      <Table>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-title">First Name</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listAdministrationSort.name, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listAdministrationSort.name, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Last Name</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listAdministrationSort.lastname, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listAdministrationSort.lastname, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Email</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listAdministrationSort.email, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listAdministrationSort.email, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Role</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Type of user</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Primary Phone Number</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listAdministrationSort.phone, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listAdministrationSort.phone, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Text Notification Number</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">2FA Status</div>
              </div>
            </th>
            <th className="action sort">
              <div className="sort-title">Actions</div>
            </th>
          </tr>
        </thead>
        {(!isLoading && items.length > 0) && <tbody>
          {items.map((item: any, index: number) => <tr key={index.toString()}>
            <td>{item.Name || "-"}</td>
            <td>{item.LastName || "-"}</td>
            <td>{item.Email || "-"}</td>
            <td className={isAdminsGroup(item) ? "admin-role" : ""}>{getUserGroup(item)}</td>
            <td>{item.IsPhysician ? "Physician" : "User"}</td>
            <td>{item.Phone || "-"}</td>
            <td>{item.Description || "-"}</td>
            <td>{!item.DisablePasscode && <CLIcon icon={OnIcon} width="24px" height="24px"></CLIcon>}</td>
            <td className="action">
              {inviting && <CLDropdown
                icon={ActionIcon}
                actions={getAction(item)}
              ></CLDropdown>}
            </td>
          </tr>)}
        </tbody>}
        {isLoading && <tbody>
          {getLoadContent()}
          <div className="spiner"><CLSpiner></CLSpiner></div>
        </tbody>}
      </Table>
      {(!isLoading && !items.length) && <div className="no-result-section">
        <div className="no-result-content">
          <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
          <div className="text">Sorry, no results found</div>
        </div>
      </div>}
    </CLAdministrationListTableWraper>
  );
});
