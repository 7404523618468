import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClStudyEnrollmentChangeModalWrapper = styled.div`
  .title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.text};
  }
  .staticData{
    
    .label{
      font-size: 14px;
      line-height: 16px;
      color: #6D6D6D;
      margin-bottom: 8px;
    }
    .value-item{
      font-size: 16px;
      line-height: 18px;
      color: #484848;
    }
    .item{
      width: 100%;
      align-items: center;
      justify-content: space-between;
      display: flex;
      padding: 10px;
      position: relative;
    }
  }
  .cl-select__value-container--is-multi.cl-select__value-container--has-value .cl-select__multi-value{
    display: flex !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .field-checkbox {
    margin-left: -10px;
  }
  .signature {
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .date {
    font-size: 16px;
    font-weight: 500;
  }
  .item-physician {
    .label{
      font-size: 16px;
      font-weight: 500;
    }
    .value-item {
      font-size: 18px;
      font-style: italic;
    }
  }
  .notice {
    margin-bottom: 40px;
  }
  .title-group {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 20px;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    color: #484848;
  }
  .field-value {
    font-size: 16px;
    line-height: 18px;
    color: #484848;
  }
  .group {
    border-bottom: 2px solid ${theme.colors.greyBorder};
    padding-bottom: 20px;
  }
  .table-title {
    display: flex;
    justify-content: space-between;
    p{
      font-size: 14px;
      font-weight: 500;
    }
    p:nth-child(1) {
      margin-right: auto;
    }
    p:nth-child(2) {
      width: 300px;
    }
    p:nth-child(3) {
      padding-left: 10px;
      width: 300px;
    }
  }

  .form-container {
    padding: 40px;
    
    .field {
      display: flex;
      align-items: center;
      //justify-content: flex-end;
    }
    .label {
      margin-right: auto;
      font-size: 14px;
      line-height: 16px;
      color: #6D6D6D;
      margin-bottom: 8px;
    }
    
    .disabled-field {
      font-size: 14px;
      line-height: 16px;
      border: 1px solid #E5E5E5;
      color: #484848;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 16px;
      height: 48px;
      width: 100%;
    }

    .fields-container {
      padding-top: 10px;
      padding-bottom: 10px;

      .invite-field {
        width: 100%;
        max-width: 300px;
      }
      .disabled-indication-field {
        padding: 0;
        width: 300px;
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;

      div:last-child {
        margin-left: 24px;
      }
    }
  }

  .content-info {
    display: flex;
    width: 100%;
    max-width: 580px;
    padding-left: 10px;
    padding-bottom: 14px;

    .content-item {
      padding-right: 40px;
  
      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: ${theme.colors.placeholder};
        padding-bottom: 4px;
      }
    
      .text {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: ${theme.colors.text};
      }
    }
  }
`;
