import styled from "styled-components";
import { theme } from "../../../theme/theme";
import { PropsType } from "./cl-button.props";

export const BtnStyle = styled.div<PropsType>`
  button {
    background: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor ? props.borderColor : props.backgroundColor};
    color: ${props => props.color ? props.color : theme.colors.light};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 10px;
    width: ${props => props.width ? props.width : "200px"};
    height: 48px;
    ${props => props.padding ? `padding: ${props.padding};` : ""}
  }

  .btn-main-container {
    flex: 1;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &.icon {
      gap: 10px;
    }
  }

  button:disabled {
    opacity: .3;
  }
`;
