import { types, applySnapshot, flow, Instance, SnapshotIn } from "mobx-state-tree";
import API from "../../boot/Api";
import { updateDisclaimerApi } from "../../services/api/user.api";

export const NotificationModel = types
  .model("NotificationModel", {
    physicians: types.frozen([]),
    activePhysicians: types.optional(types.frozen(), {})
  })
  .actions(self => ({
    getUserFromList(physicianId: number) {
      const active = self.physicians.find((physician: any) => +physician.Id === +physicianId);
      return active;
    }
  }))
  .actions(self => {
    const resetForm = () => {
      applySnapshot(self, { physicians: [], activePhysicians: {} });
    };
    const setActivePhysicians = (physicianId: number) => {
      const active = self.getUserFromList(physicianId);
      if (active) {
        self.activePhysicians = active;
      }
    };
    const getClinicPhysicians = flow(function* () {
      try {
        const response = yield API.get("ClinicPhysicians");
        self.physicians = response.data;
        if (response.data && response.data[0] && response.data[0].NotificationCriteria) {
          self.activePhysicians = response.data[0];
        }
        return response.data;
      } catch (error) {
        console.log("error happens");
      }
    });
    const updateClinicDisclaimer = flow(function* (
      isForAllClinicPhysicians: boolean, idPhysician: string, formData: FormData) {
      try {
        const response = yield updateDisclaimerApi(isForAllClinicPhysicians, idPhysician, formData);
        if (response.ok) {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    });
    return {
      getClinicPhysicians,
      setActivePhysicians,
      updateClinicDisclaimer,
      resetForm
    };
  });

export interface INotificationModel extends Instance<typeof NotificationModel> {
}

export interface INotificationModelSnapShot extends SnapshotIn<typeof NotificationModel> {
}
