import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClWiFiSetupModalWrapper = styled.div`
  .title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.text};
  }
  .sub-title {
    font-weight: normal;
    margin-top: 10px;
    font-size: 18px;
    line-height: 20px;
    color: ${theme.colors.label};
  }
  .br {
    width: 100%;
    height: 1px;
    background: ${theme.colors.greyBorder};
    margin: 30px 0 10px;
  }
  .info {
    margin-top: 20px;
  }
  .fields-combine-container {
    margin: 30px auto 10px;
    .field-container {
      position: relative;
      padding: 16px;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .field {
      min-width: 50%;
      width: 50%;
      color: #484848;
    }
    .label2 {
      color: #6D6D6D;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .edit-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .form-container {
    padding: 40px 0;
    max-width: 600px;
    margin: 0 auto;
    .fields-container {
      & > div {
        margin-bottom: 12px;
      }
      padding-top: 10px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .invite-field {
        color: ${theme.colors.label};
        width: 100%;
        p {
          margin-bottom: 8px;
        }
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;

      div:last-child {
        margin-left: 24px;
      }
    }
  }
  .invalid-text {
    color: ${theme.colors.red};
  }
  .input-mask {
    font-size: 14px;
    line-height: 16px;
    border: 1px solid ${theme.colors.background};
    color: ${theme.colors.text};
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 16px;
    height: 48px;
    width: 100%;

    &::placeholder {
      color: ${theme.colors.placeholder}
    }
    
    &.invalid {
      border: 1px solid ${theme.colors.red};
    }
  }
  .content-info {
    display: flex;
    width: 100%;
    max-width: 580px;
    padding-left: 10px;
    padding-bottom: 14px;

    .content-item {
      padding-right: 40px;
  
      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: ${theme.colors.placeholder};
        padding-bottom: 4px;
      }
    
      .text {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: ${theme.colors.text};
      }
    }
  }
`;
