import { observer } from "mobx-react-lite";
import { CLBillingUtilizationChartUsageWraper } from "./cl-billing-utilization-chart-usage.presents";
import { theme } from "../../theme/theme";
import { useRootStore } from "../../stores/storeContext";
import { CLSpiner } from "../shared/cl-spinner";
import NoResultIcon from "../../assets/icons/no-result.svg";
import { ClIconNoResult } from "../shared/cl-icon";
import { formatDateWithShortNameMonth } from "../../services/handlers/date.handler";
import { chartUtilizationOptions } from "../../boot/Constants";
import CanvasJSReact from "../../assets/charts/@canvasjs/react-charts/canvasjs.react";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const CLBillingUtilizationChartUsage = observer(() => {
  const { isLoadingUtilization, usageData, usageDataAvg } = useRootStore().billingStore;

  const getOptionsUsage = () => {
    const options = {
      ...chartUtilizationOptions,
      toolTip: { ...chartUtilizationOptions.toolTip, content: "{y} Devices" },
      axisX: { ...chartUtilizationOptions.axisX, interval: 1 },
      data: [
        {
          type: "column",
          height: 300,
          color: theme.colors.blue,
          dataPoints: usageData.map((item: any) => {
            return {
              label: formatDateWithShortNameMonth(item.Day),
              y: item.CountActive
            };
          })
        }
      ]
    };

    return <CanvasJSChart options={options}></CanvasJSChart>;
  };

  return (
    <CLBillingUtilizationChartUsageWraper>
      {(!isLoadingUtilization && usageData.length > 0) && <div>
        <div className="header">
          <div className="header-item title">Usage</div>
          <div className="header-item info">
            <div className="number">{usageDataAvg}</div>
            <div className="summary">avg device per day</div>
          </div>
          <div className="header-item"></div>
        </div>
        <div className="label">Devices</div>
        <div className="chart">
          {getOptionsUsage()}
        </div>
      </div>}
      {isLoadingUtilization && <div className="spiner"><CLSpiner></CLSpiner></div>}
      {(!isLoadingUtilization && !usageData.length) && <div className="no-result-section">
        <div className="no-result-content">
          <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
          <div className="text">Sorry, no usage data</div>
        </div>
      </div>}
    </CLBillingUtilizationChartUsageWraper>
  );
});
