import { PropsType, PropsTypeCustomButton } from "./cl-button.props";
import { BtnStyle } from "./cl-button.presents";
import { theme } from "../../../theme/theme";

export const CLButton = (props: PropsType) => {
  const handleClick = (event: any) => {
    props.click(event);
  };
  const renderIcon = () => {
    if (props.icon) {
      return props.icon;
    }
  };
  const containerClass = props.icon ? "btn-main-container icon" : "btn-main-container";

  return (
    <BtnStyle {...props}>
      <button type={props.type} onClick={handleClick} disabled={props.isDisabled}>
        <p className={containerClass}>
          {props.icon ? renderIcon() : null}
          {props.text}
        </p>
      </button>
    </BtnStyle>
  );
};

export const CLGreenButton = (props: PropsTypeCustomButton) => {
  return <CLButton
    backgroundColor={theme.colors.green}
    text={props.text}
    width={props.width}
    type={props.type}
    padding={props.padding}
    isDisabled={props.isDisabled}
    click={props.click}
    icon={props.icon}></CLButton>;
};

export const CLBlueButton = (props: PropsTypeCustomButton) => {
  return <CLButton
    backgroundColor={theme.colors.blue}
    text={props.text}
    width={props.width}
    type={props.type}
    padding={props.padding}
    isDisabled={props.isDisabled}
    click={props.click}
    icon={props.icon}></CLButton>;
};

export const CLRedButton = (props: PropsTypeCustomButton) => {
  return <CLButton
    backgroundColor={theme.colors.amaranth}
    text={props.text}
    width={props.width}
    type={props.type}
    padding={props.padding}
    isDisabled={props.isDisabled}
    click={props.click}
    icon={props.icon}></CLButton>;
};

export const CLLightButton = (props: PropsTypeCustomButton) => {
  return <CLButton
    backgroundColor={theme.colors.light}
    borderColor={theme.colors.text}
    color={theme.colors.text}
    text={props.text}
    width={props.width}
    type={props.type}
    padding={props.padding}
    isDisabled={props.isDisabled}
    click={props.click}
    icon={props.icon}></CLButton>;
};
