import { PropsType } from "./cl-tooltip.props";
import { TooltipStyle } from "./cl-tooltip.presents";
import ReactTooltip from "react-tooltip";

export const ClTooltip = (props: PropsType) => {
  return (
    <TooltipStyle>
      <a data-tip data-for={props.id}>{props.children}</a>
      <ReactTooltip
        id={props.id}
        place={props.place ? props.place : "top"}
        type={props.type ? props.type : "light"}
        effect={props.effect ? props.effect : "solid"}
        className={props.class ? props.class : "cl-tooltip"}>
        {props.tooltipBlock}
      </ReactTooltip>
    </TooltipStyle>
  );
};
