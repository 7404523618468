import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CLModal } from "../shared/cl-modal";
import { CLSelect } from "../shared/cl-select";
import {
  PatientInfoCreateEventsModalContainerStyle,
  PatientInfoCreateEventsStyle
} from "./cl-patient-info-edit-include-to-report.presents";
import { PropsType } from "./cl-patient-info-edit-include-to-report.props";
import { ArrhythmiaType } from "../../stores/patientInfo/patientInfo.constant";
import { CLInput } from "../shared/cl-input";
import { OptionType } from "../shared/cl-select/cl-select.props";
import API from "../../boot/Api";
import * as Types from "../../services/api/api.types";
import { useRootStore } from "../../stores/storeContext";
import { encodeData } from "../../services/handlers/data.handler";

const updateReport = async(idEvent: number | string, caption: string, eventType: number | string): Promise<any> => {
  const encodedData = encodeData({
    idEvent: idEvent,
    eventType: eventType,
    caption: caption
  });
  const result = API.post(`ClinicReports/UpdateEvent?${encodedData}`);
  return await Types.getResponse(result);
};

export const ClPatientInfoEditIncludeToReport = observer((props: PropsType) => {
  const {
    patient,
    getPatient
  } = useRootStore().patientInfoStore;
  const activeArrhythmiaType: OptionType | undefined = ArrhythmiaType.find((item: OptionType) => {
    if (item && item.id && +item.id === +props.item.EventType) {
      return item;
    }
  });
  const [fields, setFields] = useState({
    eventType: {
      value: activeArrhythmiaType || { id: "3", label: "Pause", value: "Pause" }
    },
    caption: {
      value: props.item.EcgStripCaption
    }
  });

  const saveChanges = () => {
    const eventType = fields.eventType.value && fields.eventType.value.id || 0;
    updateReport(props.item.Id, fields.caption.value, eventType)
      .then((response) => {
        if (response.ok) {
          props.closeModal();
          getPatient(patient.RecordingID, true);
        } else {
          console.error("Something went wrong");
        }
      });
  };

  const handleSearchChange = (ev: any) => {
    const value = ev.target.value;
    setFields((prevValue) => ({ ...prevValue, caption: { value: value } }));
  };

  const selectOption = (ev: any) => {
    setFields((prevValue) => ({ ...prevValue, eventType: { value: ev } }));
  };

  const isDisabledButton = "";
  return (
    <PatientInfoCreateEventsStyle>
      <CLModal show={props.isShow} handleHideModal={() => props.closeModal()} title={"Create Event"}>
        <PatientInfoCreateEventsModalContainerStyle>
          <div className="modal-container" id="modal-container">
            <div className="title">
              Include to Report
            </div>
            <div className="tabs">
              <div className="form">
                <div className="form-field">
                  <label>
                    Event Type
                  </label>
                  <CLSelect width="100%" options={ArrhythmiaType} selectOption={selectOption} name={"type"}
                    defaultValue={fields.eventType.value}></CLSelect>
                </div>
                <div className="form-field">
                  <label>
                    Caption
                  </label>
                  <CLInput handleChange={handleSearchChange} placeholder="Add Caption" width="100%" name={"name"}
                    type={"text"} value={fields.caption.value}></CLInput>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="btn-wrapper">
                <CLLightButton click={props.closeModal} text="Cancel" width="200px"></CLLightButton>
              </div>

              <div className={"btn-wrapper " + isDisabledButton}>
                <CLBlueButton click={saveChanges} text="Save" width="200px"></CLBlueButton>
              </div>
            </div>
          </div>
        </PatientInfoCreateEventsModalContainerStyle>

      </CLModal>

    </PatientInfoCreateEventsStyle>
  );
});
