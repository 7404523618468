import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const PaginationStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;

  .info {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.text};
    min-width: 150px;
    text-align: center;
  }

  .click {
    cursor: pointer;
  }

  .last-hidden {
    opacity: 0;
  }
`;
