import { validatePhoneHandler, validatesZipCodeHandler } from "../../services/handlers/validate.handler";

export const ValidateField = (form: any, isPhysicianNPINumber?: boolean, isPhysician?: any)
: {errors: {[key: string]: string}, isValid: boolean} => {
  const errors: {[key: string]: string} = {};
  let isValid: boolean = true;

  for (const key in form) {
    const field = form[key];
    // reqired field
    if (field.fieldId === "twoFactorAuth") {
      if (!field.isChecked) {
        errors[field.fieldId] = "Please enable two-factor authentication";
      }
    }

    if (isPhysicianNPINumber && !!isPhysician && field.fieldId === "npi") {
      if (field.value.length && field.value.length !== 10) {
        isValid = false;
        errors[field.fieldId] = "Need 10 digit number";
      }
    }

    if (field.isOptional) {
      continue;
    }
    if (field.validateTimeZone) {
      if (!field.value) {
        errors.zip = "Please fill USA zip";
        isValid = false;
      }
    }
    if (field && field.fieldId && !field.isOptional) {
      if (!field.value) {
        errors[field.fieldId] = "Please fill the field";
        isValid = false;
      }
    }
    if (field.type === "number") {
      if (field.value.indexOf("_") + 1) {
        isValid = false;
        errors[field.fieldId] = "Please enter valid phone number";
      }
    }
    if (field.validatePhone) {
      if (!validatePhoneHandler(field.value)) {
        isValid = false;
        errors[field.fieldId] = "Please enter valid phone number";
      }
    }
    if (field.validateZip) {
      if (!validatesZipCodeHandler(field.value)) {
        isValid = false;
        errors[field.fieldId] = "Please enter valid zip";
      }
    }
    if (field.fieldId === "username") {
      if (field.value.length < 4) {
        isValid = false;
        errors[field.fieldId] = "Username should consist not less than with 4 symbols";
      }
    }
  }

  return {
    errors,
    isValid
  };
};

export const isNumber = (str: string) => {
  const pattern = new RegExp(/^[0-9\b]+$/);
  return pattern.test(str);
};
