import { CLAdministrationHeaderWrapper } from "./cl-administration-list-header.presents";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLInput } from "../shared/cl-input";
import { CLGreenButton } from "../shared/cl-button";
import SearchIcon from "../../assets/icons/search.svg";
import debounce from "lodash.debounce";
import { ClAdministrationInviteModal } from "../cl-administration-invite-modal";
import { useMemo } from "react";

export const CLAdministrationListHeader = observer(() => {
  const {
    setSearch,
    searchValue,
    getTotalCount,
    setFirstPage
  } = useRootStore().listAdministrationStore;
  const { openModal } = useRootStore().inviteAdminStore;
  const { inviting } = useRootStore().userStore;
  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
    refreshData();
  };

  const openCreateUser = () => {
    openModal();
  };

  const refreshData = useMemo(
    () => debounce(() => {
      setFirstPage();
      getTotalCount();
    }, 1000), []);

  return (
    <CLAdministrationHeaderWrapper>
      <div className="margin-block-right">
        <CLInput
          handleChange={handleSearchChange}
          placeholder="Search (First Name, Last Name, etc.)"
          icon={SearchIcon}
          value={searchValue}
          width="375px"></CLInput>
      </div>
      <div className="create">
        {inviting && <CLGreenButton
          click={openCreateUser}
          text="Invite"
          width="256px"></CLGreenButton>
        }
      </div>
      <ClAdministrationInviteModal></ClAdministrationInviteModal>
    </CLAdministrationHeaderWrapper>
  );
});
