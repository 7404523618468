import { types, flow } from "mobx-state-tree";
import { getSupportFilesApi, getSupportNewsApi, getSupportVideoApi, updateNewsApi } from "../services/api/user.api";
import { IObservableArray } from "mobx";
import { openNewTab } from "../services/handlers/data.handler";

const FirstVideoFileName = "RhythmAccess - Patient Tutorial Video";

export const SupportModel = types
  .model("SupportStore", {
    files: types.optional(types.array(types.frozen()), []),
    videos: types.optional(types.array(types.frozen()), []),
    news: types.optional(types.array(types.frozen()), []),
    stringNews: types.optional(types.string, "")
  })
  .actions(() => {
    const setVideoPriority = (videos: any): any => {
      const videosResult = [];
      for (let i = 0; i < videos.length; i++) {
        if (videos[i].Name === FirstVideoFileName) {
          videosResult.unshift(videos[i]);
        } else {
          videosResult.push(videos[i]);
        }
      }
      return videosResult;
    };
    return { setVideoPriority };
  })
  .actions((self) => ({
    setNews(news: any) {
      self.news = news;
    },
    setStringNews(news: any) {
      self.stringNews = news;
    }
  }))
  .actions(self => {
    const getFiles = flow(function* getFiles() {
      try {
        const result = yield getSupportFilesApi();
        if (result.ok) {
          self.files = result.data as IObservableArray;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const getVideos = flow(function* getFiles() {
      try {
        const result = yield getSupportVideoApi();
        if (result.ok) {
          self.videos = self.setVideoPriority(result.data) as IObservableArray;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const getNews = flow(function* getNews() {
      try {
        const result = yield getSupportNewsApi();
        if (result.ok) {
          try {
            self.setStringNews(result.data);
            self.setNews(JSON.parse(result.data));
          } catch (e) {
            self.setNews([]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    });

    const downloadURI = (name: string, url: string) => {
      openNewTab(name, url);
    };

    return { getFiles, getVideos, downloadURI, getNews };
  })
  .actions(() => {
    const updateNews = flow(function* updateNews(str) {
      const result = yield updateNewsApi(str);
      if (result.ok) {
        return true;
      }
      return false;
    });
    return { updateNews };
  });
