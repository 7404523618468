import { CLReportsListTableWraper } from "./cl-reports-list-table.presents";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { Table } from "react-bootstrap";
import { ClIconSort, ClIconNoResult, CLIcon } from "../shared/cl-icon";
import ContentLoader from "react-content-loader";
import {
  sortDirection,
  listReportsSort,
  reportsListCountRows,
  DeviceModes,
  ReportTypeArr
} from "../../boot/Constants";
import SortListIcon from "../../assets/icons/sort-list.svg";
import NoResultIcon from "../../assets/icons/no-result.svg";
import { CLSpiner } from "../shared/cl-spinner";
import { formatDate } from "../../services/handlers/date.handler";
import OnIcon from "../../assets/icons/on.svg";
import OffIcon from "../../assets/icons/off.svg";
import { useHistory } from "react-router";

export const CLReportsListTable = observer(() => {
  const {
    items,
    isLoading,
    setSortBy,
    openReport,
    setPrinted
  } = useRootStore().listReportsStore;
  const { clearURLs } = useRootStore().reportStore;
  const { setPatient } = useRootStore().patientInfoStore;

  const history = useHistory();

  const getLoadContent = () => {
    const items = [];
    for (let i = 0; i < reportsListCountRows; i++) {
      items.push(<tr key={i.toString()}>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="30" height="26">
            <rect rx="4" ry="4" width="30" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="100" height="26">
            <rect rx="4" ry="4" width="100" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="100" height="26">
            <rect rx="4" ry="4" width="160" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
      </tr>);
    }

    return items;
  };

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  const getOperatingMode = (item: any) => {
    if (item.OperatingMode !== null) {
      return item.HPDays > 0
        ? "Holter+ MCT"
        : DeviceModes[item.OperatingMode];
    } else {
      return "-";
    }
  };

  const viewLink = (ev: any, item: any) => {
    ev.preventDefault();
    openReport(item.Id);
  };

  const setPrintedHandler = (ev: any, item: any) => {
    ev.preventDefault();
    setPrinted(item.Id, !item.IsPrinted);
  };

  const onSignNow = (item: any) => {
    if (item.RecordingID) {
      clearURLs();
      setPatient({});
      history.push(`/patient-info/${item.RecordingID}/reports`, { returnToReports: item.Id });
    }
  };

  return (
    <CLReportsListTableWraper>
      <Table>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-title">Patient Name</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Patient ID</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Report Date</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listReportsSort.reportdate, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listReportsSort.reportdate, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Signed</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Report Type</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Serial</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Recording ID</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Mode</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Ordering Physician</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Reading Physician</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Report link</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Printed</div>
              </div>
            </th>
          </tr>
        </thead>
        {(!isLoading && items.length > 0) && <tbody>
          {items.map((item: any) => <tr>
            <td>{item.PatientName || "-"}</td>
            <td>{item.PatientIDClinic || "-"}</td>
            <td>{item.ReportDate ? formatDate(item.ReportDate) : "-"}</td>
            <td>{
              item.IsSigned
                ? <CLIcon icon={OnIcon} width="24px" height="24px"></CLIcon>
                : <span className="link" onClick={() => onSignNow(item)}>Sign Now</span>
            }</td>
            <td>{ReportTypeArr[item.ReportType] || "-"}</td>
            <td>{item.DeviceSN || "-"}</td>
            <td>{item.RecordingID || "-"}</td>
            <td>{getOperatingMode(item)}</td>
            <td>{item.PhysicianOrdering || "-"}</td>
            <td>{item.PhysicianReading || "-"}</td>
            <td><span className="link" onClick={(ev: any) => viewLink(ev, item)}>View Report</span></td>
            <td>
              <span className="pointer" onClick={(ev: any) => setPrintedHandler(ev, item)}>
                <CLIcon icon={item.IsPrinted ? OnIcon : OffIcon} width="24px" height="24px"></CLIcon>
              </span>
            </td>
          </tr>)}
        </tbody>}
        {isLoading && <tbody>
          {getLoadContent()}
          <div className="spiner"><CLSpiner></CLSpiner></div>
        </tbody>}
      </Table>
      {(!isLoading && !items.length) && <div className="no-result-section">
        <div className="no-result-content">
          <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
          <div className="text">Sorry, no results found</div>
        </div>
      </div>}
    </CLReportsListTableWraper>
  );
});
