import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const ClAlertStyle = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 450px;

  .container {
    padding: 16px 24px;
    background: ${theme.colors.light};
    border-radius: 10px;
    border-left: 25px solid ${theme.colors.red};
    width: 100%;
    white-space: pre-line;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05);
    
    p {
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    }

    &.error {
      background: ${theme.colors.backgroundMessageError};
    }
  }
`;

export const CLAlertCloseBtnStyle = styled.div`
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  left: auto;
`;
