import { useRootStore } from "../../stores/storeContext";
import { getActiveReportedItem } from "../cl-patient-info-header";
import moment from "moment";
import { isNullDate } from "../../services/handlers/date.handler";
import { DatePicker } from "antd";
import { useState } from "react";
import { ClChartSelectDatePresents } from "./cl-chart-select-date.presents";

export const ClChartSelectDate = () => {
  const {
    patient,
    setActiveDate,
    activeDate,
    events,
    setActiveFolderName,
    setActiveEventType
  } = useRootStore().patientInfoStore;
  const [showDatePickerForEvents, setShowDatePickerForEvents] = useState(true);

  const format = "MM/DD/yyyy";
  const handleDatePick = (ev: any) => {
    // Update Selected event type
    const date = ev.format("MM/DD/yyyy");
    let isAnyEvents = false;
    events.forEach((obj: any) => {
      if (date === obj.key) {
        const event = getActiveReportedItem(obj.data);
        setActiveDate(ev);
        setActiveFolderName(event.FolderName + "");
        setActiveEventType(+event.Id);
        isAnyEvents = true;
      }
    });
    if (!isAnyEvents) {
      setActiveDate(ev, true);
    }
  };

  const disabledDate = (current: any) => {
    if (patient
      && patient.Device
      && patient.Device.DeviceStatistics
      && patient.Device.DeviceStatistics.DeviceRecordingStartDate
    ) {
      if (current && current < moment(patient.Device.DeviceStatistics.DeviceRecordingStartDate).startOf("day")) {
        return true;
      }
      if (current && current > moment(isNullDate(patient.Device.DeviceStatistics.DeviceRecordingEndDate)
        ? patient.Device.DeviceStatistics.LastTransmission
        : patient.Device.DeviceStatistics.DeviceRecordingEndDate)
      ) {
        return true;
      }
    }
    return false;
  };

  const defaultValue = (value: any) => {
    if (patient
      && patient.Device
      && patient.Device.DeviceStatistics
      && patient.Device.DeviceStatistics.DeviceRecordingStartDate
    ) {
      let dataM = moment(isNullDate(patient.Device.DeviceStatistics.DeviceRecordingEndDate)
        ? patient.Device.DeviceStatistics.LastTransmission
        : patient.Device.DeviceStatistics.DeviceRecordingEndDate);
      if (value) {
        dataM = moment(value, format);
      }
      return dataM
        .startOf("month");
    }
    return moment();
  };

  let value = null;
  if (activeDate && activeDate.format) {
    value = activeDate.format(format);
  } else
  if (patient.Device && patient.Device.DeviceStatistics && patient.Device.DeviceStatistics.DeviceRecordingStartDate) {
    value = moment(patient.Device.DeviceStatistics.DeviceRecordingStartDate)
      .add(patient.Device.DeviceSettings.Duration, "hours").format(format);
  }

  return (
    <ClChartSelectDatePresents>
      <div className="date">
        {showDatePickerForEvents && <DatePicker
          value={(value && moment(value, format)) || undefined}
          onChange={handleDatePick}
          bordered={false}
          allowClear={false}
          format={format}
          onOpenChange={(isOpen: boolean) => {
            if (!isOpen) {
              setShowDatePickerForEvents(false);
              setShowDatePickerForEvents(true);
            }
          }}
          defaultPickerValue={defaultValue(value)}
          disabledDate={disabledDate}/>}
      </div>
    </ClChartSelectDatePresents>
  );
};
