import { observer } from "mobx-react-lite";
import { NotificationsMobileStyle } from "./NotificationsMobile.present";
import { CLSpiner } from "../../components/shared/cl-spinner";
import { useRootStore } from "../../stores/storeContext";
import { ClMobileNotification } from "../../components/cl-mobile-notifications";

export const NotificationsMobilePage = observer(() => {
  const { newNotifications, lastNotifications } = useRootStore().userStore;

  if (!newNotifications || !lastNotifications) {
    return (
      <NotificationsMobileStyle>
        <div className="spiner"><CLSpiner></CLSpiner></div>
      </NotificationsMobileStyle>
    );
  }

  return (
    <NotificationsMobileStyle>
      <ClMobileNotification></ClMobileNotification>
    </NotificationsMobileStyle>
  );
});
