import { NotificationCriteria } from "../../boot/enums/NotificationCriteria";
import { NotificationRadioStates } from "../../boot/enums/NotificationRadioStates";
import { NotificationSelectStates } from "../../boot/Constants";

const getOptioins = (min: number, max: number, increment: number, additionalValue?: number) => {
  const arr: any[] = [];
  if (additionalValue) {
    arr.push({
      value: additionalValue + "",
      label: additionalValue + ""
    });
  }
  for (let i = min; i <= max; i = i + increment) {
    arr.push({
      value: i + "",
      label: i + ""
    });
  }
  return arr;
};

export const setRadioState = (notificationCriteria: any) => {
  const data: any = {};
  Object.keys(NotificationRadioStates)
    .forEach((key : string) => {
      data[key] = NotificationCriteria[+notificationCriteria[key] || 1];
    });

  return data;
};

export const setSelectState = (notificationCriteria: any) => {
  const data: any = {};

  function getDefaultNCValue(value: number | undefined, defaultValue: number | string) {
    if (value || value === 0) {
      return value;
    } else {
      return defaultValue;
    }
  }

  NotificationSelectStates.forEach((item : any) => {
    data[item.name] = {
      options: item.increment
        ? getOptioins(item.minValue, item.maxValue, item.increment, item.additionalValue)
        : item.disabled
          ? [{
            value: item.defaultValue + "",
            label: item.defaultValue + ""
          }]
          : item.options || [],
      value: item.disabled ? item.defaultValue : getDefaultNCValue(notificationCriteria[item.name], item.defaultValue),
      disabled: item.disabled
    };
  });

  return data;
};
