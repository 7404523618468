import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLPatientReportsListWraper = styled.div`
  margin-left: 24px;
  
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${theme.colors.text};
    padding-left: 24px;
  }

  .nav-container {
    display: flex;
    margin-top: 4px;
    
    .nav-item-container {
      height: 40px;
      width: 40px;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    .nav-item {
      display: flex;
      flex: 1;
      justify-content: center;
      padding-bottom: 16px;
      padding-top: 16px;
      cursor: pointer;
      position: relative;
    }

    .active {
      box-shadow: inset 0px -3px 0px 0px ${theme.colors.blue};
    }
  }

  .list-container {
    .day-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      opacity: 0.7;
      padding: 16px 24px;
      border-top: 1px solid ${theme.colors.background};
      border-bottom: 1px solid ${theme.colors.background};
    }

    .report-item {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      margin: 8px;
      padding: 16px;
      padding-left: 14px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;

      .time {
        padding-left: 6px;
      }

      .signed {
        position: absolute;
        right: 16px;
      }
    }

    .active-item {
      background: rgba(38, 86, 186, 0.08);
      border-radius: 8px;
    }
    .report-type {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;
      display: inline-block;
      padding: 3px 5px;
      border-radius: 6px;
      color: #FFF;
    }
  }

  .no-results {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${theme.colors.text};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
  }
`;
