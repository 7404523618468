import { types } from "mobx-state-tree";

export const GlobalStoreModel = types
  .model("GlobalStoreModel", {
    showGlobalSpiner: types.maybe(types.boolean)
  })
  .actions(self => {
    const setGlobalSpiner = (show: boolean) => {
      self.showGlobalSpiner = show;
    };

    return { setGlobalSpiner };
  });
