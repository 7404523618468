import { PropsType } from "./cl-category-item.props";
import { CategoryItemStyle } from "./cl-category-item.presents";

export const CLCategoryItem = (props: PropsType) => {
  const handleClick = () => {
    props.onChange(props.value);
  };

  return (
    <CategoryItemStyle
      className={`${props.isActive ? "active" : ""}${!props.count ? " no-select" : ""}`}
      onClick={handleClick}>
      {props.icon && <img src={props.icon} alt=""
        className={props.count || props.title ? "active" : ""}></img>}
      <div>
        {props.title && <span className="title">{props.title}</span>}
        {!!props.count && <span> ({props.count})</span>}
      </div>
      <div className="bottom-border"></div>
    </CategoryItemStyle>
  );
};
