import Modal from "react-bootstrap/Modal";
import { PropsType } from "./cl-modal.props";
import { CLModalCloseBtnStyle, CLModalStyle } from "./cl-modal.presents";
import { CLIcon } from "../cl-icon";
import Close from "../../../assets/icons/close.svg";
import * as React from "react";

export const CLModal = (props: PropsType) => {
  const renderModalBody = () => {
    return (<>{props.children}</>);
  };

  const handleCloseBtn = () => {
    props.handleHideModal && props.handleHideModal();
  };

  const handleEscapeKeyDown = (event: any) => {
    if (props.hideCloseBtn) {
      event.preventDefault();
    }
  };

  return (
    <CLModalStyle {...props}>
      <Modal
        onHide={() => {
          props.handleHideModal && props.handleHideModal();
        }}
        onRequestClose={console.log("request close")}
        onEscapeKeyDown={handleEscapeKeyDown}
        title={props.title}
        show={props.show}
        size="xl"
        dialogClassName={props.dialogClassName || undefined}
        animation={false}
        backdrop={props.backdrop}
        centered
      >
        <Modal.Body style={{ position: "relative" }}>
          {!props.hideCloseBtn && <CLModalCloseBtnStyle onClick={handleCloseBtn}>
            <CLIcon icon={Close} padding="7px"></CLIcon>
          </CLModalCloseBtnStyle>}
          {renderModalBody()}
        </Modal.Body>
      </Modal>
    </CLModalStyle>
  );
};
