import { observer } from "mobx-react-lite";
import { BillingListStyle } from "./BillingList.presents";
import { CLCard } from "../../../components/shared/cl-card";
import { ClBillingListTable } from "../../../components/cl-billing-list-table";

export const BillingListPage = observer(() => {
  return (
    <BillingListStyle>
      <CLCard padding="24px">
        <ClBillingListTable></ClBillingListTable>
      </CLCard>
    </BillingListStyle>
  );
});
