import { useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { CLBlueButton } from "../shared/cl-button";
import { useHistory } from "react-router";
import { CLModal } from "../shared/cl-modal";
import { CL2faWrapper } from "./cl-alert-2fa.presets";

export const ClAlert2faHero = observer(() => {
  const { disablePasscode, optOut2FA, isBannerShow, setIsBannerShow } = useRootStore().userStore;
  const [showAlert, setShowAlert] = useState(!optOut2FA && disablePasscode && isBannerShow);
  const history = useHistory();
  const onHide = () => {
    setIsBannerShow(false);
    setShowAlert(false);
  };
  const handleClick = () => {
    onHide();
    history.push("/user/profile", { highlight2AF: true });
  };

  const renderChild = () => {
    return (<CL2faWrapper>
      <div className="modal-container">
        <div className="title">
          Security
        </div>
        <p className="description">
          70% of all ransomware attacks in the U.S. are targeted at small healthcare facilities.<br />
          Don’t be the cause of a successful ransomware attack on your practice or facility!!<br />
          You can be a hero standing tall against ransomware attacks on your practice/facility.</p>
        <div className="footer">
          <div className="btn-wrapper">
            <CLBlueButton
              click={handleClick}
              text="Be a Hero!"
              width="200px"></CLBlueButton>
          </div>
        </div>
      </div>
    </CL2faWrapper>);
  };

  return (
    <CLModal
      title=""
      show={showAlert}
      hideCloseBtn={false}
      backdrop={"static"}
      dialogClassName="modal-dialog-medium"
      handleHideModal={onHide}
    >
      {renderChild()}
    </CLModal>
  );
});
