import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { PatientInfoEventsTab } from "./cl-patient-info-events-tab.presents";
import { ClPatientInfoEventsItem } from "../cl-patient-info-events-item";
import { getEventTypeString } from "../../stores/patientInfo/patientInfo.helper.service";
import { PropsType } from "./cl-patient-info-events-tab.props";
import { ClPatientInfoEditEvent } from "../cl-patient-info-edit-event/cl-patient-info-edit-event";
import { useState } from "react";
import { EventActionCP, sortDirection } from "../../boot/Constants";
import { CLIcon } from "../shared/cl-icon";
import SortIcon from "../../assets/icons/sort.svg";

const listEvents = [
  { icon: "All", type: "all", id: 0, count: 1 },
  { icon: "Confirmet", id: 3, count: 1 },
  { icon: "Profile", id: 2, count: 1 },
  { icon: "Rejected", id: 4, count: 1 }];

export const ClPatientInfoEventsTab = observer((props: PropsType) => {
  const {
    activeEventType,
    trackEvent,
    sortEvents,
    changeSortEventsType
  } = useRootStore().patientInfoStore;
  const [modalShow, setModalShow] = useState(null);

  const getItemType = (listItem: any) => {
    if (listItem) {
      return getEventTypeString(listItem);
    }
  };

  const isDisplay = (listItem: any): boolean => {
    if (+listItem.EventSource === 77 || +listItem.EventSource === 40) {
      return false;
    }
    const itemType = getItemType(listItem);
    if ((props.activeFilter === 0 && itemType !== "Rejected"
            || listEvents[props.activeFilter] && listEvents[props.activeFilter].icon === itemType
            && listItem.eventStatus !== 0)) {
      return true;
    } else if (itemType === "Rejected"
      && listEvents[props.activeFilter] && listEvents[props.activeFilter].icon === itemType) {
      return true;
    }
    return false;
  };

  const isDisplayDay = (listItems: any): boolean => {
    let isOneItemExist = false;
    listItems.forEach((item: any) => {
      if (isDisplay(item)) {
        isOneItemExist = true;
        return true;
      }
    });

    return isOneItemExist;
  };

  const setEvent = (listItem: any) => {
    props.setEvent(listItem);
  };

  const editItem = (listItem: any) => {
    setModalShow(listItem);
  };

  return (
    <PatientInfoEventsTab>
      <div
        className="sort-container"
        onClick={() => {
          changeSortEventsType(sortEvents === sortDirection.DESC ? sortDirection.ASC : sortDirection.DESC);
        }}>
        <CLIcon icon={SortIcon}></CLIcon>
        <div className="text">Sort by date: <span>{sortEvents === sortDirection.DESC ? "Newest" : "Oldest"}</span></div>
      </div>
      {(sortEvents === sortDirection.DESC ? props.events : [...props.events].reverse()).map((item: any) => {
        if (item && item.data && item.data.length && isDisplayDay(item.data)) {
          return (
            <div className="block" key={item.key}>
              <h3 className={"event-date"}>{item.key}</h3>
              <div className={"list"}>
                {(sortEvents === sortDirection.DESC ? item.data : [...item.data].reverse()).map((listItem: any) => {
                  if (isDisplay(listItem)) {
                    return (<ClPatientInfoEventsItem
                      key={listItem.Id}
                      item={listItem}
                      isActive={activeEventType === listItem.Id}
                      isNew={listItem.EventActionCP === EventActionCP.STATNew}
                      setEvent={() => setEvent(listItem)}
                      editItem={() => editItem(listItem)}
                      type="events"
                      trackNewEvent={() => trackEvent(listItem.Id)}
                    ></ClPatientInfoEventsItem>);
                  }
                })}
              </div>
            </div>
          );
        }
      })}

      {modalShow && <ClPatientInfoEditEvent
        isShow={!!modalShow}
        item={modalShow}
        closeModal={() => setModalShow(null)}></ClPatientInfoEditEvent>}
    </PatientInfoEventsTab>
  );
});
