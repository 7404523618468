import { PropsType } from "./cl-message.props";
import { MessageStyle } from "./cl-message.presents";

export const CLMessage = (props: PropsType) => {
  return (
    <MessageStyle width={props.width}>{props.text}</MessageStyle>
  );
};

export const CLMessageError = (props: PropsType) => {
  return (
    <MessageStyle width={props.width} className="error">{props.text}</MessageStyle>
  );
};
