import styled from "styled-components";
import { PropsType } from "./cl-patient-events-nav.props";
import { theme } from "../../theme/theme";

export const PatientEventsNavStyle = styled.div<PropsType>`
  text-align: center;
  box-sizing: border-box;
  width: auto;
  position: relative;
  cursor: pointer;
  height: 100%;
    //border-bottom: 1px solid ${theme.colors.background};
  &.disabled {
    pointer-events: none;
    }
  }

  .icon-holder {
    text-decoration: none;
    background: ${theme.colors.light};
    color: ${theme.colors.text};
    height: 100%;
    display: flex;
    align-items: center;
    font-size: ${props => props.fontSize ? props.fontSize : "18px"};
    line-height: ${props => props.fontSize ? props.fontSize + 2 : "20px"};
  }

  .bottom-border {
    height: 3px;
    background: ${theme.colors.blue};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .counter {
    background: #FFFFFF;
    border: 1px solid ${theme.colors.background};
    box-sizing: border-box;
    position: absolute;
    top: 10px;
    z-index: 1;
    right: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    color: ${theme.colors.text};
    text-align: center;
  }
`;
