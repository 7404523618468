/* eslint-disable max-len */
import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const CheckboxStyle = styled.div`
  position: relative;
 
    /* input with type="checkbox" */
    .custom-checkbox>input {
      position: absolute;
      //z-index: -1;
      opacity: 0;
      cursor: pointer;
    }

    /* for element label, that is inside .custom-checkbox */
    .custom-checkbox>span {
      display: inline-flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      &.error-label {
        color: ${theme.colors.red};
      }
    }

    /* create in label ::before element whit next styles */
    .custom-checkbox>span::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid ${theme.colors.greyBorder};
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      transition: all ease-in-out 0.3s;
      cursor: pointer;
    }
    .custom-checkbox.error-label>span::before {
      border: 1px solid ${theme.colors.red};
    }

    /* hover checkbox */
    .custom-checkbox>input:not(:disabled):not(:checked)+span:hover::before {
      border-color: ${theme.colors.blue};
    }

    /* active checkbox */
    .custom-checkbox>input:not(:disabled):active+span::before {
      background-color: ${theme.colors.blue};
      border-color: ${theme.colors.blue};
    }

    /* focus checkbox */
    .custom-checkbox>input:focus+span::before {
     border-color: ${theme.colors.blue};
    }

    /* focus and NOT checked */
    .custom-checkbox>input:focus:not(:checked)+span::before {
       border-color: ${theme.colors.blue};
    }

    /* checked checbox */
    .custom-checkbox>input:checked+span::before {
      background-color: ${theme.colors.blue};
      border-color: ${theme.colors.blue};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    /* disabled checkbox*/
    .custom-checkbox>input:disabled+span::before {
      background-color: #e9ecef;
      border-color: #e9ecef;
    }
`;
