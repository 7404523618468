import React, { useMemo, useState } from "react";
import { ClChartsCanvasStyles } from "./cl-charts-canvas.presents";
import { PropsType } from "./cl-charts-canvas.props";
import CanvasJSReact from "../../assets/charts/@canvasjs/react-charts/canvasjs.react";
import moment from "moment";
import {
  getListECG,
  getValidBeats, setListECG,
  setValidBeats
} from "../../stores/patientInfo/patientInfo.helper.service";
import { CLSpiner } from "../shared/cl-spinner";
import { setHRChartRef } from "../../stores/patientInfo/patientInfo.chartexport.service";
import {
  BeatsType, checkECGByTime, fetchECGListDates,
  fetchList, generateActiveEvents, generateECG,
  generateEvent,
  getDiffFromStartOfDay, setECGTimer
} from "./cl-charts-canvas.service";
import { CLIcon } from "../shared/cl-icon";
import RightIcon from "../../assets/icons/right.svg";
import LeftIcon from "../../assets/icons/left.svg";
import { ClChartSelectDate } from "../cl-chart-select-date/cl-chart-select-date";
import { ClTooltip } from "../shared/cl-tooltip";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ClChartsCanvasFunction(props: PropsType) {
  const [stateRender, setStateRender] = useState<string>("");
  const [ecgGetTimeout, setEcgGetTimeout] = useState();
  const [isLoading, setIsLoading] = useState(false);

  let options: any = {};
  let dataEvent: BeatsType[] = [];
  let dataECG: BeatsType[] = [];
  let timeoutGetAllBeats: any;

  useMemo(() => {
    setIsLoading(true);
    let cleanupFunction = false;
    const fetchData = async function() {
      try {
        const response: BeatsType[] | undefined = await fetchList(props.patientId, props.selectedDay, 6);
        const listECG = await fetchECGListDates(props.patientId, props.selectedDay);
        if (!cleanupFunction) {
          setValidBeats(response);
          setListECG(listECG.data);
          setStateRender("" + props.patientId + "_" + props.selectedDay + "_" + 6);
          setIsLoading(false);
        }
      } catch (e) {
        if (!cleanupFunction) {
          setIsLoading(false);
        }
      }
    };

    if (ecgGetTimeout) {
      clearTimeout(ecgGetTimeout);
    }

    fetchData().then(() => {
      cleanupFunction = true;
      timeoutGetAllBeats = setTimeout(() => {
        if (cleanupFunction) {
          fetchList(props.patientId, props.selectedDay, 2).then((response: BeatsType[] | undefined) => {
            setValidBeats(response);
            setStateRender(props.patientId + "_" + props.selectedDay + "_" + 2);
          });
        }
      }, 10000);
      setEcgGetTimeout(timeoutGetAllBeats);
      setECGTimer(timeoutGetAllBeats);
    });
  }, [props.patientId, props.selectedDay]);
  if (!stateRender && !getValidBeats()) {
    return (
      <ClChartsCanvasStyles>
        <div className="spiner"><CLSpiner id="hr-trend" boxShadow={"none"}></CLSpiner></div>
      </ClChartsCanvasStyles>
    );
  } else {
    const stripLines = generateActiveEvents(props.events);
    if (props.activeEvent && props.activeEvent.StartDate) {
      dataEvent = generateEvent(props.activeEvent);
    }
    dataECG = generateECG(props.activeECG);
    options = {
      animationEnabled: false,
      exportEnabled: false,
      height: 240,
      zoomEnabled: true,
      rangeChanged: function(ev: any) {
        console.log(ev);
      },
      theme: "light2", // "light1", "dark1", "dark2"
      toolTip: {
        enabled: false // enable here
      },
      axisX: {
        stripLines: stripLines
      },
      axisY: {
        maximum: 250,
        minimum: 0
      },
      data: [{
        click: function(e: any) {
          if (!props.isDisabledClick) {
            if (e.dataPoint && e.dataPoint.x) {
              const point = e.dataPoint.x;
              const time = moment(point).format("MM/DD/yyyy HH:mm:ss");
              const folderName = checkECGByTime(time, getListECG());
              if (folderName) {
                props.setAdditionalEvents({});
                props.setActiveFolderName(folderName + "");
              }
            }
          }
        },
        type: "scatter",
        markerSize: 3,
        color: "rgba(0,0,0,0.25)",
        xValueType: "dateTime",
        dataPoints: getValidBeats()
      }, {
        type: "stepArea",
        markerSize: 2,
        lineColor: "rgba(69, 126, 245, 0.5)",
        color: "rgba(69, 126, 245, 0.15)",
        dataPoints: dataEvent
      }, {
        type: "scatter",
        markerSize: 2,
        lineColor: "rgba(69, 126, 245, 0.5)",
        color: "rgba(69, 126, 245, 0.15)",
        dataPoints: [{
          color: "transparent",
          x: getDiffFromStartOfDay(props.selectedDay),
          y: 0
        }, {
          color: "transparent",
          x: getDiffFromStartOfDay(props.selectedDay) + 86400000,
          y: 0
        }]
      }]
    };
    if (dataECG && dataECG.length) {
      options.data.push({
        type: "stepArea",
        markerSize: 2,
        lineColor: "rgba(57,212,141,0.5)",
        color: "rgba(69,245,92,0.15)",
        dataPoints: dataECG
      });
    }
  }
  const onRef = (ref: any) => {
    setHRChartRef(ref);
  };
  const validSelectedDay = props.selectedDay && props.selectedDay !== "01/01/0001";
  const isPrevShow = validSelectedDay && moment(props.selectedDay) > moment(props.dateStart).startOf("day");
  const isNextShow = validSelectedDay && moment(props.selectedDay) < moment(props.dateEnd);

  const nextEcg = () => {
    props.nextDay && props.nextDay();
  };
  const prevEcg = () => {
    props.prevDay && props.prevDay();
  };

  if (isLoading) {
    return (
      <ClChartsCanvasStyles>
        <div className="spiner"><CLSpiner id="hr-trend" boxShadow={"none"}></CLSpiner></div>
      </ClChartsCanvasStyles>
    );
  }

  if ((stateRender === props.patientId + "_" + props.selectedDay + "_" + 6
      || stateRender === props.patientId + "_" + props.selectedDay + "_" + 2)
    && getValidBeats() && getValidBeats().length) {
    return (
      <ClChartsCanvasStyles>
        <ClChartSelectDate/>
        <div className="holder">
          <div>
            <CanvasJSChart options={options}
              onRef={onRef}></CanvasJSChart>
          </div>
        </div>
        <div className="control">
          <div className="left" onClick={prevEcg}>
            {isPrevShow && <ClTooltip
              id={"eventPrevHR"}
              class="cl-tooltip"
              tooltipBlock={<div>prior day</div>}>
              <CLIcon width="18px" icon={LeftIcon}></CLIcon>
            </ClTooltip>}
          </div>
          <div className="right" onClick={nextEcg}>
            {isNextShow && <ClTooltip
              id={"eventNextHR"}
              class="cl-tooltip"
              tooltipBlock={<div>next day</div>}>
              <CLIcon width="18px" icon={RightIcon}></CLIcon>
            </ClTooltip>}
          </div>
        </div>
      </ClChartsCanvasStyles>
    );
  } else if (!getValidBeats() || !getValidBeats().length) {
    return (
      <ClChartsCanvasStyles>
        <ClChartSelectDate/>
        <h6>No data received</h6>
      </ClChartsCanvasStyles>
    );
  } else {
    return (
      <ClChartsCanvasStyles>
        <div className="spiner"><CLSpiner id="hr-trend" boxShadow={"none"}></CLSpiner></div>
      </ClChartsCanvasStyles>
    );
  }
}

function areEqual(prevProps: PropsType, nextProps: PropsType) {
  if (prevProps.activeECG !== nextProps.activeECG) {
    return false;
  } else if (nextProps.activeEvent && prevProps.activeEvent && nextProps.activeEvent.Id !== prevProps.activeEvent.Id) {
    return false;
  } else if (prevProps.patientId + "_" + prevProps.selectedDay === nextProps.patientId + "_" + nextProps.selectedDay) {
    return true;
  }
  return false;
}

export const ClChartsCanvas = React.memo(ClChartsCanvasFunction, areEqual);
