import { PropsType } from "./cl-progress.props";
import { ProgressStyle } from "./cl-progress.presents";

export const CLProgress = (props: PropsType) => {
  return (
    <ProgressStyle {...props}>
      {props.text && <span>{props.text}</span>}
    </ProgressStyle>
  );
};
