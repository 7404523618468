import { CLNotificationPhysiciansWrapper } from "./cl-notification-physicians.present";
import { CLSelect } from "../shared/cl-select";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";

const getPhysicianOptionsList = async(physicians: any) => {
  return physicians.map((physician: any) => {
    return {
      value: physician.Id,
      label: physician.Name + " " + physician.LastName
    };
  });
};

export const CLNotificationPhysicians = observer(() => {
  const { physicians, setActivePhysicians } = useRootStore().notificationStore;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPhysicianOptionsList(physicians)
      .then((newList: any) => {
        setList(newList);
        setLoading(false);
      });
  }, [physicians]);

  const practiceSelected = (event: any) => {
    setActivePhysicians(event.value);
  };
  return (
    <CLNotificationPhysiciansWrapper>

      {(!loading && list.length > 0) && <CLSelect
        options={list}
        padding={"16px"}
        placeholder="All Physicians"
        defaultValue={list[0]}
        selectOption={practiceSelected}></CLSelect>}
    </CLNotificationPhysiciansWrapper>
  );
});
