import styled from "styled-components";

export const UserProfileStyles = styled.div`
  background: #fff;
  margin-top: 1px;
  position: relative;
  margin-bottom: 150px;
  min-height: 300px;
  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .back{
    position: absolute;
    top: 42px;
    left:20px;
  }
`;
