import styled from "styled-components";
import { theme } from "../../theme/theme";

export const AdministrationListStyle = styled.div`
  // padding: ${theme.offsets.mainContentVertical} ${theme.offsets.mainContent};
  .header {
    position: relative;
    z-index: 2;
    background: ${theme.colors.light};
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
    height: 82px;
    padding-left: ${theme.offsets.mainContent};
    padding-right: ${theme.offsets.mainContent};
    border-top: 1px solid  ${theme.colors.background};
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1400px;
  }
  .nav-wraper {
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: center;
  }
  .content{
    padding-bottom: 20px;
  }
  .wrap {
    padding: ${theme.offsets.mainContentVertical} ${theme.offsets.mainContent};
    
  }
`;
