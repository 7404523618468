import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClChartsCanvasStyles = styled.div`
  position: relative;
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  background: ${theme.colors.light};
  padding: 24px;
  margin-bottom: 24px;
  min-height: 366px;
  width: 100%;
  
  .holder{
    max-width: 100%;
    overflow: auto;
    height: 250px;
    & > div{
      height: 250px;
      width: 100%;
    }
    .canvasjs-react-chart-container-3{
      height: 240px !important;
    }
  }
  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .control{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
`;
