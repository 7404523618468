import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const InputStyle = styled.div<{icon?: string, width?: string, label?: string}>`
  position: relative;
  width: ${props => props.width ? props.width : "200px"};

  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.label};
    margin-bottom: 8px;
  }

  input {
    font-size: 14px;
    line-height: 16px;
    border: 1px solid ${theme.colors.background};
    color: ${theme.colors.text};
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 16px;
    height: 48px;
    width: 100%;
    
    ${props => props.icon ? "padding-right:40px;" : ""}

    &::placeholder {
      color: ${theme.colors.placeholder}
    }
  }

  img {
    display: block;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    ${props => props.label ? "margin-top: 13px;" : ""};
    cursor: pointer;
  }

  .invalid-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.red};
    margin-top: 4px;
    display: none;
  }

  &.invalid {
    input {
      border: 1px solid ${theme.colors.red};
    }

    img {
      ${props => props.label ? "margin-top: 3px;" : ""};
    }

    .invalid-text {
      display: block;
    }
  }
`;
