import { PropsType } from "./cl-checkbox.props";
import { CheckboxStyle } from "./cl-checkbox.presents";

export const CLCheckbox = (props: PropsType) => {
  const handleChange = (event: any) => {
    props.handleChange(event);
  };

  const handleFocus = (event: any) => {
    if (!props.onFocus) return;
    props.onFocus(event);
  };
  const error = (!props.value || props.value === "false") && props.errorFiled;
  if (!props.value) {
    return (
      <CheckboxStyle>
        <label className={`custom-checkbox ${error ? "error-label" : ""}`}>
          <input
            onFocus={handleFocus}
            onChange={handleChange}
            type='checkbox'
            checked={props.isChecked}
            id={props.name || "custom-checkbox"}
            name={props.name || "custom-checkbox"}
            disabled={props.disabled}
            className="checkbox-handle"
          ></input>
          <span className="checkbox-name checkbox-handle">{props.label}</span>
        </label>
      </CheckboxStyle>
    );
  } else {
    return (
      <CheckboxStyle>
        <label className={`custom-checkbox ${error ? "error-label" : ""}`}>
          <input
            onFocus={handleFocus}
            onChange={handleChange}
            value={props.value}
            checked={props.isChecked || props.value === "true"}
            type='checkbox'
            id={props.name || "custom-checkbox"}
            name={props.name || "custom-checkbox"}
            disabled={props.disabled}
            className="checkbox-handle"
          ></input>
          <span className="checkbox-name checkbox-handle">{props.label}</span>
        </label>
      </CheckboxStyle>
    );
  }
};
