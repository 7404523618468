import styled from "styled-components";
import { theme } from "../../theme/theme";

export const BillingHeaderStyle = styled.div`
  background: ${theme.colors.light};
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  height: 82px;
  padding-left: ${theme.offsets.mainContent};
  padding-right: ${theme.offsets.mainContent};
  border-top: 1px solid  ${theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1400px;

  .nav-wraper {
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: center;
  }
  
  .date-picker{
    display: flex;
    .ant-picker {
      border: 0;
      .ant-picker-input > input {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        width: 85px;
      }
    }
    .ant-picker-range-separator{ 
      //display: none;
      text-align: center;
      padding: 0;
      .anticon {
        width: 6px;
        height: 7px;
        border-top: 1px solid rgba(0, 0, 0, 0.85);
        margin: 0 auto;
        svg {
          display: none;  
        }
      }
      
    }
    .ant-picker-suffix {
      display: none;
    }
  }
`;
