import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { observer } from "mobx-react-lite";
import { CLModal } from "../shared/cl-modal";
import { StudyConfirmModalContainerStyle, StudyConfirmModalStyle } from "./cl-study-confirm-modal.presents";
import { PropsType } from "./cl-study-confirm-modal.props";
import StudySuccess from "../../assets/icons/study-success.svg";
import { CLIcon } from "../shared/cl-icon";
export const ClStudyConfirmModal = observer((props: PropsType) => {
  return (
    <StudyConfirmModalStyle>
      <CLModal
        show={props.isShow}
        handleHideModal={() => props.closeModal()}
        title={props.textTitle ? props.textTitle : "Device is ready for study"}
        hideCloseBtn={true}>
        <StudyConfirmModalContainerStyle>
          <div className="modal-container">
            <div className="success-icon">
              <CLIcon icon={StudySuccess} padding="7px" width="52px" height="52px"></CLIcon>
            </div>
            <div className="title">
              {props.textTitle ? props.textTitle : "Device is ready for study"}
            </div>
            <div className="content">
              <p className="description">To achieve best results, prep skin for device: </p>
              <div className="list">
                <div className="list-item">1. Shave area if hair is present;</div>
                <div className="list-item">2. Vigorously clean skin with prep pad for 20 sec;</div>
                <div className="list-item">3. Attach device to patient;</div>
                <div className="list-item">4. Press and hold Red button to start study.</div>
              </div>
              <p className="info">
                Patient information will appear in the portal within two minutes of enrollment</p>
            </div>

            <div className="footer">
              <div className="btn-wrapper">
                <CLLightButton
                  click={props.closeModal}
                  text="Ok"
                  width="200px"></CLLightButton>
              </div>
              {props.isPDFAvailable
                ? <div className="btn-wrapper">
                  <CLBlueButton
                    click={props.downloadPDFEnrollment}
                    text="Download Enrolment PDF"
                    width="200px"></CLBlueButton>
                </div>
                : null}
            </div>
          </div>
        </StudyConfirmModalContainerStyle>

      </CLModal>

    </StudyConfirmModalStyle>
  );
});
