import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLPatientsListCategoriesWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  border-bottom: 1px solid ${theme.colors.background};
  align-items: flex-end;

  .pagination {
    padding-bottom: 7px;
    margin-left: auto;
  }
`;
