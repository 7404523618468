import html2canvasModule from "html2canvas";
import jsPDF from "jspdf";

export const generatePDFService = (documentName: string) => {
  const chartElement = document.getElementById("study-form");
  const chartHeader = document.getElementById("print-mode");
  // eslint-disable-next-line
  
  const name = documentName;
  if (chartElement !== null) {
    const calendar = document.getElementsByClassName("calendar")[0];
    const selects = document.getElementsByClassName("cl-select__control");
    const controlls = document.getElementsByClassName("back");
    const hideElements = document.getElementsByClassName("hide-on-print");
    chartHeader?.classList.add("print");
    for (let i = 0; i < selects.length; i++) {
      const select = selects[i];
      select.classList.add("no-shadow");
    }
    for (let i = 0; i < controlls.length; i++) {
      const controll = controlls[i];
      controll.classList.add("hide");
    }
    for (let i = 0; i < hideElements.length; i++) {
      const hideElement = hideElements[i];
      hideElement.classList.add("hide");
    }
    chartElement?.classList.add("no-shadow");
    calendar.setAttribute("style", "opacity: 0;");
    html2canvasModule(chartElement, {
      scrollX: 0,
      scrollY: 0,
      allowTaint: true,
      useCORS: true,
      logging: false
    }).then(function(canvas) {
      // document.body.appendChild(canvas);
      const width = canvas.width;
      const height = canvas.height;
      // eslint-disable-next-line
      const pdf = new jsPDF("p", "px", [height, width]);
      pdf.addImage(canvas, "PNG", 0, 0, width, height);
      pdf.save(name);
    });
    calendar.setAttribute("style", "opacity: 1;");
    for (let i = 0; i < selects.length; i++) {
      const select = selects[i];
      select.classList.remove("no-shadow");
    }
    for (let i = 0; i < controlls.length; i++) {
      const controll = controlls[i];
      controll.classList.remove("hide");
    }
    for (let i = 0; i < hideElements.length; i++) {
      const hideElement = hideElements[i];
      hideElement.classList.remove("hide");
    }
    chartElement?.classList.remove("no-shadow");
    chartHeader?.classList.remove("print");
  }
};
