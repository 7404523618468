import { PropsType } from "./cl-pagination.props";
import { PaginationStyle } from "./cl-pagination.presents";
import { CLIcon } from "../cl-icon";
import { theme } from "../../../theme/theme";
import Left from "../../../assets/icons/left.svg";
import Right from "../../../assets/icons/right.svg";

export const CLPagination = (props: PropsType) => {
  const next = (event: any) => { props.next(event); };
  const previous = (event: any) => { props.previous(event); };

  const getCurrents = () => {
    const maxValue = props.countOnPage * (props.page + 1);
    return `${props.page * props.countOnPage + 1} -
      ${maxValue > props.total ? props.total : maxValue}`;
  };

  const showNextBtn = () => {
    return (props.page + 1) * props.countOnPage < props.total;
  };

  const showPreviouBtn = () => {
    return props.page !== 0;
  };

  return (
    <PaginationStyle>
      {showPreviouBtn() && <div className="click" onClick={previous} >
        <CLIcon icon={Left} borderColor={theme.colors.background} padding="11px"></CLIcon>
      </div>}
      <div className="info">{getCurrents()} of {props.total}</div>
      { showNextBtn() && <div className="click" onClick={next}>
        <CLIcon icon={Right} borderColor={theme.colors.background} padding="11px"></CLIcon>
      </div>}
      { !showNextBtn() && <div className="last-hidden">
        <CLIcon icon={Right} borderColor={theme.colors.background} padding="11px"></CLIcon>
      </div> }
    </PaginationStyle>
  );
};
