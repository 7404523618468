import { PropsType } from "./cl-radio.props";
import { RadioStyle } from "./cl-radio.presents";

export const ClRadio = (props: PropsType) => {
  const handleChange = (event: any) => {
    props.handleChange(event);
  };

  const handleFocus = (event: any) => {
    if (!props.onFocus) return;
    props.onFocus(event);
  };
  const id = props.id ? "id-" + props.id : props.name || "id-radio";
  return (
    <RadioStyle>
      <div className="custom-radio">
        <input
          onFocus={handleFocus}
          onChange={handleChange}
          value={props.value}
          checked={props.isChecked || props.value === "true"}
          type="radio"
          id={id}
          name={props.name || "radio"}
          disabled={props.disabled}
          className="radio-handle"
        ></input>
        <span className="fill"></span>
      </div>
      {props.label && <label htmlFor={id}>{props.label}</label>}
    </RadioStyle>
  );
};
