import { PropsType } from "./cl-alert.props";
import { CLAlertCloseBtnStyle, ClAlertStyle } from "./cl-alert.presents";
import { CLIcon } from "../cl-icon";
import Close from "../../../assets/icons/close.svg";

export const ClAlert = (props: PropsType) => {
  if (!props.show) return (<></>);
  const renderComponent = () => {
    return (props.child);
  };

  return (
    <ClAlertStyle {...props} onClick={props.onHide}>
      {!props.hideCloseBtn && <CLAlertCloseBtnStyle>
        <CLIcon icon={Close} width="10px" height="10px" padding="7px"></CLIcon>
      </CLAlertCloseBtnStyle>}
      <div className="container">
        {props.child && renderComponent()}
        {props.msg && <p>{props.msg}</p>}
      </div>
    </ClAlertStyle>
  );
};
