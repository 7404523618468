import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const MessageStyle = styled.div<{width?: string}>`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.text};
  padding: 24px;
  background: ${theme.colors.backgroundMessage};
  border-radius: 10px;
  width: ${props => props.width ? props.width : "100%"};
  white-space: pre-line;

  &.error {
    background: ${theme.colors.backgroundMessageError};
  }
`;
