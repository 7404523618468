import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { StudyCreateStyle } from "./StudyCreate.presents";
import { useRootStore } from "../../stores/storeContext";
import { ClPatientStudyInfo } from "../../components/cl-patient-study-info";
import { ClStudyCreate } from "../../components/cl-study-create";
import { useEffect } from "react";
import { CLSpiner } from "../../components/shared/cl-spinner";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import { useHistory } from "react-router";

export const getTimeZone = (name, tz) => {
  const active = tz.find(item => item.Id === name);
  return active.BaseUtcOffset.split(":")[0];
};

export const StudyCreatePage = observer(() => {
  const { getPatientBase, patientBase, timeZones } = useRootStore().patientInfoStore;
  const patientAdditional = useRootStore().patientCreateAdditionalModel;
  const { clinicSettingsTemplate } = useRootStore().userStore;
  const state = useRootStore().studyStore;
  const patientEdit = useRootStore().createUserStore;

  const { patientId } = useParams();
  const history = useHistory();
  const additional = {};
  state.reset();

  useEffect(() => {
    patientAdditional.init({ patientId });
    getPatientBase(patientId);
  }, [patientId]);

  useEffect(() => {
    if (patientBase && patientBase.DST) {
      additional.DST = patientBase.DST;
      additional.TimeZone = getTimeZone(patientBase.TimeZoneStandardName, timeZones);
    }
    if (patientBase && patientBase.Id) {
      state.availableDevice.getAvailableDevice();
      patientEdit.init({ patientId: patientId, patientData: patientBase });
    }
  }, [patientBase, clinicSettingsTemplate]);

  if (clinicSettingsTemplate) {
    state.init(clinicSettingsTemplate);
  }

  const backClicked = () => {
    if (history.location.state?.activeCategory) {
      history.push("/patients-list", { activeCategory: history.location.state?.activeCategory });
    } else {
      history.goBack();
    }
  };

  return (
    <StudyCreateStyle>
      <span className="back" onClick={backClicked}> <img src={ArrowLeftIcon}></img></span>
      <div className="study-form" id={"study-form"}>
        {patientBase.Id && <ClPatientStudyInfo></ClPatientStudyInfo>
        || <div className="spiner"><CLSpiner id="ecg" boxShadow={"none"}></CLSpiner></div>}
        {patientBase.Id && <ClStudyCreate
          patientId={patientBase.Id}
          patientName={patientBase.Name + " " + patientBase.LastName}
          additional={additional}></ClStudyCreate>}
      </div>
    </StudyCreateStyle>
  );
});
