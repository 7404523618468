import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClMobileNotificationWrapper = styled.div<{isOpen?:boolean}>`
  //padding: 15px;
  border-top: 1px solid #E5E5E5;
  .title {
    background: #FFF;
    width: 100%;
    color: ${theme.colors.text};
    padding: 16px;
    padding-bottom: 0;
    h2{
      font-weight: 500;
      font-size: 20px;
    }
  }

  .not-found {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    .title {
      font-weight: 300;
      font-size: 14px;
      color: ${theme.colors.text};
    }
  }
  .bottom-sheet {
    display: flex;
    justify-content: space-between;
    .btn{
      p{
        margin-bottom: 0;
        font-weight: 500;
        line-height: 30px;
        font-size: 14px;
      }
    }
  }
  .nav-wrapper{
    display: flex;
    width: auto;
    .nav-item {
      display: flex;
      font-size: 14px;
      line-height: 16px;
      margin-right: 24px;
      flex: 1;
      justify-content: center;
      padding-bottom: 10px;
      padding-top: 16px;
      cursor: pointer;
      position: relative;
      &.active {
        box-shadow: inset 0px -3px 0px 0px #457ef5;
      }
    }
  }

  .list {
    overflow: auto;
    padding-bottom: 0;
    
    .date-title {
      font-size: 14px;
      line-height: 16px;
      color: #8A8A8A;
      font-weight: 400;
      margin: 16px 0;
      padding: 0 16px;
    }
    .container-notification{
      background: #FFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05);
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      opacity: 0.7;
      padding-top: 16px;
      padding-bottom: 8px;
      margin: 0;
    }

    .notification-item {
      padding: 16px;
      display: flex;
      cursor: pointer;
      border-bottom: 1px solid ${theme.colors.background};

      .icon {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #E7E7E7;

        &.new {
          background: #FFEAEA;
        }
      }

      .info {
        padding-left: 16px;
        display: flex;
        flex-direction: column;

        .caption {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: ${theme.colors.text};
          padding-bottom: 4px;
        }
  
        .date {
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: ${theme.colors.duration};
          padding-bottom: 4px;
        }

        .date:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
`;
