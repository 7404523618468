import { observer } from "mobx-react-lite";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { UserProfilePage } from "./Profile/Profile.page";
import { UserStyles } from "./User.presents";

export const UserPage = observer(() => {
  const { url } = useRouteMatch();
  return (
    <UserStyles>
      <div className="content">
        <Switch>
          <Route exact path={url} render={() => <Redirect to={`${url}/profile`}/>}/>
          <Route path={`${url}/profile`} render={() => <UserProfilePage/>}/>
          {/* <Route path={`${url}/settings`} render={() => <UserProfilePage/>}/> */}
          {/* <Route path={`${url}/notification-settings`} render={() => <UserProfilePage/>}/> */}
        </Switch>
      </div>
    </UserStyles>
  );
});
