import { observer } from "mobx-react-lite";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { BillingStyles } from "./Billing.presents";
import { BillingListPage } from "./List";
import { BillingUtilizationPage } from "./Utilization";
import { ClBillingHeader } from "../../components/cl-billing-header";
import { useRootStore } from "../../stores/storeContext";
import { ClAlert2fa } from "../../components/cl-alert-2fa";
import { ClAction2fa } from "../../components/cl-action-2fa";
import { useEffect } from "react";
import { ClAlert2faHero } from "../../components/cl-alert-2fa-hero";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

export const BillingPage = observer(() => {
  const { url } = useRouteMatch();
  const { getItems, getUtilizationData } = useRootStore().billingStore;
  getItems();
  getUtilizationData();
  const { PortalUserMC, IdCurrentMC } = useRootStore().userStore;

  let isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");

  useEffect(() => {
    isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  }, [PortalUserMC, IdCurrentMC]);

  return (
    <BillingStyles>
      <ClBillingHeader></ClBillingHeader>
      <div className="content">
        <Switch>
          <Route exact path={url} render={() => <Redirect to={`${url}/list`} />} />
          <Route path={`${url}/list`} render={() => <BillingListPage />} />
          <Route path={`${url}/utilization`} render={() => <BillingUtilizationPage />} />
        </Switch>
      </div>
      {isMandatoryMFA ? <ClAlert2fa/> : <ClAlert2faHero/>}
      <ClAction2fa/>
    </BillingStyles>
  );
});
