import styled from "styled-components";
import { theme } from "../../theme/theme";
import { CLBillingUtilizationChartUsageWraper } from
  "../cl-billing-utilization-chart-usage/cl-billing-utilization-chart-usage.presents";

export const CLBillingUtilizationChartUtilizationWraper = styled(CLBillingUtilizationChartUsageWraper)`
  
  .legend {
    display: flex;
    justify-content: flex-end;

    .item {
      padding-left: 16px;

      .color {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        display: inline-block;
      }

      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: ${theme.colors.placeholder};
        display: inline-block;
        padding-left: 4px;
        vertical-align: text-top;
      }
    
      .color-holter {
        background: ${theme.colors.green};
      }
    
      .color-holter-plus {
        background: ${theme.colors.red};
      }
    
      .color-event {
        background: ${theme.colors.yellow};
      }
    
      .color-mct {
        background: ${theme.colors.blue};
      }
    }
  }
  
`;
