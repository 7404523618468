import styled from "styled-components";
import { theme } from "../../theme/theme";

export const PatientInfoHeaderInfoStyle = styled.div`
  position: relative;
  width: 500px;
  display: flex;

  .back {
    //position: absolute;
    display: block;
    width: 30px;
    margin-right: 15px;
    cursor: pointer;
    &.hide {
      display: none !important;
    }
  }

  .info {
    display: block;
    span {
      color: ${theme.colors.placeholder}
    }
    .title{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: ${theme.colors.text}
    };
  }
  
`;
