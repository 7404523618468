import { IInviteAdminModelSnapShot } from "./invite-admin.store";

export const InitialState: IInviteAdminModelSnapShot = {
  id: "0",
  name: {
    name: "First Name",
    value: "",
    type: "input",
    maxLength: 100,
    fieldId: "name"
  },
  lastName: {
    name: "Last Name",
    value: "",
    type: "input",
    maxLength: 100,
    fieldId: "lastName"
  },
  email: {
    name: "Email",
    value: "",
    type: "email",
    fieldId: "email"
  },
  isPhysician: {
    name: "This user is a physician",
    value: "false",
    isChecked: false,
    type: "checkbox",
    fieldId: "isPhysician"
  },
  phone: {
    name: "Primary Phone Number",
    value: "",
    type: "number",
    fieldId: "phone",
    additionalText: "Used for 2 factor authentication"
  },
  phone2: {
    name: "After Hours Number",
    value: "",
    type: "number",
    fieldId: "phone2"
  },
  description: {
    name: "Text Notification Number",
    value: "",
    type: "number",
    fieldId: "description"
  },
  statReport: {
    name: "STAT Report ",
    value: "false",
    type: "checkbox",
    isChecked: false,
    fieldId: "statReport"
  },
  sumReport: {
    name: "Summary Report ",
    value: "false",
    type: "checkbox",
    isChecked: false,
    fieldId: "sumReport"
  },
  npi: {
    name: "NPI Number",
    value: "",
    type: "input",
    fieldId: "npi",
    isOptional: true
  }
};
