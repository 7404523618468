import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClPatientInfoChartsStyle = styled.div`
  // padding-left: ${theme.offsets.mainContent};
  // padding-right: ${theme.offsets.mainContent};
  
  padding-top: 24px;
  min-height: 500px;
  flex: 0 0 auto;
  max-width: calc(100% - 304px);
  width: calc(100% - 304px);
  flex-wrap: wrap;
  display: flex;
  
  .alert{
    background: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 16px;
    color: #000;
  }
  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;
