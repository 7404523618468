import { CLPatientListTableWraper } from "../cl-patients-list-table/cl-patients-list-table.presents";
import styled from "styled-components";

export const CLBillingListTableWrapper = styled(CLPatientListTableWraper)`
  .download_link {
    display: flex;

    &:hover {
      text-decoration: none;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #484848;
      margin-left: 10px;
      display: inline-block;
    }
  }

  .header {
    display: flex;
    margin-left: auto;
    justify-content: space-between;
  }

  .mode-type {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0 10px;
      font-weight: 500;
    }
    .mode-title {
      font-weight: 500;
    }
    .mode-value {
      margin-left: 3px;
    }
  }
  
  .checkbox-holder{
    border-right: 1px solid #E5E5E5;
    padding-right: 10px;
    label {
      margin-bottom: 0;
    }
  }

  .headline {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #484848;
  }
`;
