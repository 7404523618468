import styled from "styled-components";

export const CL2faWrapper = styled.div`
  .modal-container{
    padding: 40px;
  }
  .footer{
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    .btn-wrapper{
      &:first-child{
        margin-right: 24px;
      }
    }
  }
  .title{
    font-size: 24px;
    line-height: 28px;
    color: #484848;
    margin-bottom: 32px;
  }

  .description{
    font-size: 16px;
    line-height: 22px;
  }
`;
