import styled from "styled-components";
import { PropsType } from "./cl-card.props";
import { theme } from "../../../theme/theme";

export const CardStyle = styled.div<PropsType>`
  position: relative;
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  background: ${theme.colors.light};
  
  ${props => props.padding ? `padding: ${props.padding}` : ""};
  ${props => props.paddingLeft ? `padding-left: ${props.paddingLeft}` : ""};
  ${props => props.paddingRight ? `padding-right: ${props.paddingRight}` : ""};
  ${props => props.paddingTop ? `padding-top: ${props.paddingTop}` : ""};
  ${props => props.paddingBottom ? `padding-bottom: ${props.paddingBottom}` : ""};

  height: ${props => props.height ? props.height : "auto"};
  width: ${props => props.width ? props.width : "100%"};
`;
