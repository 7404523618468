import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "spin.js/spin.css";
import "react-circular-progressbar/dist/styles.css";
import "pdfjs-dist/build/pdf.worker.min.js";
import * as serviceWorker from "./serviceWorker";
import App from "./boot/App";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <>
    <App />
  </>
);
serviceWorker.unregister();
