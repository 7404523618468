import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { observer } from "mobx-react-lite";
import { CLModal } from "../shared/cl-modal";

import { PropsType } from "./cl-study-create-modal.props";
import { StudyCreateModalContainerStyle, StudyCreateModalStyle } from "./cl-study-create-modal.presents";
import { CLCheckbox } from "../shared/cl-checkbox";
import { useState } from "react";
import { ClRadio } from "../shared/cl-radio";
enum StydySerivceTypes {
  "MCT" = 1,
  "EventRecorder" = 2,
  "WirelessHolter" = 3
}
const radioReason = [
  // {
  //   name: "Report Required",
  //   fieldName: "ReportYes",
  //   id: 0
  // },
  {
    name: "Patient non-compliance",
    fieldName: "PatientNonCompliance",
    id: 1
  },
  {
    name: "Wrong serial number assigned to recording",
    fieldName: "WrongSNRecording",
    id: 2
  },
  {
    name: "Monitor not working properly",
    fieldName: "MonitorNotWorking",
    id: 3
  },
  {
    name: "Other",
    fieldName: "Other",
    id: 4
  }
];
export const ClStudyCreateModal = observer((props: PropsType) => {
  const [isChecked, setChecked] = useState(props.studyData.isInvitingPatient + "");
  const [stateRadio, setStateRadio] = useState(1);
  const [validOther, setValidOther] = useState(true);
  const [otherValue, setOtherValue] = useState("");

  const saveChanges = () => {
    let isValid = true;
    if (stateRadio === 4) {
      if (!otherValue.trim().length) {
        isValid = false;
      } else if (otherValue.length > 100) {
        isValid = false;
      }
    }
    setValidOther(isValid);
    if (isValid) {
      props.saveChanges(isChecked, stateRadio, otherValue);
    }
  };
  const getOperationMode = () => {
    if (+props.studyData.HPDays > 0) {
      return "Holter+MCT";
    } else {
      return props.studyData.operatingMode && StydySerivceTypes[(+props.studyData.operatingMode)] || "-";
    }
  };

  const handleChange = (event: any) => {
    const value = event.target.checked + "";
    setChecked(value);
  };
  const handleOtherValue = (event: any) => {
    const value = event.target.value + "";
    setOtherValue(value);
  };
  return (
    <StudyCreateModalStyle>
      <CLModal
        show={props.isShow}
        handleHideModal={() => props.closeModal()}>
        <StudyCreateModalContainerStyle>
          <div className="modal-container">
            <div className="title">
              {props.title}
            </div>
            {props.studyData.description
              ? <p className="description" dangerouslySetInnerHTML={{ __html: props.studyData.description }}></p>
              : null}
            <p className="description">The following cannot be undone once the study started. Please confirm.</p>
            {props.studyData.isInvitingPatient && props.isShowStudy && <div className="checkbox-container">
              <CLCheckbox
                handleChange={(event: any) => handleChange(event)}
                isChecked={isChecked === "true"}
                label={"Invite Patient to myRhythmAccess app"}
                name={"invite_to_app"}></CLCheckbox>
            </div>}
            <div className="table">
              <div className="row">
                <div className="field">
                  <div className="label">Patient</div>
                  <div className="value">{props.studyData.name}</div>
                </div>
                <div className="field">
                  <div className="label">Device</div>
                  <div className="value">{props.studyData.serialNumber || "-"}</div>
                </div>
                <div className="field">
                  <div className="label">Mode</div>
                  <div className="value">{getOperationMode()}</div>
                </div>
                <div className="field">
                  <div className="label">Duration</div>
                  <div className="value">{props.studyData.duration + " days"}</div>
                </div>
              </div>
            </div>
            {props.studyData.isWR && <div className="radio-group">
              <h6>Please choose one of the reasons why a study would be ended “Without report”:</h6>
              {radioReason.map((field: any) => {
                return (<div className="radio-field" key={field.id}>
                  <ClRadio
                    handleChange={() => { setStateRadio(field.id); }}
                    value={field.fieldName}
                    isChecked={field.id === stateRadio}
                    name={field.fieldName}
                    label={field.name}
                  ></ClRadio>
                  {(stateRadio === 4 && field.id === 4)
                    ? <div className="input-field">
                      <textarea
                        className={!validOther ? "error-textarea" : ""}
                        placeholder="Other"
                        value={otherValue}
                        maxLength={100}
                        onChange={handleOtherValue}></textarea>
                      <div className={"info"}>{otherValue.length}/100</div>
                      {!validOther && !otherValue.trim().length && <div className="error">
                        This field cannot be empty</div>}
                      {!validOther && otherValue.length > 100
                        && <div className="error">Exceeded 100 character limit</div>}
                    </div>
                    : null}
                </div>);
              })}
            </div>}
            <div className="footer">
              <div className="btn-wrapper">
                <CLLightButton
                  click={props.closeModal}
                  text="Cancel"
                  width="200px"></CLLightButton>
              </div>

              <div className="btn-wrapper">
                <CLBlueButton
                  click={saveChanges}
                  text={props.title}
                  width="200px"></CLBlueButton>
              </div>
            </div>
          </div>
        </StudyCreateModalContainerStyle>

      </CLModal>

    </StudyCreateModalStyle>
  );
});
