import styled from "styled-components";
import { theme } from "../../theme/theme";

export const PatientInfoHeaderStyle = styled.div`
  background: ${theme.colors.light};
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  height: 82px;
  padding-left: ${theme.offsets.mainContent};
  padding-right: ${theme.offsets.mainContent};
  border-top: 1px solid  ${theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1400px;
  
  &.print{
    max-width: 1200px !important;
    min-width: auto !important;
    margin: 0 auto !important;
  }
  
  .ant-picker-input{
    flex-direction: row-reverse;
    .ant-picker-suffix{
      margin-right: 10px;
    }
  }
  .btn-container {
    width: 500px;
  }
  .btn-wrapper{
    margin-right:12px;
    margin-left: 12px;
    position: relative;
    &.disabled{
      pointer-events: none;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  .logo {
    height: 49px;
    width: auto;
    margin-right: 70px;
  }

  .left-element {
    margin-left: auto;
  }

  .notification {
    margin-left: 24px;
  }

  .profile-img {
    box-shadow: 8px 8px 16px rgba(38, 86, 186, 0.04);
    border-radius: 8px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    margin-left: 24px;
    margin-right: 8px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .cl-select__value-container {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: 38px;
  }

  .nav-wraper {
    display: flex;
    height: 100%;
    flex: 1;
    margin-left: 50px;
    justify-content: start;
  }

  .actions-wraper {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .report-date {
    position: absolute;
    top: 50%;
    z-index: -1;
  }
  .date {
    cursor: pointer;
  }
`;
