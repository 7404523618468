import { BrowserRouter as Router } from "react-router-dom";
import { RootStoreProvider } from "../stores/storeContext";
import { Routers } from "./Routers";
import { GlobalStyles } from "../theme/globalStyles";
import React from "react";

const App = () => {
  return (
    <RootStoreProvider>
      <meta content="width=device-width, initial-scale=1"></meta>
      <meta name="theme-color" content="#FFFFFF" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"></meta>
      <GlobalStyles>
        <Router>
          <Routers />
        </Router>
      </GlobalStyles>
    </RootStoreProvider>
  );
};

export default App;
