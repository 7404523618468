/* eslint-disable max-len */
import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClPatientInsuranceInfoWrapper = styled.div`
  padding: 40px 20px 140px;
  min-height: 920px;
  h1 {
    flex: 0 0 100%;
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    padding: 0 10px;
  }
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  // .title {
  //   font-weight: normal;
  //   font-size: 24px;
  //   line-height: 28px;
  //   color: ${theme.colors.text};
  // }
  .btn-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    padding: 0 10px;

    div:last-child {
      margin-left: 24px;
    }
  }
  .w100 {
    width: 100% !important;
  }
  h3 {
    flex: 0 0 100%;
    margin: 20px 0;
    padding: 16px 10px 0;
    font-size: 16px;
    line-height: 18px;
  }
  .study-create-block {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-bottom: 20px;
    & > div {
      width: 50%;
      flex-wrap: wrap;
      padding: 10px;
    }
  }
  .form-container.highlight {
    .form-field.invalid {
      input {
        border: 1px solid ${theme.colors.orange};
      }
      .ant-picker {
        border: 1px solid ${theme.colors.orange};
      }
      .cl-select__control {
        border: 1px solid ${theme.colors.orange} !important;
      }
    }
  }
  .form-block {
    width: 50%;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;

    label {
      font-size: 12px;
      line-height: 14px;
      width: 100%;
      position: relative;
    }

    .optional-flag {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 12px;
      line-height: 14px;
      color: #8A8A8A;
    }

    .checkbox-name {
      font-size: 14px;
      line-height: 16px;
    }

    &.invalid {
      input {
        border: 1px solid ${theme.colors.red};
      }

      .ant-picker {
        border: 1px solid ${theme.colors.red};

        input {
          border: 0;
        }
      }

      .cl-select__control {
        border: 1px solid ${theme.colors.red} !important;
      }
    }
  }

  .help-container {
    position: relative;

    .clear {
      position: absolute;
      z-index: 1;
      right: 24px;
      top: 49px;
      cursor: pointer;
    }

    .invite-field {
      width: 100%;
      padding: 0;
    }

    .help {
      position: absolute;
      background: ${theme.colors.light};
      z-index: 2;
      left: 10px;
      right: 10px;
      top: 88px;
      border: 1px solid ${theme.colors.greyDivider};
      border-radius: 7px;
      height: 320px;
      overflow-y: scroll;

      .help-el {
        padding: 8px 11px 8px 15px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: ${theme.colors.text};
        cursor: pointer;
        position: relative;

        .active {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.7071 7.29289C19.0976 7.68342 19.0976 8.31658 18.7071 8.70711L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071L5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929C5.68342 10.9024 6.31658 10.9024 6.70711 11.2929L10 14.5858L17.2929 7.29289C17.6834 6.90237 18.3166 6.90237 18.7071 7.29289Z' fill='%230D60DB'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
          position: absolute;
          right: 8px;
          top: 4px;
        }
      }

      .disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: default;
      }

      .help-el:hover {
        background: #F4F4F4;
      }

      .no-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .text {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: ${theme.colors.text};
          padding: 16px 48px;
          text-align: center;
          white-space: pre-line;
        }
      }
    }
  }
`;
