import { observer } from "mobx-react-lite";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { VQIcon, VQLightButton } from "vivaquant-components-library";
import { CLModalWrapper, CLUploadInsuranceCardImagesWrapper } from "./cl-upload-insurance-card-images.presents";
import { FileUploader } from "react-drag-drop-files";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ErrorIcon from "../../assets/icons/error.svg";
import { theme } from "../../theme/theme";
import { PropsType } from "./cl-upload-insurance-card-images.props";
import { CLModal } from "../shared/cl-modal";
import { CLLightButton } from "../shared/cl-button";
import { confirmAlert } from "react-confirm-alert";
import { message } from "antd";

const CLUploadInsuranceCardImagesC
  = (props: PropsType, _ref: React.ForwardedRef<{ submitFile: Function, validateFile?: Function }>) => {
    const fileTypes = ["png", "jpg", "pdf"];

    const {
      isLoading,
      isUploaded,
      errorMessages,
      addImages,
      progressUpload,
      deleteImageData,
      getImageData
    } = useRootStore().uploadInsuranceCardImagesStore;

    const [file, setFile] = useState<FileList[] | any>([]);
    const [previews, setPreviews] = useState<any[] | any>([]);
    const [touched, setTouched] = useState(false);
    const [isError, setIsError] = useState(false);
    const [modal, setModal] = useState(
      { isShow: false, preview: "", isPDF: false });

    const hide = () => {
      setTouched(false);
    };

    const getFileNameFromPath = (path: any) => {
      const url = new URL(path);
      const parts = url.pathname.split("/");
      const name = parts[parts.length - 1];
      return decodeURI(name);
    };

    const getFiles = async(skipFileUpdating?: boolean) => {
      const result = await getImageData(props.idPatient);
      if (result) {
        const previews = result.map((el: any) => ({
          preview: el,
          isUploaded: true,
          type: el.indexOf(".pdf") >= 0 ? "pdf" : "image/jpeg",
          name: getFileNameFromPath(el)
        }));
        if (!skipFileUpdating) {
          setFile([]);
        }
        setPreviews(previews);
        return result;
      }
    };

    useEffect(() => {
      getFiles();
    }, []);

    const onSubmit = (id: number = props.idPatient, isReload?: boolean) => {
      const patientId = id || props.idPatient;
      const key = "updatable";
      if (!touched) return;
      return addImages(file, patientId)
        .then((result: boolean) => {
          if (result) {
            hide();
            if (isReload) {
              getFiles();
            }
            return result;
          } else {
            message.error({ content: "Error, Something went wrong!", key, duration: 2 });
          }
        });
    };
    useImperativeHandle(_ref, () => ({
      submitFile: (id?: number, isReload?: boolean) => {
        if (file && file.length) {
          const pId = id || props.idPatient;
          return onSubmit(pId, isReload);
        } else {
          return { ok: false };
        }
      },
      validateFile: () => {
        // const errorMsg = isUploaded && errorMessages;
        // const isFile = file && file.length;
        // const isPreviews = previews && previews.length;
        setIsError(false);
        return true;
      }
    }));

    const removeFile = async(index: number, isPreview?: boolean) => {
      if (isPreview) {
        confirmAlert({
          message: "Are you sure you want to delete this file?",
          overlayClassName: "cl-confirm-modal",
          buttons: [
            {
              label: "Cancel",
              onClick: () => {}
            },
            {
              label: "Delete",
              className: "delete-btn",
              onClick: async() => {
                const item = previews[index];
                await deleteImageData(props.idPatient, item.preview);
                getFiles(true);
              }
            }
          ]
        });
      } else {
        const arr = [...file];
        arr.splice(index, 1);
        setFile(arr);
        setTouched(true);
      }
    };

    const handleUpload = (files: FileList) => {
      const maxAvailableFiles = 4 - (file?.length + previews?.length);
      const newFiles: any = [];
      const length = Math.min(files.length, maxAvailableFiles);
      for (let i = 0; i < length; i++) {
        newFiles.push(Object.assign(files[i], {
          preview: URL.createObjectURL(files[i])
        }));
      }
      setFile([...file, ...newFiles]);
      setTouched(true);
      setIsError(false);
    };
    // const handleAddUpload = (files: File) => {
    //   const newFiles: any = Object.assign(files, {
    //     preview: URL.createObjectURL(files)
    //   });
    //   setFile([...file, newFiles]);
    //   setTouched(true);
    //   setIsError(false);
    // };
    const openPreview = (item: any) => {
      setModal({
        isShow: true,
        preview: item.preview,
        isPDF: item.type.indexOf("pdf") + 1 > 0
      });
    };
    const renderFilePreview = (item: any, index: number, isPreview?: boolean) => {
      const isPDF = item.type.indexOf("pdf") + 1 > 0;
      return (<div className="file-wrapper">
        <div className="remove" onClick={() => removeFile(index, isPreview)}>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d={`M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5
            3.58579L8.29289 0.292893C8.68342 -0.097631 9.31658 -0.097631 9.70711 0.292893C10.0976
            0.683418 10.0976 1.31658 9.70711 1.70711L6.41421 5L9.70711 8.29289C10.0976 8.68342
            10.0976 9.31658 9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L5
             6.41421L1.70711 9.70711C1.31658 10.0976 0.683417 10.0976 0.292893 9.70711C-0.0976311
              9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711C-0.0976311
              1.31658 -0.0976311 0.683417 0.292893 0.292893Z`} fill="#484848"/>
          </svg>
        </div>
        <div className="img-bg" onClick={() => openPreview(item)}>
          {isPDF
            ? <div>
              <div onClick={() => openPreview(item)} className="pdf-overlay"></div>
              <embed src={item.preview} width="130px"/>
            </div>
            : <img src={item.preview} alt="preview" width="130px"/>}
        </div>
        <p className="name">{item.name}</p>
      </div>);
    };
    const renderNewFilePreview = () => {
      return (<div className="file-wrapper">
        <FileUploader
          multiple={true}
          handleChange={handleUpload}
          name="file"
          types={fileTypes}
        >
          <div className="add-file">
            <div className="icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d={`M6 1C6 0.447715 6.44772 0 7 0C7.55228 0 8 0.447715 8 1V13C8 13.5523 7.55228
                14 7 14C6.44772 14 6 13.5523 6 13V1Z`} fill="#000"/>
                <path d={`M13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8L1 8C0.447715
                8 -2.41411e-08 7.55228 0 7C2.41411e-08 6.44771 0.447715 6 1 6L13 6Z`} fill="#000"/>
              </svg>
            </div>
            <p>Add more images</p>
          </div>
        </FileUploader>
      </div>);
    };
    const errorClass = isError ? "error" : "";
    const isUploadingShow = !!file.length && isLoading && !isUploaded;

    return (
      <CLUploadInsuranceCardImagesWrapper>
        <div className="form-container">
          <h3>Upload Insurance Card Images (optional)</h3>
          <form onSubmit={() => onSubmit()} className="extra-padding">
            <div className="upload-container">
              {(!file.length && !previews.length)
                ? <FileUploader
                  multiple={true}
                  handleChange={handleUpload}
                  name="file"
                  types={fileTypes}
                >
                  <div className={"upload-content " + errorClass}>
                    <div className="content">
                      <VQLightButton type="button" text="Select Images" click={() => {
                      }}/>
                      <div className="text">
                        Or Drag and Drop here<br/>
                        <span className="additional-text">
                          <span className="bold">PNG, JPG, PDF</span> up to 4 images
                        </span>
                      </div>
                    </div>
                  </div>
                </FileUploader>
                : null}
            </div>
          </form>
          {((!!file.length && !isLoading && !isUploaded) || previews.length) && !isUploadingShow
            ? <div className="upload-content selected">
              {previews.map((item: string, index: number) => {
                return renderFilePreview(item, index, true);
              })}
              {Array.from(file).map((el: any, index: number) => {
                return renderFilePreview(el, index);
              })}
              {file.length + previews.length < 4 ? renderNewFilePreview() : null}
            </div>
            : null}
          {isUploadingShow
            ? <div className="upload-content selected">
              <div className="content">
                <div className="progressbar">
                  <CircularProgressbar
                    value={progressUpload}
                    text={`${progressUpload}%`}
                    styles={buildStyles({
                      pathColor: `${theme.colors.blue}`,
                      trailColor: `${theme.colors.uploadBorder}`,
                      textColor: `${theme.colors.text}`,
                      textSize: "24px"
                    })}/>
                </div>
                <div className="text m-b-40">Adding</div>
              </div>
            </div>
            : null}
          {((isUploaded && errorMessages))
            ? <div className="upload-content selected-error">
              <div className="content">
                <VQIcon icon={ErrorIcon} width="56px" height="56px"></VQIcon>
                <div className="text m-b-40 error">Error</div>
                <VQLightButton type="button" text="Remove" click={removeFile}/>
              </div>
            </div>
            : null}
        </div>
        {(isUploaded && errorMessages) && <div className="alert-container">
          {errorMessages.map((el: string, index: number) => (
            <div key={index} className="alert-text">{el}</div>
          ))}
        </div>}

        {modal.isShow
          && <CLModal
            title="Preview"
            show={modal.isShow}
            dialogClassName="modal-dialog-medium"
            handleHideModal={() => {
              setModal({ isShow: false, preview: "", isPDF: false });
            }}
          >
            <CLModalWrapper>
              <div className="modal-container">
                <div className="title">
                  Preview
                </div>
                <div className="img-bg">
                  {modal.isPDF
                    ? <embed src={modal.preview} width="668px" height="600px"/>
                    : <img src={modal.preview} alt="preview" width="668px"/>}
                </div>
                <div className="footer">
                  <div className="btn-wrapper">
                    <CLLightButton
                      click={() => setModal({ isShow: false, preview: "", isPDF: false })}
                      text="Close"
                      width="200px"></CLLightButton>
                  </div>
                </div>
              </div>
            </CLModalWrapper>
          </CLModal>}
      </CLUploadInsuranceCardImagesWrapper>
    );
  };

export const CLUploadInsuranceCardImages
  = observer(forwardRef<{ submitFile: Function, validateFile?: Function }, PropsType>(CLUploadInsuranceCardImagesC));
