import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../stores/storeContext";
import { useEffect } from "react";
import { CLSpiner } from "../../../components/shared/cl-spinner";
import { ClAdministrationIcd10 } from "../../../components/cl-administration-icd10-modal";
import { CLCard } from "../../../components/shared/cl-card";
import { ICD10Style } from "./icd10.presents";

export const ICD10Page = observer(() => {
  const { initForm, icd10 } = useRootStore().adminICD10Store;

  useEffect(() => {
    initForm();
  }, []);

  if (!icd10 || !icd10.length) {
    return (
      <ICD10Style>
        <CLCard padding="24px">
          <div className="spiner"><CLSpiner></CLSpiner></div>
        </CLCard>
      </ICD10Style>
    );
  }

  return (
    <ICD10Style>
      <CLCard padding="24px">
        <ClAdministrationIcd10></ClAdministrationIcd10>
      </CLCard>
    </ICD10Style>
  );
});
