import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLAdministrationListCategoriesWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  border-bottom: 1px solid ${theme.colors.background};
  align-items: flex-end;

  .pagination {
    padding-bottom: 7px;
    margin-left: auto;
  }
  .phone-number-block {
    height: 44px;
    margin: 2px 30px 2px 0;
    padding: 0 16px;
    display: flex;
    border-left: 1px solid #E5E5E5;
  }
  p {
    margin-bottom: 0;
    line-height: 48px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .phone-number {
    color: ${theme.colors.blue};
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  .input-number{
    margin: 0 10px;
    input {
      border: none;
      border-radius: 0;
      padding: 0 5px;
      margin-top: 8px;
      height: 30px;
      border-bottom: 2px solid ${theme.colors.blue};
      width: 140px;
    }
  }
`;
