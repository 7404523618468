import { ClPatientInfoEventsItemStyle } from "./cl-patient-info-events-item.presents";
import { PropsType } from "./cl-patient-info-events-item.props";
import moment from "moment";
import { ClPatientEventsIcons } from "../cl-patient-events-icons";
import { ClPatientEventsType } from "../cl-patient-events-type";
import { useState } from "react";
import Delete from "../../assets/icons/delete-event.svg";
import Edit from "../../assets/icons/edit-event.svg";
import People from "../../assets/icons/list-patients-all.svg";
import Dots from "../../assets/icons/dots.svg";
import { CLIcon } from "../shared/cl-icon";
import { CLCheckbox } from "../shared/cl-checkbox";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { EventActionCP } from "../../boot/Constants";
import { secondsToTimeString } from "../../services/handlers/date.handler";

export const ClPatientInfoEventsItem = observer((props: PropsType) => {
  const {
    setEventIncludeToReport,
    changeStatusEvents
  } = useRootStore().patientInfoStore;

  const [isCollapsedTooltip, setCollapsedTooltip] = useState(false);
  const isEditECGAvailable = !(props.item.EventSource && props.item.EventSource === 40);
  const handleClick = (event: any) => {
    if (event.target.classList.contains("checkbox-handle")) {
      return;
    }
    props.setEvent();
    if (props.isNew && props.trackNewEvent) {
      props.trackNewEvent();
    }
  };

  const handleClickDots = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setCollapsedTooltip((prevState: boolean) => !prevState);
  };

  const editEcg = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.editItem();
    setCollapsedTooltip(false);
  };

  const deleteEcg = () => {
    setEventIncludeToReport([props.item.Id], false);
    changeStatusEvents([props.item.Id], EventActionCP.STATRejected);
  };

  const reviewedEcg = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    changeStatusEvents([props.item.Id], EventActionCP.STATNone);
    setCollapsedTooltip(false);
  };

  const handleCheckbox = (event: any) => {
    const value = event.target.checked + "";
    setEventIncludeToReport([props.item.Id], value === "true");
  };

  if (isCollapsedTooltip) {
    window.addEventListener("click", (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setTimeout(() => {
        setCollapsedTooltip(false);
      }, 200);
    });
  }

  return (
    <ClPatientInfoEventsItemStyle className={`${props.isActive ? "active" : ""} ${props.isNew ? "new" : ""}`}>
      {props.type === "reported" && <div className={"form-field"}>
        <CLCheckbox handleChange={handleCheckbox} isChecked={props.item.IncludedToReport}
          name={props.item.fieldId}></CLCheckbox>
      </div>}
      {props.type === "events" && <ClPatientEventsIcons item={props.item}></ClPatientEventsIcons>}
      <div onClick={handleClick} className={"eventDate "}>
        <div>{moment(props.item.StartDate).format("hh:mm:ss A")}</div>
        {props.type === "events" && <div className="duration">{secondsToTimeString(props.item.Length)}</div>}
        {props.type === "reported" && <div className="caption">{props.item.EcgStripCaption || "-"}</div>}
      </div>
      <div onClick={handleClick} className="eventType">
        <ClPatientEventsType
          EventType={props.item.EventType}
          EventSource={props.item.EventSource}
          isActive={props.isActive}></ClPatientEventsType>
      </div>
      <div className="three-dots" onClick={handleClickDots}>
        <CLIcon icon={Dots} padding="0px"></CLIcon>
      </div>
      {isCollapsedTooltip && <div className="tooltip-container">
        <div className="tooltip-item tooltip-item-name edit">
          <CLIcon icon={People} padding="7px"></CLIcon>
          <div>{props.item.Operator}</div>
        </div>
        {isEditECGAvailable && <div className="tooltip-item edit" onClick={editEcg}>
          <CLIcon icon={Edit} padding="7px"></CLIcon>
          <div>Edit ECG</div>
        </div>}
        {props.item.EventActionCP !== 3 && <div className="tooltip-item delete" onClick={deleteEcg}>
          <CLIcon icon={Delete} padding="7px"></CLIcon>
          <div>Delete ECG</div>
        </div>}
        {props.item.EventActionCP === 3 && <div className="tooltip-item delete" onClick={reviewedEcg}>
          <CLIcon icon={Delete} padding="7px"></CLIcon>
          <div>Rollback ECG</div>
        </div>}
      </div>}
    </ClPatientInfoEventsItemStyle>
  );
});
