import { CLAppHeader } from "../../components/cl-app-header";
import { DeviceMode, HeaderTabs, timeUpdateNotification } from "../../boot/Constants";
import { useEffect } from "react";
import { useRootStore } from "../../stores/storeContext";
import { ClAppHeaderMobile } from "../../components/cl-app-header-mobile";

export const ClinicPortalPage = () => {
  const { getNotifications, detectMode } = useRootStore().userStore;
  const isMobile = detectMode() === DeviceMode.mobile;

  useEffect(() => {
    const idUpdateNotification = setInterval(() => {
      getNotifications();
    }, timeUpdateNotification);

    return () => {
      if (idUpdateNotification) {
        clearInterval(idUpdateNotification);
      }
    };
  });
  if (isMobile) {
    return (
      <ClAppHeaderMobile></ClAppHeaderMobile>
    );
  }
  return (
    <CLAppHeader navTabs={HeaderTabs}></CLAppHeader>
  );
};
