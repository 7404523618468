import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLPatientInfoNotificationsStyle = styled.div`
  position: relative;
  padding: 20px 40px;
  background: #FFF;
  width: 100%;
  margin-top: 30px;

  .no-box-shadow {
    box-shadow: none !important;
  }
  .no-print {
    display: none !important;
  }

  .checkbox-handle {
    align-items: flex-start !important;
  }

  .checkbox-handle::before {
    margin-top: 4px;
  }
  
  .header-container{
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: space-between;
  }
  .caption {
    font-size: 16px;
  }
  h1 {
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    word-spacing: 1px;
    letter-spacing: 0.1px;
    padding: 0 10px;
    max-width: 1400px;
    margin: 20px 0 40px;
  }
  .overlay{
    border-radius: 20px;
    max-width: 1400px;
    margin: 20px  auto;
    border: 1px solid #DFDFDF;
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    border-radius: 20px;
    border-style: hidden;
    font-size: 16px;
  }
  
  td {
    border: 1px solid #DFDFDF;
    padding: 10px 8px;
  }
  th {
    border: 1px solid #DFDFDF;
    padding: 20px;
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: 10%;
    }
  }

  .buttons {
    justify-content: end;
    display: flex;
    margin-top: 20px;
    gap: 16px;
  }
  
  .button-holder {
    display: flex;
  }
  .button-holder.disabled {
    button {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  
  thead {
    tr {
      background: #F9F9F9;
    }

    th {
      &:first-child {
        border-top-left-radius: 20px;
      }
      &:last-child {
        border-top-right-radius: 20px;
      }
    }
  }
  .input {
    margin: 0 10px;
  }
  .table-cell{
    display: flex;
    justify-content: end;
    min-width: 300px;
  }
  .table-cell2{
    display: flex;
    justify-content: space-between;
    width: 100%;
    &.pull-right{
      justify-content: end;
    }
  }
  .t {
    display: table;
    width: 530px;
    border-collapse: collapse;
    table-layout: fixed;
    .t-row {
      display: table-row;
      .t-cell {
        display: table-cell;
        width: 33%;
        &:last-child {
          .table-cell2 {
            justify-content: start;
          }
        }
      }
    }
  }
  
  .text {
    white-space: nowrap;
    line-height: 36px;
  }
  .select {
    width: 210px;
    margin-left: 8px;
    margin-top: 18px;
  }
  .radio-overlay{
    width: 20px;
    height: 20px;
  }
  .questions{
    max-width: 888px;
    margin: 0 auto;
    h3 {
      font-size: 18px;
      line-height: 22px;
      color: #484848;
    }
    .description {
      font-size: 14px;
      line-height: 16px;
      color: #757575;
    }
    .block {
      padding: 16px 32px;
      border-bottom: 1px solid #E5E5E5;
    }
  }
  
  .container {
    padding: 20px 0;
    display: flex;
    //border-bottom: 1px solid ${theme.colors.background};
    max-width: 1024px;
    margin: 0 auto;
    // align-items: center;
    flex-wrap: wrap;
  }
  
  .w100 {
    width: 100%;
    &.border-bottom {
      border-bottom: 1px solid #E5E5E5 !important;
      margin: 20px 0 0;
    }
  }
  .cl-select__control .cl-select__value-container {
    padding: 8px 0 8px 8px;
  }
  .signature {
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .value-item {
      font-style: italic;
    }
  }
  .date {
    font-size: 16px;
    font-weight: 500;
  }
`;
