import { PropsType } from "./cl-nav-item.props";
import { NavItemStyle } from "./cl-nav-item.presents";
import { Link } from "react-router-dom";

export const CLNavItem = (props: PropsType) => {
  return (
    <NavItemStyle {...props}>
      <Link to={props.link}>
        {props.title}
      </Link>
      {props.isActive && <div className="bottom-border"></div>}
    </NavItemStyle>
  );
};
