import { PropsType, ActionsPropsType } from "./cl-dropdown.props";
import { DropDownStyle } from "./cl-dropdown.presents";
import { Dropdown } from "react-bootstrap";
import { ClIconDropdown } from "../cl-icon";
import { useState } from "react";

export const CLDropdown = (props: PropsType) => {
  const [actionArr, setActionArr] = useState<ActionsPropsType[]>(props.actions);

  const actionClicked = (eventKey: any) => {
    return actionArr[eventKey].action();
  };

  const onToggleClicked = (isOpen: boolean, event: any) => {
    try {
      if (!props.onToggle) { return; }
      return props.onToggle(isOpen, event)
        .then((result: any) => {
          if (!result) { return; }
          setActionArr(result);
        })
        .catch(() => {});
    } catch (error) {}
  };

  return (
    <DropDownStyle {...props}>
      <Dropdown
        onToggle={onToggleClicked}
        onSelect={(eventKey: any) => actionClicked(eventKey)}
        className={props.rotateIcon ? "rotate-icon" : ""}>
        <Dropdown.Toggle>
          {props.text && <div className="text">{props.text}</div>}
          {props.iconMain && <img className="icon-main" src={props.iconMain} alt=""></img>}
          {props.icon && <img className="icon" src={props.icon} alt=""></img>}
        </Dropdown.Toggle>
        <Dropdown.Menu align={props.menuAlign ? props.menuAlign : "end"}>
          {actionArr.map((item, index) => (
            <Dropdown.Item key={index.toString()} eventKey={index.toString()} disabled={item.disabled}>
              {item.icon && <ClIconDropdown icon={item.icon}></ClIconDropdown>}
              <span
                className={item.icon ? "text-icon" : ""}
                style={item.color ? { color: item.color } : {}}>
                {item.text}
              </span>
            </Dropdown.Item>
          ))}
          {actionArr.length === 0 && <Dropdown.Item
            eventKey={0}
            disabled={true}>
            <span>
                Loading...
            </span>
          </Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    </DropDownStyle>
  );
};
