import styled from "styled-components";

export const PatientInfoEventsStyle = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 600px;
  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`;
