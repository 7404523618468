import styled from "styled-components";
import { theme } from "../../theme/theme";

export const PatientEventsType = styled.div`
  // width: 280px;
  // position: relative;
  // border-radius: 16px;
  // box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  // background: ${theme.colors.light};
  // padding: 24px;
  //margin: 24px 0 24px 24px;
  padding: 6px 8px;
  border-radius: 4px;
  background: #DDF5E2;
  color: #41B571;
  &.active{
    background: #52CA84;
    color: #FFF;
  }
  &.type2{
    background: #FFEEE7;
    color: #EA9252;
    &.active{
      background: #EA9252;
      color: #FFF;
    }
  }
`;
