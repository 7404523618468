import styled from "styled-components";
import { theme } from "../../../theme/theme";
import { PropsType } from "./cl-icon.props";

export const IconStyle = styled.div<PropsType>`
  position: relative;
  ${props => props.padding ? `padding: ${props.padding};` : ""}
  ${props => props.borderColor
    ? `border: 1px solid ${props.borderColor}; border-radius: 8px;`
    : ""}
  
  img {
    height: ${props => props.height || "24px"};
    width: ${props => props.width || "24px"};
    display: block;
  }

  ${props => props.active
    ? `
        background: rgba(69, 126, 245, 0.1);
        border-color: transparent;
        box-shadow: 8px 8px 16px rgba(38, 86, 186, 0.04);
      `
    : ""}

  ${props => props.count
    ? `
    ::before {
      content: "${props.count}";
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      margin: 0;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      height: ${props.countHeight ? props.countHeight : "16px"};
      width: ${props.countHeight ? props.countHeight : "16px"};
      position: absolute;
      right: 0;
      top: 0;
      background: ${theme.colors.light};
      color: ${theme.colors.text};
      ${
  (props.borderColorCount || props.borderColor)
    ? `border: 1px solid ${props.borderColorCount || props.borderColor};`
    : ""}
      
      transform: translate(30%, -30%);

      ${props.alert
    ? `
        background: ${theme.colors.red};
        color: ${theme.colors.light};
      `
    : ""}
    }
   
  
  
  `
    : ""}
`;

export const IconStyleSort = styled.div`
  position: relative;

  img {
    height: 12px;
    width: 12px;
    display: block;
  }
`;

export const IconStyleNoIcon = styled.div`
  position: relative;
  
  img {
    height: 52px;
    width: 52px;
    display: block;
  }
`;

export const IconStyleDropdown = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  img {
    height: 24px;
    width: 24px;
    display: block;
  }
`;
