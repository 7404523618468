import { observer } from "mobx-react-lite";
import { CLAdministrationListCategoriesWrapper } from "./cl-administration-list-categories.presents";
import { AdministrationListCategories, administrationListCountRows } from "../../boot/Constants";
import { CLCategoryItem } from "../shared/cl-category-item";
import { CLPagination } from "../shared/cl-pagination";
import { useRootStore } from "../../stores/storeContext";
import { useEffect } from "react";

export const CLAdministrationListCategories = observer(() => {
  const {
    activeCategory,
    page,
    total,
    setActiveCategory,
    nextPage,
    previousPage,
    allCategoryTotal,
    physiciansCategoryTotal,
    usersCategoryTotal,
    getClinicPhoneNumber
  } = useRootStore().listAdministrationStore;
  const { PortalUserAC } = useRootStore().userStore;

  useEffect(() => {
    getClinicPhoneNumber();
  }, [PortalUserAC]);
  const categoriesTotals: any = {
    allCategoryTotal,
    physiciansCategoryTotal,
    usersCategoryTotal
  };

  const changeListCategory = (value: string) => {
    setActiveCategory(value, categoriesTotals[`${value}CategoryTotal`]);
  };
  return (
    <CLAdministrationListCategoriesWrapper>
      {AdministrationListCategories.map(item => (
        <CLCategoryItem
          key={item.title}
          onChange={changeListCategory}
          title={item.title}
          icon={activeCategory === item.value ? item.iconActive : item.icon}
          count={categoriesTotals[`${item.value}CategoryTotal`]}
          value={item.value}
          isActive={activeCategory === item.value}
        ></CLCategoryItem>
      ))}
      <div className="pagination">
        {(total > administrationListCountRows) && <CLPagination
          total={total}
          page={page}
          countOnPage={administrationListCountRows}
          next={nextPage}
          previous={previousPage}></CLPagination>}
      </div>
    </CLAdministrationListCategoriesWrapper>
  );
});
