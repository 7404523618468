import styled from "styled-components";
import { PropsType } from "./cl-modal.props";

export const CLModalStyle = styled.div<PropsType>`
  .modal-header {
    padding: 40px 40px 0 40px;
    border-bottom: 0;
  }

  .modal-dialog{
    width: 90% !important;
    max-width: 1400px;
  }
  .modal-body {
    padding: 40px;
  }
  .icon-close{
  
  }
`;

export const CLModalCloseBtnStyle = styled.div`
  position: absolute;
  cursor: pointer;
  top: 40px;
  right: 40px;
  z-index: 10;
  left: auto;
  & > div{

  }
`;
