import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { NotificationsStyle } from "./Notifications.present";
import { ClPatientInfoNotifications } from "../../components/cl-patient-info-notifications";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { CLSpiner } from "../../components/shared/cl-spinner";

export const Notifications = observer(() => {
  const { notificationCriteria, PortalUserAC } = useRootStore().userStore;
  const { getClinicPhysicians, activePhysicians } = useRootStore().notificationStore;
  const { search } = useLocation();
  const allUsers = new URLSearchParams(search).get("allUsers");
  const [clinicId, setClinickId] = useState(-1);

  useEffect(() => {
    if (allUsers || (!notificationCriteria || notificationCriteria && !Object.keys(notificationCriteria).length)) {
      if (!activePhysicians || activePhysicians && !Object.keys(activePhysicians).length) {
        getClinicPhysicians();
        setClinickId(PortalUserAC);
      } else if (clinicId !== PortalUserAC) {
        getClinicPhysicians();
        setClinickId(PortalUserAC);
      }
    }
  }, [notificationCriteria, PortalUserAC]);

  let resultNotificationCriteria = {};

  if (!allUsers && notificationCriteria && Object.keys(notificationCriteria).length) {
    resultNotificationCriteria = notificationCriteria;
  } else if (activePhysicians && Object.keys(activePhysicians).length) {
    resultNotificationCriteria = activePhysicians.NotificationCriteria;
  } else {
    return (
      <NotificationsStyle>
        <div className="spiner"><CLSpiner></CLSpiner></div>
      </NotificationsStyle>
    );
  }

  return (
    <NotificationsStyle>
      {notificationCriteria
      && <ClPatientInfoNotifications
        notificationCriteria={resultNotificationCriteria}
        isAllUsers={allUsers}></ClPatientInfoNotifications>}
    </NotificationsStyle>
  );
});
