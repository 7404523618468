import { useLocation, useRouteMatch } from "react-router-dom";
import { CLNavItem } from "../shared/cl-nav-item";
import { PatientInfoHeaderStyle } from "./cl-patient-info-header.presents";
import { ClPatientInfoHeaderInfo } from "../cl-patient-info-header-info";
import { PatientInfoTabs, RA1ID, RA2ID } from "../../boot/Constants";
import { CLGreenButton, CLLightButton } from "../shared/cl-button";
import moment from "moment";
import { DatePicker } from "antd";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { isNullDate } from "../../services/handlers/date.handler";
import { isIDTFMC } from "../../stores/patientInfo/patientInfo.helper.service";

export const getActiveReportedItem = (data: any) => {
  let activeEvent = data[0];
  for (let i = 0; i < data.length; i++) {
    if (+data[i].EventSource === 77) {
      activeEvent = data[i];
      return activeEvent;
    }
  }
  return activeEvent;
};

export const ClPatientInfoHeader = observer(() => {
  const location = useLocation();
  const { url } = useRouteMatch();
  const {
    patient,
    activeDate
  } = useRootStore().patientInfoStore;
  const [showDatePickerForReport, setShowDatePickerForReport] = useState(false);

  const { generateReportSummary, generateReportDaily } = useRootStore().reportStore;
  const { IdCurrentMC } = useRootStore().userStore;

  const format = "MM/DD/yyyy";
  const isPreAnalysis = isIDTFMC(IdCurrentMC) || IdCurrentMC === RA1ID || IdCurrentMC === RA2ID;
  const summaryBtnName = isPreAnalysis ? "Pre-analysis report" : "Preview summary report";

  const disabledDate = (current: any) => {
    if (patient
      && patient.Device
      && patient.Device.DeviceStatistics
      && patient.Device.DeviceStatistics.DeviceRecordingStartDate
    ) {
      if (current && current < moment(patient.Device.DeviceStatistics.DeviceRecordingStartDate).startOf("day")) {
        return true;
      }
      if (current && current > moment(isNullDate(patient.Device.DeviceStatistics.DeviceRecordingEndDate)
        ? patient.Device.DeviceStatistics.LastTransmission
        : patient.Device.DeviceStatistics.DeviceRecordingEndDate)
      ) {
        return true;
      }
    }
    return false;
  };

  const defaultValue = (value: any) => {
    if (patient
      && patient.Device
      && patient.Device.DeviceStatistics
      && patient.Device.DeviceStatistics.DeviceRecordingStartDate
    ) {
      let dataM = moment(isNullDate(patient.Device.DeviceStatistics.DeviceRecordingEndDate)
        ? patient.Device.DeviceStatistics.LastTransmission
        : patient.Device.DeviceStatistics.DeviceRecordingEndDate);
      if (value) {
        dataM = moment(value, format);
      }
      return dataM
        .startOf("month");
    }
    return moment();
  };

  let value = null;
  if (activeDate && activeDate.format) {
    value = activeDate.format(format);
  } else
  if (patient.Device && patient.Device.DeviceStatistics && patient.Device.DeviceStatistics.DeviceRecordingStartDate) {
    value = moment(patient.Device.DeviceStatistics.DeviceRecordingStartDate)
      .add(patient.Device.DeviceSettings.Duration, "hours").format(format);
  }

  const generateSummaryReportClick = () => {
    generateReportSummary(patient.RecordingID);
  };

  const generateDailyReport = (ev: any) => {
    setShowDatePickerForReport(false);
    generateReportDaily(patient.RecordingID, ev.format("MM/DD/yyyy"));
  };

  return (
    <PatientInfoHeaderStyle id="info-header">
      <ClPatientInfoHeaderInfo></ClPatientInfoHeaderInfo>
      <div className="nav-wraper">
        {PatientInfoTabs.map((item) => {
          if (patient?.Device?.DeviceSettings?.ArchiveStatus === 2) {
            return <></>;
          }
          return (
            <CLNavItem
              key={item.id}
              link={`${url}${item.link}`}
              title={item.title}
              isActive={location.pathname === url + item.link}
              fontSize={"16px"}></CLNavItem>);
        })}
      </div>

      <div className="btn-container">
        {location.pathname.indexOf("/reports") >= 0 && <div className="actions-wraper">
          <div className="btn-wrapper">
            <CLLightButton
              click={generateSummaryReportClick}
              text={summaryBtnName}
              width="240px"></CLLightButton>
          </div>
          <div className="btn-wrapper">
            <CLGreenButton
              click={() => { setShowDatePickerForReport(!showDatePickerForReport); }}
              text="Preview daily report"
              width="240px"></CLGreenButton>
            {showDatePickerForReport && <DatePicker
              className="report-date"
              onChange={generateDailyReport}
              open={showDatePickerForReport}
              bordered={false}
              format={format}
              defaultPickerValue={defaultValue(value)}
              disabledDate={disabledDate}/>}
          </div>
        </div>}
      </div>
    </PatientInfoHeaderStyle>
  );
});
