import API from "../../boot/Api";
import * as Types from "./api.types";
import { encodeData } from "../handlers/data.handler";

export const getAllReportsApi = async(recordingId: string | number): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    recordingID: recordingId
  });
  const result = API.get(`ClinicReports/RecordingReports?${encodedData}`);
  return await Types.getResponse(result);
};

export const reportFindingsApi
  = async(recordingId: string | number, deviceSerial: string | number): Promise<Types.ApiResponse> => {
    const encodedData = encodeData({
      recordingId: recordingId,
      deviceSerial: deviceSerial
    });
    const result = API.get(`ClinicReports/ReportFindingsARCS?${encodedData}`);
    return await Types.getResponse(result);
  };

export const deleteReportApi = async(reportID: string | number): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    reportID: reportID
  });
  const result = API.post(`ClinicReports/DeleteReport?${encodedData}`);
  return await Types.getResponse(result);
};

export const downloadReportApi = async(reportID: string | number): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    reportID: reportID
  });
  const result = API.get(`ClinicReports/PDF?${encodedData}`, { responseType: "blob" });
  return await Types.getResponse(result);
};

export const signReportApi = async(params: string): Promise<Types.ApiResponse> => {
  const result = API.post(`ClinicReports/Report/SignAndSave?${params}`);
  return await Types.getResponse(result);
};

export const generateReportSummaryApi = async(recordingID: string | number): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    recordingID: recordingID
  });
  const result = API.get(`ClinicReports/Report/GenerateSummary?${encodedData}`, { responseType: "blob" });
  return await Types.getResponse(result);
};

export const generateReportDailyApi = async(recordingID: string | number, date: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    recordingID: recordingID,
    date: date
  });
  const result = API.get(`ClinicReports/Report/GenerateDaily?${encodedData}`,
    { responseType: "blob" });
  return await Types.getResponse(result);
};

export const getTotalClinicRepotsCountApi
  = async(jsonFilter: {}, filterMode: string, filterSigned: string): Promise<Types.ApiResponse> => {
    const result = API.get("/ClinicReports/All/NumberRecords", {
      params: {
        filterSigned: filterSigned,
        jsonFilter: jsonFilter,
        filterMode: filterMode
      }
    });
    return await Types.getResponse(result);
  };

export const getListClinicRepotsApi
  = async(jsonFilter: {}, filterMode: string, filterSigned: string): Promise<Types.ApiResponse> => {
    const result = API.get("/ClinicReports/All", {
      params: {
        filterSigned: filterSigned,
        jsonFilter: jsonFilter,
        filterMode: filterMode
      }
    });
    return await Types.getResponse(result);
  };

export const setPrintedApi = async(idReport: string | number, isPrinted: boolean): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    idReport,
    isPrinted
  });
  const result = API.post(`ClinicReports/Report/SetPrinted?${encodedData}`);
  return await Types.getResponse(result);
};
