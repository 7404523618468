import { theme } from "../../theme/theme";
import styled from "styled-components";

export const CLDynamicFormWrapper = styled.div`
  width: 33%;
  flex-wrap: wrap;
  padding: 10px;
  position: relative;
  &.w100 {
    width: 100%;
  }

  label {
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    position: relative;
  }

  .optional-flag {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 12px;
    line-height: 14px;
    color: #8A8A8A;
  }

  .checkbox-name {
    font-size: 14px;
    line-height: 16px;
  }

  .form-field.invalid {
    input {
      border: 1px solid ${theme.colors.red};
    }

    .ant-picker {
      border: 1px solid ${theme.colors.red};

      input {
        border: 0;
      }
    }

    .cl-select__control {
      border: 1px solid ${theme.colors.red} !important;
    }

    .invalid-text {
      display: block;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.red};
      margin-top: 4px;
    }
  }

.from-group-state {
  width: 33%;
  flex-wrap: wrap;
  padding: 0;
  display: flex;

  .form-block {
    width: 100%;
  }

  .form-control-state {
    width: 50%;
    flex-wrap: wrap;
  }

  .form-control-zip {
    width: 50%;
    flex-wrap: wrap;
  }
}

.ant-picker {
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #E5E5E5;
  color: #484848;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  width: 100%;
}

;

textarea {
  border: 1px solid ${theme.colors.background};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px;
  resize: none;
  height: 156px !important;
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.text};
}

textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.text};
  opacity: 0.5;
}

.input-mask {
  font-size: 14px;
  line-height: 16px;
  border: 1px solid ${theme.colors.background};
  color: ${theme.colors.text};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 16px;
  height: 48px;
  width: 100%;

  &::placeholder {
    color: ${theme.colors.placeholder}
  }
}

`;
