import { observer } from "mobx-react-lite";
import { UserProfileStyles } from "./Profile.presents";
import { useRootStore } from "../../../stores/storeContext";
import { ClUserProfile } from "../../../components/cl-user-profile";

export const UserProfilePage = observer(() => {
  const { initProfile } = useRootStore().profileStore;
  initProfile();

  return (
    <UserProfileStyles>
      <div className="content">
        <ClUserProfile/>
      </div>
    </UserProfileStyles>
  );
});
