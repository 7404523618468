import { CLCard } from "../../components/shared/cl-card";
import { DevicesListStyle } from "./DevicesList.presents";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLDevicesListHeader } from "../../components/cl-devices-list-header";
import { CLAdministrationListCategories } from "../../components/cl-devices-list-categories";
import { CLDevicesListTable } from "../../components/cl-devices-list-table";
import { ClDevicesModalMove } from "../../components/cl-devices-modal-move";
import { ClDevicesModalServiceRequest } from "../../components/cl-devices-modal-service-request";
import React, { useEffect } from "react";
import { ClAlert2fa } from "../../components/cl-alert-2fa";
import { ClAction2fa } from "../../components/cl-action-2fa";
import { ClAlert2faHero } from "../../components/cl-alert-2fa-hero";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

export const DevicesListPage = observer(() => {
  const {
    getTotalCount
  } = useRootStore().listDevicesStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { PortalUserMC, IdCurrentMC } = useRootStore().userStore;

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => { setGlobalSpiner(false); });
  });
  let isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");

  useEffect(() => {
    isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  }, [PortalUserMC, IdCurrentMC]);

  return (
    <DevicesListStyle>
      <CLCard padding="24px">
        <CLDevicesListHeader></CLDevicesListHeader>
        <CLAdministrationListCategories></CLAdministrationListCategories>
        <CLDevicesListTable></CLDevicesListTable>
      </CLCard>
      <ClDevicesModalMove></ClDevicesModalMove>
      <ClDevicesModalServiceRequest></ClDevicesModalServiceRequest>
      {isMandatoryMFA ? <ClAlert2fa/> : <ClAlert2faHero/>}
      <ClAction2fa/>
    </DevicesListStyle>
  );
});
