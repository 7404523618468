import styled from "styled-components";
import { theme } from "../../../theme/theme";
import Icon from "../../../assets/icons/select.svg";

export const SelectStyle = styled.div<{width?: string, padding?: string, type?: string}>`
  width: ${props => props.width ? props.width : "230px"};

  .cl-select__control {
    border-radius: 10px;
    border: 1px solid ${theme.colors.background} !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05) !important;
    cursor: pointer;

    &.no-shadow {
      box-shadow: none !important;
    }

    .cl-select__value-container {
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      padding: ${props => props.padding ? props.padding : "12px 16px"};
    }

    .cl-select__indicators {
      .cl-select__indicator-separator {
        display: none;
      }

      .cl-select__indicator {
        color: ${theme.colors.text};
      }
    }

    .cl-select__value-container--is-multi.cl-select__value-container--has-value {
      input {
        display: none;
      }

      .cl-select__multi-value {
        margin: 0;
        display: none;
        padding: 3px;
        margin-top: -3px;
        margin-bottom: -3px;
        background: ${theme.colors.blue};
        white-space: nowrap;

        .cl-select__multi-value__label {
          padding: 0;
          color: ${theme.colors.light};
          display: inline-block;
        }

        .cl-select__multi-value__remove {
          display: inline-block;
          color: ${theme.colors.light};
        }

        .cl-select__multi-value__remove:hover {
          background: ${theme.colors.blue};
        }
      }

      .cl-select__multi-value:first-child {
        display: flex;
      }
    }


    .cl-select__multi-value {

    }
  }

  .cl-select--is-disabled {
    .cl-select__control {
      .cl-select__indicators {
        display: none;
      }
    }
  }


  .cl-select__control--menu-is-open {
    border-radius: 10px 10px 0 0;
    border-color: transparent !important;

    .cl-select__indicators {
      .cl-select__indicator {
        transform: rotate(180deg);
      }
    }
  }

  .cl-select__menu {
    border-radius: 10px;
    border: 1px solid transparent !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px 4px 4px rgba(0, 0, 0, 0.05) !important;
    margin: 0;
    border-radius: 0 0 10px 10px !important;
    z-index: 100;

    &.no-shadow {
      box-shadow: none !important;
    }


    .cl-select__menu-list {
      padding: 0 16px;

      .cl-select__option {
        padding: 16px 0;
        border-top: 1px solid ${theme.colors.background};
        font-size: 14px;
        line-height: 16px;
        color: ${theme.colors.text};
        cursor: pointer;
      }

      .cl-select__option--is-focused {
        background: transparent;
      }

      .cl-select__option--is-selected {
        background: transparent;
        position: relative;
      }

      .cl-select__option--is-disabled {
        color: #9a9a9a;
        position: relative;
      }

      .cl-select__option--is-selected::after {
        content: url(${Icon});
        position: absolute;
        right: 0;
      }
    }
  }
  .cl-select__menu {
    .search-input-wrapper {
      .cl-select__option:first-child{
        border-top: none !important;
      }
    }
  }
  .search-input-wrapper > div{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
  ${props => props.type === "smallSelect"
    ? `
      width: ${props.width ? props.width : "77px"};
      
      .cl-select__control {
        .cl-select__value-container {
          padding: ${props.padding ? props.padding : "8px"};
        }

        .cl-select__input {
          input {
            height: auto;
          }
        }
      }

      .cl-select__menu {
        .cl-select__menu-list {
          padding: 0 8px;

          .cl-select__option {
            padding: 10px 0;
          }
        }
      }
    `
    : ""}
`;
