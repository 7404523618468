import { types, applySnapshot, flow, Instance, SnapshotIn } from "mobx-state-tree";
import { UserFormModel } from "./createUser/user.model";
import {
  getClinicIndicationsAPI,
  getDefaultIndicationsAPI,
  studyEnrollmentChangeApi
} from "../services/api/administration.api";
import { StudyEnrollmentInitialState } from "./study/study-enrollment.state";
import moment from "moment";
import { GenderType } from "../boot/Constants";
import { ISelectOptionsModelSnapShot } from "./createUser/options.model";
import { toJS } from "mobx";

function getValue(value: any) {
  if (value + "" === "0") {
    return "None";
  } else {
    return value;
  }
}

export const StudyEnrollmentChangeModel = types
  .model("InviteAdminModel", {
    show: types.optional(types.boolean, false),
    idUser: types.optional(types.string, ""),
    id: types.identifier,
    recordingId: types.maybe(UserFormModel),
    clinicName: types.maybe(UserFormModel),
    clinicId: types.maybe(UserFormModel),
    contactName: types.maybe(UserFormModel),
    contactPhone: types.maybe(UserFormModel),
    contactEmail: types.maybe(UserFormModel),

    patientName: types.maybe(UserFormModel),
    patientLastName: types.maybe(UserFormModel),
    patientID: types.maybe(UserFormModel),
    patientDOB: types.maybe(UserFormModel),
    gender: types.maybe(UserFormModel),
    indication: types.maybe(UserFormModel),
    device: types.maybe(UserFormModel),
    mode: types.maybe(UserFormModel),
    IDPhysician: types.optional(types.number, 0)
  })
  .actions(self => ({
    setDefaultValueByKey(key: string, defaultValue: any) {
      if (self[key as keyof typeof self]) {
        self[key as keyof typeof self].value = defaultValue.value;
        self[key as keyof typeof self].defaultValue = defaultValue;
      }
    }
  }))
  // .actions(self => ({
  //   getData() {
  //     return getSnapshot(self);
  //   }
  // }))
  .actions(self => {
    const resetForm = () => {
      self.IDPhysician = 0;
      self.idUser = "";
      applySnapshot(self, StudyEnrollmentInitialState);
    };

    const closeModal = () => {
      resetForm();
    };

    const getDataForSave = (disabledFormData: any) => {
      const getValue = (name: keyof typeof self) => {
        if (disabledFormData[name].value !== self[name].value) {
          return self[name].value;
        }
        return null;
      };
      const getValueIndications = () => {
        const activeFullNamedArr = self.indication?.options
          .filter((item: any) => {
            return !!self.indication?.valueMulti.find((itemOrigin: any) => item.value === itemOrigin);
          })
          .map((item: any) => item.label);
        const selfValue = toJS(activeFullNamedArr)?.join(";") || "";
        const disabledValue = toJS(disabledFormData.indication?.valueMulti)?.join(";") || "";
        if (selfValue !== disabledValue) {
          return selfValue;
        }
        return null;
      };
      const data: any = {
        patientName: getValue("patientName"),
        patientLastName: getValue("patientLastName"),
        patientID: getValue("patientID"),
        patientDOB: getValue("patientDOB"),
        gender: getValue("gender"),
        indications: getValueIndications(),
        mode: getValue("mode")
      };
      const returnObj: any = {};
      for (const key in data) {
        if (data[key]) {
          returnObj[key] = data[key];
        }
      }
      return returnObj;
    };

    const saveForm = flow(function* saveForm(disabledFormData) {
      const data = getDataForSave(disabledFormData);
      const str = JSON.stringify(data);

      try {
        const response = yield studyEnrollmentChangeApi(self.recordingId?.value || "", str);
        if (response.ok && response.data) {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    });

    function apply(state: any) {
      for (const key in state) {
        if (self[key as keyof typeof self]) {
          if (self[key as keyof typeof self].type === "select") {
            if (key === "gender") {
              self.setDefaultValueByKey(key, {
                value: GenderType[state[key] as keyof typeof GenderType],
                label: GenderType[state[key] as keyof typeof GenderType]
              });
            } else {
              const activeItem = self[key as keyof typeof self];
              let activeItemValue = null;
              if (activeItem.options && activeItem.options.length) {
                activeItemValue = activeItem.options.find((item: any) => +item.value === +state[key]);
              }
              if (activeItemValue) {
                self.setDefaultValueByKey(key, {
                  value: "" + activeItemValue.value,
                  label: "" + activeItemValue.label
                });
              } else {
                self.setDefaultValueByKey(key, {
                  value: "" + state[key],
                  label: "" + state[key]
                });
              }
            }
          } else if (self[key as keyof typeof self].type === "checkbox") {
            self[key as keyof typeof self].value = state[key] + "";
            self[key as keyof typeof self].isChecked = state[key] === "true";
          } else {
            self[key as keyof typeof self].value = state[key] + "";
          }
        }
      }
    }

    const validateFormFields = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;
      const fields = [
        "patientName",
        "patientLastName",
        "patientID",
        "patientDOB",
        "gender",
        "device",
        "mode"
      ];
      fields.forEach((field: string) => {
        const item = self[field as keyof typeof self];
        if (!item?.value) {
          errors[field] = "Please fill the field";
          isValid = false;
        }
      });
      if (self.indication && (!self.indication.valueMulti || !self.indication.valueMulti!.length)) {
        errors.indication = "Please fill the field";
        isValid = false;
      }
      return {
        errors,
        isValid
      };
    };

    async function getIcd10() {
      const clinic: any = await getClinicIndicationsAPI();
      if (!clinic.data || clinic.data.length === 0) {
        const defaultD: any = await getDefaultIndicationsAPI();
        const options: ISelectOptionsModelSnapShot[] = defaultD.data.map((item: any) => ({
          label: item.Code + " " + item.Description,
          value: item.Code,
          id: item.Id + ""
        }));
        self.indication?.setOptions(options);
      } else {
        const options: ISelectOptionsModelSnapShot[] = clinic.data.map((item: any) => ({
          label: item.Code + " " + item.Description,
          value: item.Code,
          id: item.Id + ""
        }));
        self.indication?.setOptions(options);
      }
    }

    const findICDValuesByCode = (values: string[]): any => {
      const additionalValues: any = [];
      const returnedValues: any = [];
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        if (!value || value === " ") continue;
        const activeValueOption = self.indication?.options.find((item: any) => value.indexOf(item.value) + 1);
        if (activeValueOption) {
          returnedValues.push(activeValueOption.value);
        } else {
          additionalValues.push(value);
          returnedValues.push(value);
        }
      }
      return {
        returnedValues,
        additionalValues
      };
    };

    async function init(clinicInfo: any, patientInfo: any) {
      const patientData = patientInfo.patientData;
      const initState: any = {};
      initState.recordingId = patientInfo.recordingID;
      initState.clinicName = clinicInfo.Name;
      initState.clinicId = clinicInfo.Id;
      initState.contactName = patientInfo.user?.firstName + " " + patientInfo.user?.lastName;
      initState.contactPhone = clinicInfo.Phone;
      initState.contactEmail = clinicInfo.Email;

      initState.patientName = patientData.Name;
      initState.patientLastName = patientData.LastName;
      initState.patientID = patientData.Id + "";
      initState.patientDOB = moment.utc(patientData.DOB).format("MM/DD/YYYY");
      initState.gender = patientData.Gender;
      initState.device = patientData.Device.SerialNumber;
      initState.mode = (+patientInfo.deviceSettings.HPDays) ? "4" : getValue(patientInfo.deviceSettings.OperatingMode);
      apply(initState);

      await getIcd10();
      const values = patientData.IndForMonitor.split(";");
      const filteredObj = findICDValuesByCode(values);
      self.indication?.setOptions([
        ...self.indication.options,
        ...filteredObj.additionalValues.map((value: string) => ({
          value: value,
          label: value
        }))
      ]);
      self.indication?.setDefaultMultiValue(filteredObj.returnedValues);
      self.indication?.setValueMultiply(filteredObj.returnedValues);
    }

    return {
      closeModal,
      init,
      validateFormFields,
      saveForm
    };
  });

export interface IStudyEnrollmentChangeModel extends Instance<typeof StudyEnrollmentChangeModel> {}
export interface IStudyEnrollmentChangeModelSnapShot extends SnapshotIn<typeof StudyEnrollmentChangeModel> {}
