import { GenderType } from "../../boot/Constants";
import { IStudyEnrollmentChangeModelSnapShot } from "../study-enrollment-change.store";

export const StudyEnrollmentInitialState: IStudyEnrollmentChangeModelSnapShot = {
  id: "0",
  recordingId: {
    name: "Recording Id",
    value: "",
    type: "input",
    fieldId: "recordingId"
  },
  clinicName: {
    name: "Clinic Name",
    value: "",
    type: "input",
    fieldId: "clinicName"
  },
  clinicId: {
    name: "Clinic Id",
    value: "",
    type: "input",
    fieldId: "clinicId"
  },
  contactName: {
    name: "Contact Name",
    value: "",
    type: "input",
    fieldId: "contactName"
  },
  contactPhone: {
    name: "Contact Phone",
    value: "",
    type: "input",
    fieldId: "contactPhone"
  },
  contactEmail: {
    name: "Contact Email",
    value: "",
    type: "input",
    fieldId: "contactEmail"
  },
  // physician: {
  //   name: "Request From",
  //   value: "",
  //   type: "input",
  //   fieldId: "physician"
  // },

  patientName: {
    name: "Patient First Name",
    value: "",
    type: "input",
    fieldId: "patientName"
  },
  patientLastName: {
    name: "Patient Last Name",
    value: "",
    type: "input",
    fieldId: "patientLastName"
  },
  patientID: {
    name: "Patient ID",
    value: "",
    type: "input",
    fieldId: "patientID"
  },
  gender: {
    name: "Gender",
    value: "",
    type: "select",
    options: [{
      value: GenderType.M,
      label: GenderType.M
    }, {
      value: GenderType.F,
      label: GenderType.F
    }, {
      value: GenderType.O,
      label: GenderType.O
    }],
    fieldId: "gender"
  },
  indication: {
    name: "Indication",
    value: "",
    type: "multiply",
    options: [],
    fieldId: "indication"
  },
  patientDOB: {
    name: "Date of Birth",
    value: "",
    type: "dateTime",
    fieldId: "patientDOB"
  },
  device: {
    name: "Device",
    value: "",
    type: "input",
    fieldId: "device"
  },
  mode: {
    name: "Mode",
    value: "",
    type: "select",
    fieldId: "serviceType",
    options: [{
      value: "1",
      label: "MCT"
    }, {
      value: "2",
      label: "EventRecorder"
    }, {
      value: "3",
      label: "WirelessHolter"
    }, {
      value: "4",
      label: "Holter+ MCT"
    }]
  }
  // disclamer: {
  //   name: "I read and agree",
  //   value: "false",
  //   isChecked: false,
  //   required: true,
  //   type: "checkbox",
  //   fieldId: "disclamer"
  // }
};
