import values from "lodash.values";
import groupBy from "lodash.groupby";
import moment from "moment";
import { formatDateWithNameMonth } from "./date.handler";
import Signal0 from "../../assets/icons/signal0.svg";
import Signal1 from "../../assets/icons/signal1.svg";
import Signal2 from "../../assets/icons/signal2.svg";
import Signal3 from "../../assets/icons/signal3.svg";
import Signal4 from "../../assets/icons/signal4.svg";

import WIFISignal1 from "../../assets/icons/wifi_1.svg";
import WIFISignal2 from "../../assets/icons/wifi_2.svg";
import WIFISignal3 from "../../assets/icons/wifi_3.svg";
import WIFISignal4 from "../../assets/icons/wifi_4.svg";
import WIFISignal5 from "../../assets/icons/wifi_5.svg";

export const dataToParamsString = (data: any) => {
  return Object.keys(data).map(key => key + "=" + data[key]).join("&");
};

export const compare = (a: any, b: any, key: string) => {
  const A = moment(a[key]);
  const B = moment(b[key]);
  const diff = A.diff(B);
  return diff > 0 ? -1 : 1;
};

const isToday = (date: any) => {
  const TODAY = moment().clone().startOf("day");
  return moment(date).isSame(TODAY, "d");
};

const isYesterday = (date: any) => {
  const YESTERDAY = moment().clone().subtract(1, "days").startOf("day");
  return moment(date).isSame(YESTERDAY, "d");
};

const getTitle = (day: any) => {
  let title = "";

  if (isToday(day)) {
    title = "Today";
  } else if (isYesterday(day)) {
    title = "Yesterday";
  } else {
    title = formatDateWithNameMonth(day);
  }

  return title;
};

export const convertReportsByDate = (list: any, type?: number) => {
  const byDates = values(groupBy(list, (item: any) => moment(item.ReportDate).startOf("day"))).reverse();
  const result = byDates.map((dayList: any) => {
    dayList = dayList.reverse();

    let data = dayList;
    if (typeof type !== "undefined") {
      data = data.filter((item: any) => {
        return item.ReportType === type;
      });
    }

    return {
      title: getTitle(dayList[0].ReportDate),
      data: data
    };
  });

  return result;
};

export const convertNotificationsByDate = (list: any, isNew?: boolean) => {
  const byDates = values(groupBy(list, (item: any) => moment(item.StartDate).startOf("day")));
  const result = byDates.map((dayList: any) => {
    dayList = dayList.sort((a: any, b: any): any => { return compare(a, b, "StartDate"); });
    return {
      title: getTitle(dayList[0].StartDate),
      date: moment(dayList[0].StartDate).startOf("day"),
      isNew: isNew || false,
      data: dayList
    };
  });

  return result.sort((a: any, b: any): any => { return compare(a, b, "date"); });
};

export const getSignalIcon = (count: number) => {
  if (count < 70) {
    return Signal0;
  } else if (count >= 70 && count < 115) {
    return Signal1;
  } else if (count >= 115 && count < 160) {
    return Signal2;
  } else if (count >= 160 && count < 200) {
    return Signal3;
  } else if (count >= 200) {
    return Signal4;
  } else {
    return "";
  }
};

export const getWifiSignalIcon = (count: number) => {
  if (count >= -40) {
    return WIFISignal1;
  } else if (count < -40 && count >= -60) {
    return WIFISignal2;
  } else if (count < -60 && count >= -80) {
    return WIFISignal3;
  } else if (count < -80 && count >= -90) {
    return WIFISignal4;
  } else if (count < -90) {
    return WIFISignal5;
  } else {
    return "";
  }
};

export const encodeData = (data: any) => {
  return Object.keys(data).map(function(k) {
    return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
  }).join("&");
};

export const getValidPhoneNumber = (value: string | null) => {
  if (!value) return "";
  return "+" + value.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
};

export const openNewTab = (name: string, url: string) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const openNewTabBlob = (blob: Blob) => {
  const href = window.URL.createObjectURL(blob);
  window.open(href, "_blank");
};

export const isRX3Device = (value: string) => {
  return value?.toLowerCase()?.indexOf("rx3") === 0;
};
