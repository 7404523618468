import { BeatsType } from "../../components/cl-charts-canvas/cl-charts-canvas.service";

let ecgRef: any;
let ecgShortRef: any;
let hrRef: any;

let ecgUrl: string;
let ECGEventStart: number;
let ECGEventDeltaStart: number;

export const setECGChartRef = (ref: any) => {
  ecgRef = ref;
};

export const getECGChartRef = () => {
  return ecgRef;
};
export const setHRChartRef = (ref: any) => {
  hrRef = ref;
};
export const getECGShortChartRef = () => {
  return ecgShortRef;
};
export const setECGShortChartRef = (ref: any) => {
  ecgShortRef = ref;
};

export const getECGDataURL = () => {
  if (!ecgRef) return "";
  const dataUrl = ecgRef.exportChart({ format: "jpg", toDataURL: true });
  return dataUrl;
};

export const getECGShortDataURL = () => {
  if (!ecgShortRef) return "";
  const dataUrl = ecgShortRef.exportChart({ format: "jpg", toDataURL: true });
  return dataUrl;
};

export const getHRDataURL = () => {
  if (!hrRef) return "";
  const dataUrl = hrRef.exportChart({ format: "jpg", toDataURL: true });
  return dataUrl;
};

export const setECGChartDataUrl = (url: string) => {
  ecgUrl = url;
};

export const getECGChartDataUrl = () => {
  return ecgUrl;
};

export const setECGEventStart = (event: number) => {
  ECGEventStart = event;
};

export const setECGEventDeltaStart = (event: number) => {
  ECGEventDeltaStart = event;
};

export const getECGEventStart = (): number => {
  return ECGEventStart;
};

export const getECGEventDeltaStart = (): number => {
  return ECGEventDeltaStart;
};

export interface EcgStateModel {
    data: BeatsType[] | null,
    dataSecond: BeatsType[] | null,
    dataShort: BeatsType[] | null,
    dataShort2: BeatsType[] | null,
    dataEvent: BeatsType[] | null,
    dataNumbers: BeatsType[] | null,
    dataBeats: BeatsType[] | null,
    isScroll: boolean,
    activeDataState: string,
    activeDate: string,
    mmmV: number,
    mV: number
}

let chartPreviewData: EcgStateModel;

export const setChartsPreviewData = (chartData: EcgStateModel) => {
  chartPreviewData = chartData;
};

export const getChartsPreviewData = (): EcgStateModel => {
  return chartPreviewData;
};
