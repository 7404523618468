import { PropsType } from "./cl-spinner.props";
import { SpinerStyle } from "./cl-spinner.presents";
import { useEffect } from "react";
import { Spinner } from "spin.js";
import _uniqueId from "lodash/uniqueId";

export const CLSpiner = (props: PropsType) => {
  const options = {
    lines: 9,
    length: 0,
    width: 12,
    radius: 15,
    scale: props.scale || 1.00,
    corners: 1,
    color: "#4E5A61",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 2,
    position: "absolute",
    animation: "spinner-line-shrink"
  };

  const targetID = `clSpinner_${props.id ? props.id : _uniqueId()}`;

  useEffect(() => {
    const target = document.getElementById(targetID);
    if (target) {
      new Spinner(options).spin(target);
    }
  });

  return (
    <SpinerStyle {...props}>
      <div id={targetID}></div>
    </SpinerStyle>
  );
};
