import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { ClPatientInfoHeader } from "../../components/cl-patient-info-header";
import { PatientInfoEvents } from "./Events";
import { PatientInfoReports } from "./Reports";
import { PatientStudy } from "./Study";
import { useRootStore } from "../../stores/storeContext";
import { PatientInfoStyles } from "./PatientsInfo.presents";
import { useMemo, useState } from "react";
import { useHistory } from "react-router";

export const PatientInfoPage = () => {
  const { getPatient, getLastEcg } = useRootStore().patientInfoStore;
  const { url } = useRouteMatch();
  const { patientId } = useParams();
  const history = useHistory();
  const [isScrubbed, setIsScrubbed] = useState(false);

  useMemo(async() => {
    let forcibly = false;
    if (history.location.state && history.location.state.returnToReports) {
      forcibly = true;
    }
    Promise.all([getPatient(patientId, forcibly), getLastEcg(patientId)])
      .then((response) => {
        if (!response[0]) {
          history.replace("/patient-list");
        } else {
          if (response[0].Device?.DeviceSettings?.ArchiveStatus === 2) {
            setIsScrubbed(true);
            // if route events, redirect to reports
            if (history.location.pathname.includes("events")) {
              history.replace(`${url}/reports`);
            }
            if (history.location.pathname.includes("study")) {
              history.replace(`${url}/reports`);
            }
          }
        }
      });
  }, [patientId]);

  return (
    <PatientInfoStyles>
      <div>
        <ClPatientInfoHeader></ClPatientInfoHeader>

        <div className="content">
          <Switch>
            <Route exact path={url} render={() => <Redirect to={!isScrubbed ? `${url}/events` : `${url}/reports`} />} />
            {!isScrubbed ? <Route path={`${url}/events`} render={() => <PatientInfoEvents />} /> : null}
            <Route path={`${url}/reports`} render={() => <PatientInfoReports />} />
            {!isScrubbed ? <Route path={`${url}/study`} render={() => <PatientStudy />} /> : null}
          </Switch>
        </div>
      </div>
    </PatientInfoStyles>
  );
};
