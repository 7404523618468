import { CLPatientsListHeaderWrapper } from "./cl-patients-list-header.presents";
import { CLInput } from "../shared/cl-input";
import { CLGreenButton } from "../shared/cl-button";
import SearchIcon from "../../assets/icons/search.svg";
import { useHistory } from "react-router-dom";
import { useRootStore } from "../../stores/storeContext";
import debounce from "lodash.debounce";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { ClSelectSearch } from "../shared/cl-select-search";
import { OptionType } from "../shared/cl-select/cl-select.props";
import { DeviceStatusesArr } from "../../boot/Constants";

export const CLPatientsListHeader = observer(() => {
  const history = useHistory();

  const {
    setSearch,
    phusiciansFilterOptions,
    selectePractice,
    activeStatuses,
    setActiveStatuses,
    searchValue,
    getTotalCount,
    selectedPhusicians,
    updateStatusSearch,
    defaultPage,
    setFirstPage
  } = useRootStore().listPatientsStore;

  const { reset } = useRootStore().createUserStore;
  const statuses: OptionType[] = DeviceStatusesArr.map(({ value, id }: any) => {
    return {
      value: value,
      label: value,
      id: id
    } as any;
  });

  const activeStatusesValue = statuses.find((status: any) => status.value === activeStatuses);

  const statusesSelected = (data: any) => {
    if (data) {
      setActiveStatuses(data.value);
      setFirstPage();
      updateStatusSearch(data.id);
    } else {
      setActiveStatuses("");
      setFirstPage();
      updateStatusSearch(-1);
    }
  };
  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
    refreshData();
  };
  const defaultValues = selectedPhusicians.map((selectedValue: any) => {
    const active = phusiciansFilterOptions.find((option: any) => option.value === selectedValue);
    return active;
  });

  const refreshData = useMemo(
    () => debounce(() => {
      setFirstPage();
      getTotalCount();
    }, 1000), []);

  const openCreatePatient = () => {
    reset();
    history.push("/create-patient");
  };

  const practiceSelected = (event: any) => {
    selectePractice(event);
    defaultPage();
  };

  return (
    <CLPatientsListHeaderWrapper>
      <div className="margin-block-right">
        <CLInput
          handleChange={handleSearchChange}
          placeholder="Search (First Name, Last Name, Patient Id etc.)"
          icon={SearchIcon}
          value={searchValue}
          width="375px"></CLInput>
      </div>

      {phusiciansFilterOptions.length > 0 && <ClSelectSearch
        options={phusiciansFilterOptions}
        isMulti={true}
        showSelectedOptions={true}
        padding={"16px"}
        defaultValue={defaultValues}
        value={defaultValues}
        isSearchable={true}
        placeholder="All Physicians"
        selectOption={practiceSelected}></ClSelectSearch>}

      <div className="active-statuses">
        <ClSelectSearch
          options={statuses}
          isMulti={false}
          showSelectedOptions={true}
          padding={"16px"}
          value={activeStatusesValue}
          isSearchable={true}
          placeholder="Status"
          selectOption={statusesSelected}/>
      </div>

      <div className="create">
        <CLGreenButton
          click={openCreatePatient}
          text="Create Patient"
          width="256px"></CLGreenButton>
      </div>
    </CLPatientsListHeaderWrapper>
  );
});
