import { AxiosPromise } from "axios";
export interface ApiParameters {
  params?: any;
  headers?: any;
}

export type ApiErrorResponse = {
  ok: false
  status: number
  error: string
};

export type ApiSuccessResponse = {
  ok: true
  status: number
  data: any
};

export type ApiResponse = ApiErrorResponse | ApiSuccessResponse;

export const getResponse = async(request: AxiosPromise): Promise<ApiResponse> => {
  try {
    const { data, status } = await request;
    return { ok: true, data, status };
  } catch ({ response }) {
    if (!response) {
      return { ok: false, status: 0, error: "Failed" };
    }
    const url = response.config.url;
    const method = response.config.method.toUpperCase();
    console.error(`API error [${response.status}] for [${method} ${url}]: \n`, response.data);
    if (response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return { ok: false, status: response.status, error: response.data };
  }
};
