import { ClDevicesModalServiceRequestWrapper } from "./cl-devices-modal-service-request.presents";
import { CLModalCloseBtnStyle } from "../shared/cl-modal/cl-modal.presents";
import { Modal } from "react-bootstrap";
import Close from "../../assets/icons/close.svg";
import { CLIcon } from "../shared/cl-icon";
import { CLLightButton, CLBlueButton } from "../shared/cl-button";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component/cl-dynamic-form-component";
import { IUserFormModel } from "../../stores/createUser/user.model";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { message } from "antd";

export const ClDevicesModalServiceRequest = observer(() => {
  const { selectedDevice, showRequestModal, hideRequestModal, serviceRequestDevice } = useRootStore().listDevicesStore;
  const { setGlobalSpiner } = useRootStore().globalStore;

  const [contact, setContact] = useState("");
  const [summary, setSummary] = useState("");

  const hideModal = () => {
    hideRequestModal("");
    setContact("");
    setSummary("");
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    confirmAlert({
      message: "Send Request?",
      overlayClassName: "cl-confirm-modal",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {}
        },
        {
          label: "Send",
          className: "confirm-btn",
          onClick: () => {
            setGlobalSpiner(true);
            serviceRequestDevice(selectedDevice.SerialNumber, contact, summary)
              .then((result: boolean) => {
                if (!result) {
                  message.error({ content: "Error, Something went wrong!", duration: 2 });
                }
                setGlobalSpiner(false);
              });
          }
        }
      ]
    });
    hideModal();
  };

  const handleChange = () => {};

  return (
    <Modal
      centered
      show={showRequestModal}
      dialogClassName="invite-admin-modal">
      <Modal.Body>
        <CLModalCloseBtnStyle onClick={hideModal}>
          <CLIcon icon={Close}></CLIcon>
        </CLModalCloseBtnStyle>
        {selectedDevice && <ClDevicesModalServiceRequestWrapper>
          <div className="form-container">
            <div className="title">Service Request</div>
            <form onSubmit={onSubmit}>
              <div className="fields-container">
                <div className="content-info">
                  <div className="content-item">
                    <div className="title">Serial number:</div>
                    <div className="text">{selectedDevice.SerialNumber}</div>
                  </div>
                  <div className="content-item">
                    <div className="title">Product:</div>
                    <div className="text">{selectedDevice.ModelNumber}</div>
                  </div>
                </div>
                <CLDynamicFormComponent
                  item={{
                    name: "Direct Contact Information",
                    value: contact,
                    type: "input",
                    fieldId: "contact",
                    setValue: setContact
                  } as IUserFormModel}
                  customClass="invite-field"
                  handleChange={handleChange}></CLDynamicFormComponent>
                <CLDynamicFormComponent
                  item={{
                    name: "Reason of return",
                    value: summary,
                    type: "textarea",
                    fieldId: "summary",
                    placeholder: "Enter the reason of return",
                    setValue: setSummary
                  } as IUserFormModel}
                  customClass="invite-field"
                  handleChange={handleChange}></CLDynamicFormComponent>
              </div>
              <div className="btn-container">
                <CLLightButton type="button" text="Cancel" click={hideModal}/>
                <CLBlueButton
                  type="submit"
                  isDisabled={!contact || !summary}
                  text="Send Request" click={() => {}}/>
              </div>
            </form>
          </div>
        </ClDevicesModalServiceRequestWrapper>}
      </Modal.Body>
    </Modal>
  );
});
