import styled from "styled-components";
import { PropsType } from "./cl-dropdown.props";
import { theme } from "../../../theme/theme";

export const DropDownStyle = styled.div<PropsType>`
  .dropdown {
    button {
      background: ${theme.colors.light} !important;
      border: none !important;
      color: ${theme.colors.text} !important;
      cursor: pointer;
      padding: 0;
      box-shadow: none !important;

      .text {
        display: inline-block;
        line-height: 24px
      }

      img {
        display: inline-block;
        vertical-align: middle;
      }
      img.icon-main {
        width: 32px;
        height: 32px;
      }
    }

    button::after {
      display: none;
    }
  }

  .rotate-icon {
    button[aria-expanded=true] {
      img.icon {
        transform: rotate(180deg);
      } 
    }
  }

  .dropdown-menu {
    background: ${theme.colors.light};
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border: none;
    padding: 8px 0;

    a {
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      padding: 16px;
      border-bottom: 1px solid ${theme.colors.background};
      position: relative;
    }

    a:last-child {
      border: none;
    }

    .disabled {
      opacity: 0.5;
    }

    .text-icon {
      padding-left: 32px;
    }
  }
  


`;
