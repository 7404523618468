import { IObservableArray } from "mobx";
import { types, flow } from "mobx-state-tree";
import { ReportType } from "../boot/Constants";
import { convertReportsByDate, encodeData } from "../services/handlers/data.handler";

import {
  deleteReportApi,
  downloadReportApi,
  signReportApi,
  getAllReportsApi,
  generateReportSummaryApi,
  generateReportDailyApi, reportFindingsApi
} from "../services/api/report.api";

export const ReportStoreModel = types
  .model("ReportStoreModel", {
    reportsAll: types.optional(types.array(types.frozen()), []),
    reportsDaily: types.optional(types.array(types.frozen()), []),
    reportsStat: types.optional(types.array(types.frozen()), []),
    reportsSummary: types.optional(types.array(types.frozen()), []),
    activeType: types.optional(types.number, -1),
    activeReport: types.optional(types.frozen(), {}),
    isLoading: types.optional(types.boolean, true),
    summary: types.optional(types.string, ""),
    blobPDF: types.maybe(types.frozen()),
    urlPDF: types.maybe(types.frozen()),
    validSummary: types.optional(types.boolean, true),
    isPreviewDaily: types.optional(types.boolean, false),
    isPreviewSymmary: types.optional(types.boolean, false),
    blobPDFPreview: types.maybe(types.frozen()),
    urlPDFFPreview: types.maybe(types.frozen()),
    datePreview: types.optional(types.string, ""),
    latestReportID: types.optional(types.string, "")
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const setSummary = (value: string) => {
      self.summary = value;
      validateSummary();
    };

    const getAllReports = flow(function* getAllReports(
      recordingId: string | number, useActiveReport?: boolean, reportId?: string
    ) {
      self.setLoading(true);
      try {
        const result = yield getAllReportsApi(recordingId);
        if (result.ok) {
          if (result.data.length > 0) {
            self.reportsAll = convertReportsByDate(result.data) as IObservableArray;
            self.reportsDaily = convertReportsByDate(result.data, ReportType.Daily) as IObservableArray;
            self.reportsStat = convertReportsByDate(result.data, ReportType.Stat) as IObservableArray;
            self.reportsSummary = [...convertReportsByDate(result.data, ReportType.Summary),
              ...convertReportsByDate(result.data, ReportType.BlankSummary),
              ...convertReportsByDate(result.data, ReportType.SummaryM)] as IObservableArray;
            if (useActiveReport) {
              let report = null;
              const reportIdStored = reportId || self.activeReport.Id;
              self.reportsAll.forEach((days: any) => {
                const findedReport = days.data.find((item: any) => item.Id === reportIdStored);
                if (findedReport) {
                  report = findedReport;
                }
              });
              report ? setActiveReport(report) : setActiveReport(self.reportsAll[0].data[0]);
            } else {
              const activeReport = result.data.find((report: any) => report.Id === reportId);
              setActiveReport(activeReport || self.reportsAll[0].data[0]);
            }
            self.latestReportID = recordingId + "_";
          } else {
            self.reportsAll.replace([])as IObservableArray;
            self.reportsDaily.replace([]) as IObservableArray;
            self.reportsStat.replace([]) as IObservableArray;
            self.reportsSummary.replace([]) as IObservableArray;
            self.activeReport = {};
            self.summary = "";
            self.blobPDF = null;
            self.urlPDF = null;
            self.isPreviewDaily = false;
            self.isPreviewSymmary = false;
            self.blobPDFPreview = null;
            self.urlPDFFPreview = null;
            self.datePreview = "";
            self.validSummary = true;
            self.latestReportID = "";
          }

          self.activeType = -1;
          self.setLoading(false);
          return true;
        }
        self.setLoading(false);
      } catch (error) {
        self.setLoading(false);
        return false;
      }
    });

    const deleteReport = flow(function* deleteReport(reportID: string | number, recordingID: string | number) {
      self.setLoading(true);
      try {
        const result = yield deleteReportApi(reportID);
        if (result.ok) {
          getAllReports(recordingID);
          return true;
        }
        self.setLoading(false);
        return false;
      } catch (error) {
        self.setLoading(false);
        return false;
      }
    });

    const reportFindings
      = flow(function* deleteReport(deviceSerial: string, recordingID: string) {
        try {
          const result = yield reportFindingsApi(recordingID, deviceSerial);
          if (result.ok) {
            setSummary(result.data || "");
            return true;
          }
          return false;
        } catch (error) {
          return false;
        }
      });

    const downloadPdf = (blob: any, name?: string) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name || "Report.pdf";
      link.click();
    };

    const downloadReport = flow(function* downloadReport(reportID: string | number) {
      self.setLoading(true);
      try {
        if (!self.blobPDF) {
          const result = yield downloadReportApi(reportID);
          if (result.ok) {
            downloadPdf(result.data);
          }
          self.setLoading(false);
          return !!result.ok;
        } else {
          downloadPdf(self.blobPDF);
          self.setLoading(false);
          return true;
        }
      } catch (error) {
        self.setLoading(false);
        return false;
      }
    });

    const downloadReportPreview = () => {
      downloadPdf(self.blobPDFPreview, "Preview Report.pdf");
      self.setLoading(false);
      return true;
    };

    const signReport = flow(function* signReport(recordingID: string | number, idReport: string | number) {
      if (!validateSummary()) { return null; }
      self.setLoading(true);
      try {
        const params = encodeData({
          idReport: idReport,
          recordingID,
          date: "",
          physicianInterpretation: self.summary
        });
        const result = yield signReportApi(params);
        if (result.ok) {
          getAllReports(recordingID, true);
          return true;
        }
        self.setLoading(false);
        return false;
      } catch (error) {
        self.setLoading(false);
        return false;
      }
    });

    const signReportDayly = flow(function* signReportDayly(recordingID: string | number, date: string) {
      if (!validateSummary()) { return null; }
      self.setLoading(true);
      try {
        const params = encodeData({
          idReport: 0,
          recordingID,
          date,
          physicianInterpretation: self.summary
        });
        const result = yield signReportApi(params);
        if (result.ok) {
          getAllReports(recordingID);
          return true;
        }
        self.setLoading(false);
        return false;
      } catch (error) {
        self.setLoading(false);
        return false;
      }
    });

    const generateReportSummary = flow(function* generateReportSummary(recordingID: string | number) {
      self.setLoading(true);
      try {
        const result = yield generateReportSummaryApi(recordingID);
        if (result.ok) {
          const blob = new Blob([result.data], { type: "application/pdf" });
          self.isPreviewDaily = false;
          self.isPreviewSymmary = true;
          self.blobPDFPreview = blob;
          self.urlPDFFPreview = URL.createObjectURL(blob);
          self.datePreview = "";
          setSummary("");
        }
        self.validSummary = true;
        self.setLoading(false);
        return false;
      } catch (error) {
        self.setLoading(false);
        return false;
      }
    });

    const generateReportDaily = flow(function* generateReportDaily(recordingID: string | number, date: string) {
      self.setLoading(true);
      try {
        const result = yield generateReportDailyApi(recordingID, date);
        if (result.ok) {
          const blob = new Blob([result.data], { type: "application/pdf" });
          self.isPreviewDaily = true;
          self.isPreviewSymmary = false;
          self.blobPDFPreview = blob;
          self.urlPDFFPreview = URL.createObjectURL(blob);
          self.datePreview = date;
          setSummary("");
        }
        self.validSummary = true;
        self.setLoading(false);
        return false;
      } catch (error) {
        self.setLoading(false);
        return false;
      }
    });

    const setActiveType = (type: number) => {
      self.activeType = type;
    };

    const setActiveReport = flow(function* setActiveReport(item: any) {
      self.activeReport = item;
      self.isPreviewDaily = false;
      self.isPreviewSymmary = false;
      self.blobPDFPreview = null;
      self.urlPDFFPreview = null;
      self.datePreview = "";
      setSummary(item.PhysicianInterpretation);
      const resultPdf = yield downloadReportApi(item.Id);
      if (resultPdf.ok) {
        const blob = new Blob([resultPdf.data], { type: "application/pdf" });
        self.blobPDF = blob;
        self.urlPDF = URL.createObjectURL(blob);
      }
    });

    const validateSummary = () => {
      self.validSummary = !!self.summary.trim();
      if (self.summary.trim().length > 1000) {
        self.validSummary = false;
      }
      return self.validSummary;
    };

    const clearURLs = () => {
      self.urlPDF = null;
      self.urlPDFFPreview = null;
      self.blobPDFPreview = null;
      self.reportsAll.replace([])as IObservableArray;
      self.reportsDaily.replace([]) as IObservableArray;
      self.reportsStat.replace([]) as IObservableArray;
      self.reportsSummary.replace([]) as IObservableArray;
      self.activeReport = {};
      self.summary = "";
      self.blobPDF = null;
      self.isPreviewDaily = false;
      self.isPreviewSymmary = false;
      self.datePreview = "";
      self.validSummary = true;
      self.latestReportID = "";
    };

    return {
      getAllReports,
      deleteReport,
      downloadReport,
      downloadReportPreview,
      signReport,
      signReportDayly,
      setActiveType,
      setActiveReport,
      setSummary,
      reportFindings,
      clearURLs,
      generateReportSummary,
      generateReportDaily,
      validateSummary
    };
  });
