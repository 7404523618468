import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLPatientListTableWraper = styled.div`
  table {
    margin-top: ${theme.offsets.mainContentVertical};
    max-width: 100%;
  }
  thead {
    //border: none;
    background: ${theme.colors.backgroundTableHeader};
    
    th:first-child {
      border-radius:12px 0 0 12px;
    }
    
    th:last-child {
      border-radius:0 12px 12px 0;
    }

    th {
      border: none;
      padding: 24px 12px 24px 12px;
      font-weight: 500;
      font-size: 12px;
      vertical-align: middle;
      line-height: 16px;
      color: ${theme.colors.textTableHeader};
    }

    .action {
      text-align: right;
      padding-right: 14px;
      justify-content: flex-end;
    }
    .status-container {
      position: absolute;
      left: -10px;
      top: -15px;
      .cl-select__menu {
        padding-top: 15px;
        border-radius: 10px !important;
      }
      .cl-select__indicator{
        width: 150px;
        position: absolute;
        left: 0;
      }
      .cl-select__control {
        opacity: 0;
      }
    }
    .sort {
      display: flex;
      position: relative;

      .sort-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 5px;
        min-height: 24px;
      }

      .sort-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      }

      .bottom-sort {
        transform: rotate(180deg);
      }
      
    }

  }

  tbody {
    position: relative;

    td {
      border: none;
      padding: 24px 24px 24px 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      vertical-align: middle;
      &.admin-role {
        color: ${theme.colors.blue}
      }
      &.red {
        color: ${theme.colors.red}
      }
    }

    tr {
      border-bottom: 1px solid ${theme.colors.background};
    }
    tr:last-child {
      border: none;
    }

    .action {
      text-align: right;
      padding-right: 14px;
    }

    .monitoring {
      .record {
        .received {
          padding-top: 8px;
          display: flex;
          justify-items: center;

          .small {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: ${theme.colors.text};
            padding-right: 8px;
          }
          
        }
      }
    }

    .link {
      cursor: pointer;
      color: ${theme.colors.blue};
    }

    .copy {
      cursor: default !important;
      white-space: nowrap;
      
      div {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 4px;
        cursor: pointer;
      }
    }

  }
  .last-comm {
    &.red {
      color: ${theme.colors.red}
    }
    &.orange {
      color: ${theme.colors.yellow}
    }
    &.green {
      color: ${theme.colors.green}
    }
  }


  .no-result-section {
    height: 50vh;
    display: flex;

    .no-result-content {
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .text {
        margin-top: 16px;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        color: ${theme.colors.text};
      }
    }
  }

  .count-new-events {
    background-color: ${theme.colors.red};
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    color: ${theme.colors.light};
  }

  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .cursor {
    td {
      cursor: pointer;
    }

    td:last-child{
      cursor: inherit;
    }
  }

  .alert-block {
    color: ${theme.colors.red};
    white-space: nowrap;
    display: flex;
    align-items: center;

    div {
      display: inline-block;
    }

    .text {
      padding-left: 4px;
    }
  }

  .normal {
    color: ${theme.colors.text};
  }

  .pointer {
    cursor: pointer;
  }
`;
