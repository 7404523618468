import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLPatientReportsMainWraper } from "./cl-patient-reports-main.presents";
import DeleteIcon from "../../assets/icons/report-delete.svg";
import DownloadIcon from "../../assets/icons/report-download.svg";
import { CLCard } from "../shared/cl-card";
import { CLIcon } from "../shared/cl-icon";
import { CLGreenButton } from "../shared/cl-button";
import { useState, useEffect, useRef } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import debounce from "lodash.debounce";
import { FCMID, IDTFID, RA1ID, RA2ID, ReportType } from "../../boot/Constants";
import Sticky from "react-sticky-el";
import { useHistory } from "react-router";
import { isFeatureFlagActive } from "../../services/feature-flag.service";
import { confirmAlert } from "react-confirm-alert";

export const CLReportPdf = observer((props: { url : any}) => {
  const [pagesCount, setPages] = useState(0);
  const [wrapperDivSize, setWrapperDivSize] = useState(0);
  const pdfRef = useRef<HTMLDivElement>(null);

  const initWrapperSize = () => {
    if (pdfRef && pdfRef.current && pdfRef.current.offsetWidth !== wrapperDivSize) {
      setWrapperDivSize(pdfRef.current.offsetWidth);
    }
  };

  const onResize = debounce(() => {
    initWrapperSize();
  }, 100);

  useEffect(() => {
    initWrapperSize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  const getPages = () => {
    const pages = [];
    for (let i = 1; i <= pagesCount; i++) {
      pages.push(<div>
        <div className="title">Page {i}</div>
        <CLCard padding="24px">
          <Page pageNumber={i} width={wrapperDivSize} />
        </CLCard>
      </div>);
    }

    return pages;
  };

  return (
    <>
      {props.url && <div className="pdf-container">
        <div ref={pdfRef}>
          <Document
            file={props.url}
            onLoadSuccess={(pdf) => {
              setPages(pdf.numPages);
            }}>
            {pagesCount > 0 && getPages()}
          </Document>
        </div>
      </div>}
    </>
  );
});

export const CLPatientReportsMain = observer(() => {
  const { patient } = useRootStore().patientInfoStore;
  const {
    deleteReport,
    downloadReport,
    downloadReportPreview,
    signReport,
    signReportDayly,
    activeReport,
    summary,
    setSummary,
    reportFindings,
    validSummary,
    isPreviewSymmary,
    isPreviewDaily,
    urlPDFFPreview,
    datePreview,
    urlPDF
  } = useRootStore().reportStore;
  const { IdCurrentMC } = useRootStore().userStore;
  const isRHMC = IdCurrentMC + "" === RA1ID + "" || IdCurrentMC + "" === RA2ID + "" || IdCurrentMC === IDTFID + "";
  const isFMC = IdCurrentMC + "" === FCMID + "";

  const history: any = useHistory();

  const handleSummaryChange = (event: any) => {
    setSummary(event.target.value);
  };

  const handleDeleteClick = (ev: any) => {
    ev.preventDefault();
    confirmAlert({
      message: "Are you sure you want to delete this report?",
      overlayClassName: "cl-confirm-modal",
      buttons: [
        {
          label: "Cancel",
          onClick: () => {}
        },
        {
          label: "Delete",
          className: "delete-btn",
          onClick: () => {
            deleteReport(activeReport.Id, patient.RecordingID);
          }
        }
      ]
    });
  };

  const handleDownloadClick = (ev: any, isPreview?: boolean) => {
    ev.preventDefault();
    if (isPreview) {
      downloadReportPreview();
    } else {
      downloadReport(activeReport.Id);
    }
  };

  const handleSignDaylyClick = (ev: any) => {
    ev.preventDefault();
    signReportDayly(patient.RecordingID, datePreview).then(() => {
      if (history.location.state && history.location.state.returnToReports) {
        history.push("/reports-list");
      }
    });
  };

  const handleSignClick = (ev: any) => {
    ev.preventDefault();
    signReport(patient.RecordingID, activeReport.Id).then(() => {
      if (history.location.state && history.location.state.returnToReports) {
        history.push("/reports-list");
      }
    });
  };
  const isInsertFindingRequest = activeReport.ReportType === ReportType.Holter;
  const isInsertFindingShow = (isRHMC || isFMC)
    ? (activeReport.ReportType !== ReportType.BlankHolter)
    : (activeReport.ReportType !== ReportType.BlankHolter && activeReport.ReportType !== ReportType.Holter);

  const copiedFindings = () => {
    if (isInsertFindingRequest) {
      reportFindings(patient?.Device?.SerialNumber, patient.RecordingID);
    } else {
      setSummary(activeReport.TechnicianFindings);
    }
  };

  const isSignSaveByReportTypeAvailable
    = activeReport.ReportType === ReportType.Holter
    || activeReport.ReportType === ReportType.BlankHolter
    || activeReport.ReportType === ReportType.Summary
    || activeReport.ReportType === ReportType.SummaryM;

  const isSummaryMReport = activeReport.ReportType === ReportType.SummaryM;
  const isHolterReport = isFeatureFlagActive("sighScrubbedHolterReport")
    && activeReport.ReportType === ReportType.Holter;
  const isSummaryReport = isFeatureFlagActive("sighScrubbedSummaryReport")
    && activeReport.ReportType === ReportType.Summary;

  const isSignSaveAvailable = patient?.Device?.DeviceSettings?.ArchiveStatus !== 2 || isSignSaveByReportTypeAvailable;
  const isScrubbed = patient?.Device?.DeviceSettings?.ArchiveStatus === 2;
  const isSignSaveBlocked = !isScrubbed || isSummaryMReport || isHolterReport || isSummaryReport;

  const getDataForView = () => {
    if (isPreviewSymmary) {
      return (
        <div>
          <Sticky>
            <CLCard padding="24px">
              <div className="main">
                <div className="info"></div>
                <div className="actions">
                  <div className="action" onClick={(ev: any) => { handleDownloadClick(ev, true); }}>
                    <CLIcon icon={DownloadIcon}></CLIcon>
                    <div className="text">Download Report</div>
                  </div>
                </div>
              </div>
            </CLCard>
          </Sticky>
          <CLReportPdf url={urlPDFFPreview}></CLReportPdf>
        </div>
      );
    } else if (isPreviewDaily) {
      return (
        <div>
          <Sticky>
            <CLCard padding="24px">
              <div className="main">
                {isSignSaveBlocked
                  ? <div className="info">
                    <div className="title">Final Interpretation
                      {isInsertFindingShow
                        && <span onClick={() => copiedFindings()}>Insert Findings</span>}
                    </div>
                  </div>
                  : null}
                <div className="actions">
                  <div className="action" onClick={(ev: any) => { handleDownloadClick(ev, true); }}>
                    <CLIcon icon={DownloadIcon}></CLIcon>
                    <div className="text">Download Report</div>
                  </div>
                </div>
                {isSignSaveBlocked
                  ? <div className="action-butons">
                    <div className="action">
                      {isSignSaveAvailable
                        ? (<CLGreenButton
                          isDisabled={!validSummary}
                          text="Sign & Save"
                          click={handleSignDaylyClick}></CLGreenButton>)
                        : (<div><p>Report can no longer be signed</p></div>)}
                    </div>
                  </div>
                  : null}
              </div>
              {isSignSaveBlocked
                ? <div className="summary">
                  <textarea
                    className={!validSummary ? "error-textarea" : ""}
                    placeholder="Enter report summary"
                    value={summary}
                    onChange={handleSummaryChange}></textarea>
                  <div className={"chart-length"}>{summary.length}/1000</div>
                  {!validSummary && !summary.trim().length && <div className="error">This field cannot be empty</div>}
                  {!validSummary && !!summary.length && <div className="error">Exceeded 1000 character limit</div>}
                </div>
                : null}
            </CLCard>
          </Sticky>
          <CLReportPdf url={urlPDFFPreview}></CLReportPdf>
        </div>
      );
    } else {
      if (activeReport && activeReport.Id) {
        return (
          <div >
            <Sticky>
              <CLCard padding="24px">
                <div className="main">
                  {isSignSaveBlocked
                    ? <div className="info">
                      <div className="title">Final Interpretation
                        {isInsertFindingShow
                          && <span onClick={() => copiedFindings()}>Insert Findings</span>}
                      </div>
                    </div>
                    : null}
                  <div className="actions">
                    {(activeReport.ReportType === ReportType.Daily)
                    && <div className="action delete" onClick={(ev: any) => { handleDeleteClick(ev); }}>
                      <CLIcon icon={DeleteIcon}></CLIcon>
                      <div className="text">Delete Report</div>
                    </div>}
                    {(activeReport.ReportType === ReportType.Daily) && <div className="split-block"></div>}
                    <div className="action" onClick={(ev: any) => { handleDownloadClick(ev); }}>
                      <CLIcon icon={DownloadIcon}></CLIcon>
                      <div className="text">Download Report</div>
                    </div>
                  </div>
                  {isSignSaveBlocked
                    ? <div className="action-butons">
                      <div className="action">
                        {isSignSaveAvailable
                          ? (<CLGreenButton
                            isDisabled={!validSummary}
                            text="Sign & Save" click={handleSignClick}></CLGreenButton>)
                          : (<p className={"report-no-signin"}>Report can no longer be signed</p>)}
                      </div>
                    </div>
                    : null}
                </div>
                {isSignSaveBlocked
                  ? <div className="summary">
                    <textarea
                      className={!validSummary ? "error-textarea" : ""}
                      placeholder="Enter report summary"
                      value={summary}
                      onChange={handleSummaryChange}></textarea>
                    <div className={"chart-length"}>{summary.length}/1000</div>
                    {!validSummary && !summary.trim().length && <div className="error">This field cannot be empty</div>}
                    {!validSummary && !!summary.length && <div className="error">Exceeded 1000 character limit</div>}
                  </div>
                  : null}
              </CLCard>
            </Sticky>
            <CLReportPdf url={urlPDF}></CLReportPdf>
          </div>
        );
      } else {
        return (<div style={{ flex: 1 }}>
          <CLCard padding="24px">
            <p className="empty-state">Sorry, no reports were found.</p>
          </CLCard>
        </div>);
      }
    }
  };
  return (
    <CLPatientReportsMainWraper>
      {getDataForView()}
    </CLPatientReportsMainWraper>
  );
});
