import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLModal } from "../shared/cl-modal";
import { CLSelect } from "../shared/cl-select";
import {
  PatientInfoEditEventsStyle,
  PatientInfoEditEventsModalContainerStyle
} from "./cl-patient-info-edit-event.presents";
import { PropsType } from "./cl-patient-info-edit-event.props";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { ArrhythmiaType } from "../../stores/patientInfo/patientInfo.constant";
import API from "../../boot/Api";
import * as Types from "../../services/api/api.types";
import { OptionType } from "../shared/cl-select/cl-select.props";
import { encodeData } from "../../services/handlers/data.handler";

const { RangePicker } = DatePicker;

// POST /clinicAPI/ClinicReports/AddEvent

const updateReport2 = async(idEvent: number | string, eventType: number | string): Promise<any> => {
  const encodedData = encodeData({
    idEvent: idEvent,
    eventType: eventType,
    caption: ""
  });
  const result = API.post(`ClinicReports/UpdateEvent?${encodedData}`);
  return await Types.getResponse(result);
};

function getActiveEventStartDate(item: any): [Moment, Moment] {
  const activeEventDate = moment(item.StartDate);
  const activeEventDate2 = moment(item.StartDate).add(item.Length, "seconds");
  const value = activeEventDate || moment();
  const value2 = activeEventDate2 || moment();
  return [value, value2];
}

export const ClPatientInfoEditEvent = observer((props: PropsType) => {
  const {
    patient,
    getPatient
  } = useRootStore().patientInfoStore;
  const activeArrhythmiaType: OptionType | undefined = ArrhythmiaType.find((item: OptionType) => {
    if (item && item.id && +item.id === +props.item.EventType) {
      return item;
    }
  });
  const [fields, setFields] = useState({
    eventRange: getActiveEventStartDate(props.item),
    eventType: {
      value: activeArrhythmiaType || { id: "3", label: "Pause", value: "Pause" }
    }
  });

  const saveChanges = () => {
    const eventType = fields.eventType.value && fields.eventType.value.id || 0;
    updateReport2(props.item.Id, eventType)
      .then((response: any) => {
        if (response.ok) {
          props.closeModal();
          getPatient(patient.RecordingID, true);
        } else {
          console.error("Something went wrong");
        }
      });
  };

  const handleDatePick = (val: any) => {
    setFields((prevValue) => ({ ...prevValue, eventRange: val }));
  };

  const selectOption = (ev: any) => {
    setFields((prevValue) => ({ ...prevValue, eventType: { value: ev } }));
  };
  const disabledDate = (current: Moment) => {
    // Can not select days before today and today
    return current && current < moment(props.item.StartDate);
  };

  return (
    <PatientInfoEditEventsStyle>
      <CLModal show={props.isShow} handleHideModal={() => props.closeModal()} title={"Edit Event"}>
        <PatientInfoEditEventsModalContainerStyle>
          <div className="modal-container">
            <div className="title">
              Edit Event
            </div>
            <div className="tabs">
              <div className="form">
                <div className="form-field form-range">
                  <label>
                    Event Range
                  </label>
                  <div className="input-field">
                    <RangePicker
                      popupStyle={{ zIndex: 10500 }}
                      value={fields.eventRange}
                      onChange={handleDatePick}
                      disabledDate={disabledDate}
                      format={"MM/DD/yyyy hh:mm:ss A"} showTime disabled={true}/>
                  </div>
                </div>
                <div className="form-field">
                  <label>
                    Event Type
                  </label>
                  <CLSelect width="100%" options={ArrhythmiaType} selectOption={selectOption} name={"type"}
                    defaultValue={fields.eventType.value}></CLSelect>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="btn-wrapper">
                <CLLightButton click={props.closeModal} text="Cancel" width="200px"></CLLightButton>
              </div>

              <div className="btn-wrapper">
                <CLBlueButton click={saveChanges} text="Save" width="200px"></CLBlueButton>
              </div>
            </div>
          </div>
        </PatientInfoEditEventsModalContainerStyle>

      </CLModal>

    </PatientInfoEditEventsStyle>
  );
});
