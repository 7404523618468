import { observer } from "mobx-react-lite";
import { ClMobileNotificationWrapper } from "./cl-mobile-notification.presents";
import { useRootStore } from "../../stores/storeContext";
import { convertNotificationsByDate } from "../../services/handlers/data.handler";
import { secondsToTimeString } from "../../services/handlers/date.handler";
import EventIcon from "../../assets/icons/list-patients-event.svg";
import EventIconRed from "../../assets/icons/events-stat.svg";
import { CLIcon } from "../shared/cl-icon";
import NotificationsIcon from "../../assets/icons/notification-mobile.svg";
import { getEventTypeConstant } from "../cl-patient-events-type/cl-patient-events-type.constant";
import { useState } from "react";
import { getStatReportByRecording, setEventsActionSTAT } from "../../services/api/user.api";
import { downloadReportApi } from "../../services/api/report.api";
import { EventActionCP } from "../../boot/Constants";
import { ClAlert } from "../shared/cl-alert";

export const Tabs = [
  {
    title: "New",
    id: 0
  },
  {
    title: "History",
    id: 1
  }
];

export const ClMobileNotification = observer(() => {
  const { newNotifications, lastNotifications, getNotifications, getProblemDevices } = useRootStore().userStore;
  const [activeType, setActiveType] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const { setGlobalSpiner } = useRootStore().globalStore;

  const sortData = () => {
    let list: any = [];
    if (newNotifications.length > 0 && activeType === 0) {
      list = convertNotificationsByDate(newNotifications, true);
    }

    if (lastNotifications.length > 0 && activeType === 1) {
      const sortedLastNotifications = convertNotificationsByDate(lastNotifications);
      list = list.concat(sortedLastNotifications);
    }
    return list;
  };

  const downloadPdf = (blob: any, name?: string) => {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = name || "Report.pdf";
    link.click();
  };

  const openEvent = async(ev: any, item: any) => {
    ev.preventDefault();
    if (item.RecordingID) {
      setGlobalSpiner(true);
      const response: any = await getStatReportByRecording(item.RecordingID, item.FolderName);
      if (response.ok && response.data && response.data[0]) {
        const result: any = await downloadReportApi(response.data[0].Id);
        if (result.ok) {
          await setEventsActionSTAT([item.Id] as string[] | number[], EventActionCP.STATReviewed);
          await getNotifications();
          await getProblemDevices();
          setGlobalSpiner(false);
          downloadPdf(result.data);
        } else {
          setShowAlert(true);
          setGlobalSpiner(false);
        }
      } else {
        setShowAlert(true);
        setGlobalSpiner(false);
      }
    }
  };

  const markAsViewed = async() => {
    const ids = newNotifications.map((item: any) => (item.Id));
    await setEventsActionSTAT([ids[0]] as string[] | number[], EventActionCP.STATReviewed);
    getNotifications();
  };

  const onHide = () => {
    setShowAlert(false);
  };

  return (
    <ClMobileNotificationWrapper>
      <div className="title">
        <h2>Notifications</h2>
        <div className="bottom-sheet">
          <div className="nav-wrapper">
            {Tabs.map((item: any) => (
              <div
                className={`nav-item ${item.id === activeType ? " active" : ""}`}
                onClick={() => setActiveType(item.id)}>
                {item.title}
              </div>)
            )}
          </div>
          <div className="btn">
            { activeType === 0 && <p onClick={markAsViewed}>Mark all as viewed</p> }
          </div>
        </div>
      </div>
      {(!sortData().length) && <div className="not-found">
        <CLIcon icon={NotificationsIcon} padding="7px" width={"56px"} height={"66px"}></CLIcon>
        <p>You don't have new notifications</p>
      </div>}
      {(newNotifications.length > 0 || lastNotifications.length > 0) && <div className="list">
        {sortData().map((item: any) => {
          if (item.data.length > 0) {
            return (
              <div>
                <p className="date-title">{item.date.format("MM/DD/YY")}</p>
                <div className="container-notification">
                  {item.data.map((notification: any) => <div className="notification-item"
                    onClick={(ev: any) => openEvent(ev, notification)}>
                    <div className={`icon ${item.isNew ? "new" : ""}`}>
                      <CLIcon icon={item.isNew ? EventIconRed : EventIcon}></CLIcon>
                    </div>
                    <div className="info">
                      <div className="caption">{getEventTypeConstant(notification.EventType)}</div>
                      <div className="date">
                        {secondsToTimeString(notification.Length)}{notification.EcgStripCaption ? " | " : ""}
                        {notification.EcgStripCaption}
                      </div>
                      <div className="date">
                        {notification.PatientName || ""} {notification.PatientLastName || ""}
                      </div>
                    </div>
                  </div>)}
                </div>
              </div>
            );
          }
        })}
      </div>}
      <ClAlert
        show={showAlert}
        onHide={onHide}
        msg={"There is no available reports"}/>
    </ClMobileNotificationWrapper>
  );
});
