import styled from "styled-components";

export const CLAdministrationHeaderWrapper = styled.div`
  display: flex;

  .margin-block-right {
    margin-right: 24px;
  }

  .create {
    margin-left: auto;
    display: flex;
    & > div {
      margin-left: 20px;
    }
  }
`;
