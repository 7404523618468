import { ClChartsEcgStyles } from "./cl-charts-ecg-preview.presents";
import CanvasJSReact from "../../assets/charts/@canvasjs/react-charts/canvasjs.react";
import { useRef } from "react";
import * as Types from "../../services/api/api.types";
import API from "../../boot/Api";
import { observer } from "mobx-react-lite";
import {
  getStoredBeats,
  isIncludeStoredBeats,
  setStoredBeats
} from "../../stores/patientInfo/patientInfo.helper.service";
import { CLSpiner } from "../shared/cl-spinner";
import {
  getChartsPreviewData
} from "../../stores/patientInfo/patientInfo.chartexport.service";
import { BeatsType } from "../cl-charts-canvas/cl-charts-canvas.service";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const getEcg = async(patientId: string, folderName: string): Promise<any> => {
  if (isIncludeStoredBeats(patientId, folderName)) {
    const result = getStoredBeats(patientId, folderName);
    return await Types.getResponse(result);
  } else {
    const result = API.get(`HSData/ECGData?recordingID=${patientId}&receivedDataFolderName=${folderName}`);
    setStoredBeats(patientId, folderName, result);
    return await Types.getResponse(result);
  }
};

const getChartHeight = () => {
  const windowWidth = window.innerWidth;
  let elementWidth;
  if (windowWidth > 1400) {
    elementWidth = 1400 - 80;
  } else {
    elementWidth = windowWidth * 0.9 - 80;
  }
  const height = elementWidth / 4.4;
  return height;
};

export const ClChartsEcgPreview = observer(() => {
  const Gstate = getChartsPreviewData();
  const myRef = useRef<HTMLDivElement>(null);

  const stripLines: { value: number, color: string }[] = [{ value: 0, color: "#FF52E8" }];
  const stripLinesShort: { value: number, color: string }[] = [{ value: 0, color: "#000000" }];

  let options = {};
  let optionsShort = {};
  const dataShort: any[] = [];
  const dataShort2: any[] = [];
  const chartHeight = getChartHeight();

  const getStripLines = () => {
    if (Gstate && Gstate.dataShort && Gstate.data) {
      const countStripesShort = Math.round(Gstate.dataShort.length * 10 / 1000);

      let n = (Math.floor(Gstate.data[0].x / 500) + 0) * 500;

      while (n < Gstate.data[Gstate.data.length - 1].x) {
        stripLines.push({ value: n, color: "rgb(237, 105, 226)" });
        n += 200;
      }
      stripLines[0].value = 4;
      stripLines.push({ value: 7996, color: "rgb(237, 105, 226)" });

      for (let i = 0; i < countStripesShort; i++) {
        stripLinesShort.push({ value: i * 1000, color: "#000000" });
      }
    }
  };

  const getDataShort = () => {
    if (Gstate.dataEvent && Gstate.dataShort && Gstate.dataShort.length) {
      //  get middle of selected 8second ecg
      const middle = (Gstate.dataEvent[1].x + Gstate.dataEvent[2].x) / 2;
      if (middle > 15000) {
        for (let i = 0; i < Gstate.dataShort.length; i++) {
          const item = Gstate.dataShort[i];
          if (item.x > middle - 15000 && item.x < middle + 15000) {
            dataShort.push({
              x: item.x,
              y: item.y
            });
          } else if (item.x > middle + 15000) {
            break;
          }
        }
        if (Gstate.dataShort2) {
          for (let i = 0; i < Gstate.dataShort2.length; i++) {
            const item = Gstate.dataShort2[i];
            if (item.x > middle - 15000 && item.x < middle + 15000) {
              dataShort2.push({
                x: item.x,
                y: item.y
              });
            } else if (item.x > middle + 15000) {
              break;
            }
          }
        }
      } else {
        for (let i = 0; i < Gstate.dataShort.length; i++) {
          const item = Gstate.dataShort[i];
          if (item.x < 30000) {
            dataShort.push({
              x: item.x,
              y: item.y
            });
          } else if (item.x > 30000) {
            break;
          }
        }
        if (Gstate.dataShort2) {
          for (let i = 0; i < Gstate.dataShort2.length; i++) {
            const item = Gstate.dataShort2[i];
            if (item.x < 30000) {
              dataShort2.push({
                x: item.x,
                y: item.y
              });
            } else if (item.x > 30000) {
              break;
            }
          }
        }
      }
    }
  };

  if (Gstate && Gstate.dataShort && Gstate.data && Gstate.dataSecond
        && Gstate.dataShort.length && Gstate.data.length && Gstate.dataSecond.length
        && Gstate.dataEvent && Gstate.dataEvent.length) {
    getStripLines();
    getDataShort();
    Gstate.dataEvent.map((item: any) => {
      item.color = "red";
      item.lineColor = "rgb(172, 121, 90)";
      if (item.y < 0) {
        item.y = -0.95;
      }
    });

    const dataNumbersMMMV = Gstate.dataNumbers && Gstate.dataNumbers.map((item) => {
      item.y = 1.35 * Gstate.mmmV;
      return item;
    });

    const getMMMvNumber = () => {
      const chartData: BeatsType[] = [];
      chartData.push({
        x: 8000 - 600,
        y: -1.8 * Gstate.mmmV,
        indexLabel: (10 / Gstate.mmmV) + " mm/mV",
        indexLabelTextAlign: "left"
      });
      chartData.push({
        x: 8000 - 660,
        y: -1.35 * Gstate.mmmV,
        indexLabel: "25 mm/s",
        indexLabelTextAlign: "left"
      });
      return chartData;
    };

    options = {
      animationEnabled: false,
      exportEnabled: false,
      zoomEnabled: false,
      height: chartHeight,
      backgroundColor: "rgb(255, 255, 255, 0)",
      theme: "light2", // "light1", "dark1", "dark2"
      toolTip: {
        enabled: false // enable here
      },
      axisY: {
        // title: "",
        // tickLength: 0,
        // lineThickness:0,
        // margin:0,
        interval: 0.5 * Gstate.mmmV,
        maximum: 2 * Gstate.mmmV,
        minimum: -2 * Gstate.mmmV,
        title: "",
        tickLength: 0,
        lineThickness: 0,
        margin: 0,
        labelFormatter: function() {
          return " ";
        }
      },
      axisX: {
        stripLines: stripLines,
        interval: 1000,
        title: "",
        tickLength: 0,
        lineThickness: 0,
        margin: 0,
        labelFormatter: function() {
          return " ";
        }
        // valueFormatString: "#,.#"
        // labelFormatter: function ( e ) {
        //     return "x: " + e.value;
        // }
      },
      data: [{
        markerType: "none",
        lineThickness: 2,
        type: "line",
        color: "#000000",
        xValueType: "number",
        // xValueType: 'dateTime',
        dataPoints: Gstate.data
      }, {
        markerType: "none",
        lineThickness: 2,
        type: "line",
        color: "#000000",
        xValueType: "number",
        // xValueType: 'dateTime',
        dataPoints: Gstate.dataSecond
      }, {
        lineThickness: 0,
        type: "scatter",
        markerSize: 0,
        color: "rgba(0,0,0,0)",
        xValueType: "number",
        indexLabelFontWeight: "bold",
        indexLabelFontSize: 16,
        indexLabelFontColor: "#000",
        // xValueType: 'dateTime',
        dataPoints: dataNumbersMMMV
      }, {
        lineThickness: 0,
        type: "scatter",
        markerSize: 0,
        color: "rgba(0,0,0,0)",
        xValueType: "number",
        indexLabelFontWeight: "bold",
        indexLabelFontSize: 16,
        indexLabelFontColor: "#000",
        // xValueType: 'dateTime',
        dataPoints: getMMMvNumber()
      }]
    };

    optionsShort = {
      animationEnabled: false,
      height: chartHeight * 0.27,
      backgroundColor: "rgb(255, 255, 255, 0)",
      toolTip: {
        enabled: false // enable here
      },
      axisY: {
        title: "",
        tickLength: 0,
        lineThickness: 0,
        margin: 0,
        maximum: 0.5,
        minimum: -1,
        gridThickness: 0,
        labelFormatter: function() {
          return " ";
        }
      },
      axisX: {
        title: "",
        tickLength: 0,
        lineThickness: 0,
        margin: 0,
        labelFormatter: function() {
          return " ";
        }
      },
      theme: "light2", // "light1", "dark1", "dark2"
      data: [{
        type: "line",
        lineThickness: 1,
        color: "#000000",
        xValueType: "dateTime",
        dataPoints: dataShort
      }, {
        type: "line",
        lineThickness: 1,
        color: "#000000",
        xValueType: "dateTime",
        dataPoints: dataShort2
      }, {
        type: "line",
        markerSize: 2,
        dataPoints: Gstate.dataEvent
      }
        // {
        //     type: 'rangeSplineArea',
        //     fillOpacity: 0.15,
        //     lineThickness: 0.5,
        //     markerType: 'none',
        //     color: 'rgb(69, 126, 245)',
        //     dataPoints: Gstate.dataEvent2
        // }
      ]
    };
  } else {
    return (
      <ClChartsEcgStyles>
        <div className="spiner"><CLSpiner id="ecg" boxShadow={"none"}></CLSpiner></div>
      </ClChartsEcgStyles>
    );
  }

  // const onClickch = () => {
  //   const chartElement = document.getElementById("chart-ecg");

  //   if (chartElement !== null) {
  //     html2canvasModule(chartElement, {
  //       scrollX: 0,
  //       scrollY: 0,
  //       allowTaint: true,
  //       useCORS: true,
  //       logging: false
  //     })
  //       .then(function(canvas) {
  //         console.log(canvas);
  //         document.body.appendChild(canvas);
  //       });
  //   }
  // };

  return (
    <ClChartsEcgStyles>
      <div className="holder">
        <div style={{ height: chartHeight + 10 + "px" }}>
          <CanvasJSChart options={options} style={{ height: chartHeight + "px" }}></CanvasJSChart>
        </div>
      </div>
      <div className="holder-short" ref={myRef}>
        <div style={{ height: chartHeight * 0.27 + "px" }}>
          <CanvasJSChart options={optionsShort}></CanvasJSChart>
        </div>
      </div>
    </ClChartsEcgStyles>
  );
});
