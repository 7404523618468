import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const CategoryItemStyle = styled.div`
  text-align: center;
  box-sizing: border-box;
  width: auto;
  position: relative;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  img {
    display: block;
    margin-right: 4px;
  }

  font-size: 16px;
  line-height: 18px;

  .title {
    color: ${theme.colors.text};
  }


  .bottom-border {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  &.active {
    .title {
      color: ${theme.colors.blue};
    }

    .bottom-border {
      display: block;
      height: 4px;
      background: ${theme.colors.blue};
    }
  }
  
  &.no-select {
    pointer-events: none;
  }
`;
