import { IProfileModelSnapShot } from "./profile.model";

export const ProfileInitialState: IProfileModelSnapShot = {
  id: "0",
  firstName: {
    name: "First Name",
    value: "",
    type: "input",
    fieldId: "firstName"
  },
  lastName: {
    name: "Last Name",
    value: "",
    type: "input",
    fieldId: "lastName"
  },
  email: {
    name: "Email",
    value: "",
    type: "input",
    fieldId: "email"
  },
  username: {
    name: "Username",
    value: "",
    type: "input",
    fieldId: "username"
  },
  signAs: {
    name: "Sign As",
    value: "",
    type: "input",
    fieldId: "signAs"
  },
  phoneNumber: {
    name: "Primary Phone Number",
    value: "",
    type: "number",
    fieldId: "phoneNumber"
  },
  phone2: {
    name: "After Hours Number",
    value: "",
    type: "number",
    isOptional: true,
    fieldId: "phone2"
  },
  description: {
    name: "Text Notification Number",
    value: "",
    type: "number",
    isOptional: true,
    fieldId: "description"
  },
  allClinicLoad: {
    name: "Show All clinics",
    value: "false",
    type: "checkbox",
    isChecked: false,
    fieldId: "allClinicLoad"
  },
  twoFactorAuth: {
    name: "Enable 2-factor authentication",
    value: "false",
    type: "checkbox",
    isChecked: false,
    fieldId: "twoFactorAuth"
  },
  optOutTwoFactorAuth: {
    name: `I am opting out of implementing multi-factor authentication. I understand the risk this may place my clinic
     in regarding cyber attacks on PHI and other critical data.`,
    value: "false",
    type: "checkbox",
    isChecked: false,
    fieldId: "optOutTwoFactorAuth"
  },
  npi: {
    name: "NPI Number",
    value: "",
    type: "input",
    fieldId: "npi",
    isOptional: true
  }
};
