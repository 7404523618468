import { useLocation, useRouteMatch } from "react-router-dom";
import { CLNavItem } from "../shared/cl-nav-item";
import { BillingHeaderStyle } from "./cl-billing-header.presents";
import { BillingTabs } from "../../boot/Constants";
import { observer } from "mobx-react-lite";
import { DatePicker } from "antd";
import { useRootStore } from "../../stores/storeContext";
import moment from "moment";
import { useState } from "react";
import { CLIcon } from "../shared/cl-icon";
import CalendarIcon from "../../assets/icons/calendar.svg";

const { RangePicker } = DatePicker;

export const ClBillingHeader = observer(() => {
  const location = useLocation();
  const { url } = useRouteMatch();
  const { startDate, endDate, getItems, getUtilizationData } = useRootStore().billingStore;
  const dateFormat = "MM/DD/yyyy";
  let startDateM = moment();
  let endDateM = moment();

  if (startDate && endDate) {
    startDateM = moment(startDate, dateFormat);
    endDateM = moment(endDate, dateFormat);
  }

  const [state, setState] = useState<[any, any]>([startDateM, endDateM]);

  const onPickerChange = (values: any) => {
    setState(values);
    getItems(values[0].format(dateFormat), values[1].format(dateFormat));
    getUtilizationData(values[0].format(dateFormat), values[1].format(dateFormat));
  };

  return (
    <BillingHeaderStyle>
      <div className="nav-wraper">
        {BillingTabs.map((item) => (
          <CLNavItem key={item.id}
            link={`${url}${item.link}`}
            title={item.title}
            isActive={location.pathname === url + item.link}
            fontSize={"16px"}></CLNavItem>
        ))}
      </div>
      <div className="date-picker">
        <CLIcon icon={CalendarIcon}></CLIcon>
        <RangePicker popupStyle={{ zIndex: 10500 }} defaultValue={state} format={dateFormat} onChange={onPickerChange}/>
      </div>
    </BillingHeaderStyle>
  );
});
