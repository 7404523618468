import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLUserProfileWrapper = styled.div`
 position: relative;
  padding: 20px 60px;
  background: #FFF;
  & > .container {
    padding-top: 20px;
    padding-bottom: 200px;
    //display: flex;
    border-bottom: 1px solid ${theme.colors.background};
    max-width: 600px;
    margin: 0 auto;
    // align-items: center;
    //flex-wrap: wrap;
  }

  h1 {
    flex: 0 0 100%;
    font-size: 24px;
    line-height: 28px;
    padding: 0 10px;
  }

  .patient-create-block {
    margin-top: 20px;
    .profile-field{
      width: 100%;
    }
  }

  .button-holder {
    margin-right: 20px;
  }
  
  h3 {
    flex: 0 0 100%;
    margin: 20px 0;
    padding: 0 10px;
    font-size: 16px;
    line-height: 18px;
  }

  .form-block {
    width: 584px;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;

    label {
      font-size: 12px;
      line-height: 14px;
      width: 100%;
      position: relative;
    }

    .optional-flag {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 12px;
      line-height: 14px;
      color: #8A8A8A;
    }

    .checkbox-name {
      font-size: 14px;
      line-height: 16px;
    }

    &.invalid {
      input {
        border: 1px solid ${theme.colors.red};
      }

      .ant-picker {
        border: 1px solid ${theme.colors.red};

        input {
          border: 0;
        }
      }

      .cl-select__control {
        border: 1px solid ${theme.colors.red} !important;
      }
    }
  }

  .from-group-state {
    width: 100%;
    flex-wrap: wrap;
    padding: 0;
    display: flex;

    .form-block {
      width: 100%;
    }

    .form-control-state {
      width: 50%;
      flex-wrap: wrap;
    }

    .form-control-zip {
      width: 50%;
      flex-wrap: wrap;
    }
  }

  .ant-picker {
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #E5E5E5;
    color: #484848;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px;
    width: 100%;
  }

  .w100 {
    width: 100%;
  }
`;

export const ContainerCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 32px;
  .container-code {
    width: 580px;
    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.placeholder};
      padding-top: 40px;
      padding-left: 57px;
    }

    .auth-code-container {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      
      .auth-code-input {
        height: 64px;
        width: 64px;
        border: 1px solid #D1D7E3;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 28px;
        color: ${theme.colors.text};
        border-radius: 12px;
        text-align: center;
        margin-left: 16px;
      }
      .auth-code-input:first-child {
        margin-left: 0;
      }

      .error {
        border-color: ${theme.colors.red};
      }
    }

    .retry {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: ${theme.colors.blue};
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;

      span {
        cursor: pointer;
      }
    }

    .error-code {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      padding-left: 57px;
      color: ${theme.colors.red};
      margin-top: 16px;
      margin-bottom: 26px;
    }
  }
`;
