import { useRootStore } from "../../stores/storeContext";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component";
import { LegacyRef, useEffect, useRef, useState } from "react";
import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { CLSpiner } from "../shared/cl-spinner";
import { useParams } from "react-router-dom";
import { ClPatientInsuranceInfoWrapper } from "./cl-patient-insurance-info.presents";
import { CLUploadInsuranceCardImages } from "../cl-upload-insurance-card-images";
import { message } from "antd";
import { DeviceStatuses } from "../../boot/Constants";
import { CLIcon, ClIconNoResult } from "../shared/cl-icon";
import NoResultIcon from "../../assets/icons/no-result.svg";
import ClearIcon from "../../assets/icons/close.svg";

export const ClPatientInsuranceInfo = observer(() => {
  const {
    resetForm,
    isCheckingValid,
    validateFormFields,
    primaryInsuranceCompany,
    policyNumber,
    groupNumber,
    secondaryInsuranceCompany,
    secPolicyNumber,
    secGroupNumber,
    subscribersFirstName,
    specifyRelationship,
    subscribersLastName,
    subscribersRelationship,
    setDisableCompany,
    subscribersDOB,
    isLoading,
    saveForm
  } = useRootStore().patientCreateAdditionalModel;
  const { patientBase } = useRootStore().patientInfoStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const [highlights, setIsHighlights] = useState(false);
  const status = patientBase?.Device?.Status;

  const history: any = useHistory();
  const { patientId }: any = useParams();
  const fileRef = useRef<{ submitFile: Function, validateFile: Function }>(null);

  function validateFormHighLight() {
    const { errors, isValid } = validateFormFields(false);

    setIsHighlights(!isValid);
    setErrors(errors);
  }
  const isSpecifyRelationshipShow = subscribersRelationship.value === "Other";
  const isCreate = history?.location?.state?.isCreate;
  const isStudy = history?.location?.state?.isStudy;

  const isSaveIncompleateAvailable
    = !(DeviceStatuses.Standby === status
    || DeviceStatuses.Ready === status
    || DeviceStatuses.Recording === status
    || DeviceStatuses.Configured === status);

  const [showHelpPrimaryCompany, setShowHelpPrimaryCompany] = useState(false);
  const [showHelpSecondaryCompany, setShowHelpSecondaryCompany] = useState(false);
  const [isChangedPrimaryCompany, setIsChangedPrimaryCompany] = useState(false);
  const [isChangedSecondaryCompany, setIsChangedSecondaryCompany] = useState(false);
  const primaryCompanyInputRef: LegacyRef<any> | undefined = useRef(null);
  const secondaryCompanyInputRef: LegacyRef<any> | undefined = useRef(null);
  // eslint-disable-next-line max-len
  const noCompanyText = "Insurance missing? \nType it in and contact RhythmExpress to include it in the dropdown suggestions.";

  useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusIn);
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusIn);
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (!isCheckingValid && isStudy) {
      validateFormHighLight();
    }
  }, [isCheckingValid, isStudy]);

  useEffect(() => {
    if (subscribersRelationship?.defaultValue?.value === "Self"
      && patientBase?.Id
      && subscribersRelationship?.value === "") {
      prepopulateFields(true);
      subscribersRelationship.setValue("Self");
    }
  }, [patientBase, subscribersRelationship]);

  const handleFocusIn = () => {
    if (primaryCompanyInputRef.current || secondaryCompanyInputRef.current) {
      const elPrimaryCompany = primaryCompanyInputRef.current.getElementsByTagName("input");
      const elSecondaryCompany = secondaryCompanyInputRef.current.getElementsByTagName("input");

      if (primaryInsuranceCompany && elPrimaryCompany && document.activeElement === elPrimaryCompany[0]) {
        setTimeout(() => {
          setShowHelpPrimaryCompany(true);
        }, 150);
      }

      if (secondaryInsuranceCompany && elSecondaryCompany && document.activeElement === elSecondaryCompany[0]) {
        setTimeout(() => {
          setShowHelpSecondaryCompany(true);
        }, 150);
      }
    } else {
      setShowHelpPrimaryCompany(false);
      setShowHelpSecondaryCompany(false);
    }
  };

  const handleClick = (event: any) => {
    if (event?.target?.classList?.contains("help-el")
      || event?.target?.classList?.contains("help")
    ) {
      /* empty */
    } else {
      if (event?.target?.name === "primaryInsuranceCompany") {
        setShowHelpSecondaryCompany(false);
      } else if (event?.target?.name === "secondaryInsuranceCompany") {
        setShowHelpPrimaryCompany(false);
      } else {
        setShowHelpPrimaryCompany(false);
        setShowHelpSecondaryCompany(false);
      }
    }
  };

  const getPrimaryInsuranceCompanyOptions = () => {
    return primaryInsuranceCompany.options.filter((el: any) => {
      if (!isChangedPrimaryCompany) {
        return el;
      } else if (primaryInsuranceCompany.value) {
        return el.value.toLowerCase().includes(primaryInsuranceCompany.value.toLowerCase());
      }
      return el;
    });
  };

  const getSecondaryInsuranceCompanyOptions = () => {
    return secondaryInsuranceCompany.options.filter((el: any) => {
      if (!isChangedSecondaryCompany) {
        return el;
      } else if (secondaryInsuranceCompany.value) {
        return el.value.toLowerCase().includes(secondaryInsuranceCompany.value.toLowerCase());
      }
      return el;
    });
  };

  function validateForm() {
    const { errors, isValid } = validateFormFields(isSaveIncompleateAvailable);
    setErrors(errors);
    return isValid;
  }

  function prepopulateFields(checkIsValueExist?: boolean) {
    checkIsValueExist
      ? !subscribersFirstName.value && subscribersFirstName.setValue(patientBase.Name)
      : subscribersFirstName.setValue(patientBase.Name);
    checkIsValueExist
      ? !subscribersLastName.value && subscribersLastName.setValue(patientBase.LastName)
      : subscribersLastName.setValue(patientBase.LastName);
    checkIsValueExist
      ? !subscribersDOB.value && subscribersDOB.setValue(patientBase.DOB)
      : subscribersDOB.setValue(patientBase.DOB);
  }

  const handleChangeInsurance = (event: any, item: any) => {
    const value = event?.value || "";
    if (item.fieldId === "primaryInsuranceCompany") {
      setIsChangedPrimaryCompany(true);
      setDisableCompany(value, true);
    } else {
      setIsChangedSecondaryCompany(true);
      setDisableCompany(value, false);
    }
  };

  const handleChange = () => {
  };
  const handleChangeRelationShip = (event: any, item: any) => {
    const selectedRelationship = item.value;

    if (selectedRelationship === "Self") {
      prepopulateFields();
    } else {
      subscribersFirstName.setValue("");
      subscribersLastName.setValue("");
      subscribersDOB.setValue("");
      specifyRelationship.setValue("");
    }
  };
  const clearData = () => {
    resetForm(true);
    setFormInvalid(false);
    setErrors({});
  };
  const hide = () => {
    clearData();
    history.goBack();
  };
  const onSubmit = async(event: any) => {
    event.preventDefault();
    const key = "updatable";
    const id = patientId || "";
    if (!validateForm()) {
      setFormInvalid(true);
    } else {
      try {
        setFormInvalid(false);
        setGlobalSpiner(true);
        const response = await saveForm();
        if (response) {
          await fileRef.current?.submitFile(id, !isCreate);
          if (isCreate) {
            setTimeout(() => {
              clearData();
              history.push(`/study-create/${id}`);
            }, 100);
          } if (isStudy) {
            hide();
          } else {
            setFormInvalid(false);
            setErrors({});
            message.success({ content: "Insurance information successfully updated!", key, duration: 2 });
          }
        } else {
          message.error({ content: "Error, Something went wrong!", key, duration: 2 });
        }
        setGlobalSpiner(false);
      } catch (e) {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
      }
    }
  };

  return (
    <ClPatientInsuranceInfoWrapper>
      <div className="container">
        <h1>Insurance</h1>
        {isLoading
          ? <div className="spinner"><CLSpiner containerWidth={"100px"}/></div>
          : <div className={"form-container " + (highlights ? "highlight" : "")} id="study-enrollment">
            <form onSubmit={onSubmit}>
              <div className="study-create-block">
                <h3>Insurance Information</h3>
                <div className="help-container" ref={primaryCompanyInputRef}>
                  {primaryInsuranceCompany.value && <div className="clear" onClick={() => {
                    primaryInsuranceCompany.setValue("");
                    setDisableCompany("", true);
                  }}>
                    <CLIcon icon={ClearIcon} height="14px" width="14px"></CLIcon>
                  </div>}
                  <CLDynamicFormComponent
                    item={primaryInsuranceCompany}
                    customClass="invite-field"
                    errors={errors}
                    showError={true}
                    handleChange={handleChangeInsurance}
                    isFormInvalid={formInvalid}/>
                  {showHelpPrimaryCompany && <div className="help">
                    {getPrimaryInsuranceCompanyOptions().map((el: any, index: number) => (
                      <div className={`help-el ${el.isDisabled ? "disabled" : ""}`} key={index} onClick={() => {
                        primaryInsuranceCompany.setValue(el.value);
                        setDisableCompany(el.value, true);
                        setIsChangedPrimaryCompany(false);
                        setShowHelpPrimaryCompany(false);
                      }}>
                        {el.label}
                        {el.value === primaryInsuranceCompany.value && <div className="active"></div>}
                      </div>
                    ))}
                    {!getPrimaryInsuranceCompanyOptions().length && <div className="no-result">
                      <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
                      <div className="text">{noCompanyText}</div>
                    </div>}
                  </div>}
                </div>
                <CLDynamicFormComponent
                  item={policyNumber}
                  customClass="invite-field"
                  errors={errors}
                  showError={true}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={groupNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <div className="w100 border-bottom"></div>
                <h3>Secondary Insurance Information</h3>
                <div className="help-container" ref={secondaryCompanyInputRef}>
                  {secondaryInsuranceCompany.value && <div className="clear" onClick={() => {
                    secondaryInsuranceCompany.setValue("");
                    setDisableCompany("", false);
                  }}>
                    <CLIcon icon={ClearIcon} height="14px" width="14px"></CLIcon>
                  </div>}
                  <CLDynamicFormComponent
                    item={secondaryInsuranceCompany}
                    customClass="invite-field"
                    errors={errors}
                    showError={true}
                    handleChange={handleChangeInsurance}
                    isFormInvalid={formInvalid}/>
                  {showHelpSecondaryCompany && <div className="help">
                    {getSecondaryInsuranceCompanyOptions().map((el: any, index: number) => (
                      <div className={`help-el ${el.isDisabled ? "disabled" : ""}`} key={index} onClick={() => {
                        secondaryInsuranceCompany.setValue(el.value);
                        setDisableCompany(el.value, false);
                        setIsChangedSecondaryCompany(false);
                        setShowHelpSecondaryCompany(false);
                      }}>
                        {el.label}
                        {el.value === secondaryInsuranceCompany.value && <div className="active"></div>}
                      </div>
                    ))}
                    {!getSecondaryInsuranceCompanyOptions().length && <div className="no-result">
                      <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
                      <div className="text">{noCompanyText}</div>
                    </div>}
                  </div>}
                </div>
                <CLDynamicFormComponent
                  item={secPolicyNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={secGroupNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <div className="w100 border-bottom"></div>
                <h3>Subscriber Information</h3>
                <CLDynamicFormComponent
                  item={subscribersRelationship}
                  customClass="invite-field"
                  errors={errors}
                  showError={true}
                  handleChange={handleChangeRelationShip}
                  isFormInvalid={formInvalid}/>
                {isSpecifyRelationshipShow
                  ? <CLDynamicFormComponent
                    item={specifyRelationship}
                    customClass="invite-field"
                    errors={errors}
                    showError={true}
                    handleChange={handleChange}
                    isFormInvalid={formInvalid}/>
                  : null}
                <CLDynamicFormComponent
                  item={subscribersFirstName}
                  customClass="invite-field"
                  errors={errors}
                  showError={true}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={subscribersLastName}
                  customClass="invite-field"
                  errors={errors}
                  showError={true}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={subscribersDOB}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
              </div>
              <div className="w100 border-bottom"></div>
              <CLUploadInsuranceCardImages ref={fileRef} idPatient={patientId || 0} isEdit={!isCreate}/>
              <div className="btn-container">
                <CLBlueButton type="submit" text={"Save Information"} click={() => {
                }}/>
                <CLLightButton type="button" text="Cancel" click={hide}/>
              </div>
            </form>
          </div>
        }
      </div>
    </ClPatientInsuranceInfoWrapper>
  );
});
