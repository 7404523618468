import styled from "styled-components";

export const CLDevicesHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  .margin-block-right {
    margin-right: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .download_link {
    display: flex;

    &:hover {
      text-decoration: none;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #484848;
      margin-left: 10px;
      display: inline-block;
    }
  }

  .create {
    margin-left: auto;
  }

  .pagination {
    margin-left: auto;
  }
`;
