import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ClAdministrationICD10ModalWrapper = styled.div`
  position: relative;
  padding: 20px 40px;
  background: #FFF;
  width: 100%;
  margin-top: 30px;

  .header-container{
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: space-between;
  }
  h1 {
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    padding: 0;
    max-width: 1400px;
    margin: 20px 0 40px;
  }
  .overlay{
    max-width: 1400px;
  }
  .title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.text};
  }

  .form-container {
    .fields-container {
      padding-left: 40px;
      border-left: 10px solid ${theme.colors.duration};
      display: flex;
      flex-direction: column;
      text-align: left;

      .invite-field {
        width: 100%;
        max-width: 580px;
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;

      div:last-child {
        margin-left: 24px;
      }
    }
  }

  .content-info {
    display: flex;
    width: 100%;
    max-width: 580px;
    padding-left: 10px;
    padding-bottom: 14px;

    .content-item {
      padding-right: 40px;
  
      .title {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: ${theme.colors.placeholder};
        padding-bottom: 4px;
      }
    
      .text {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: ${theme.colors.text};
      }
    }
  }
`;
