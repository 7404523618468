import { observer } from "mobx-react-lite";
import { CLPatientsListCategoriesWrapper } from "./cl-patients-list-categories.presents";
import { CLCategoryItem } from "../shared/cl-category-item";
import { CLPagination } from "../shared/cl-pagination";
import { HomeHookupCategory, PatiensListCategories, patientListCountRows } from "../../boot/Constants";
import { useRootStore } from "../../stores/storeContext";
import { useEffect } from "react";
import { useHistory } from "react-router";

export const CLPatientsListCategories = observer(() => {
  const {
    activeCategory,
    total,
    page,
    setActiveCategory,
    nextPage,
    previousPage,
    allCategoryTotal,
    activeCategoryTotal,
    eventsCategoryTotal,
    pendingCategoryTotal,
    archivedCategoryTotal,
    inactiveCategoryTotal,
    homeHookupCategoryTotal
  } = useRootStore().listPatientsStore;

  const {
    idPhysician,
    isUseSH
  } = useRootStore().userStore;

  const history: any = useHistory();

  const categoriesTotals: any = {
    allCategoryTotal,
    activeCategoryTotal,
    eventsCategoryTotal,
    pendingCategoryTotal,
    archivedCategoryTotal,
    inactiveCategoryTotal,
    homeHookupCategoryTotal
  };

  useEffect(() => {
    if (history?.location?.state?.activeCategory) {
      setActiveCategory(history.location.state?.activeCategory, 0, true);
    } else if (idPhysician) {
      setActiveCategory("active", 0, true);
    }
  }, [idPhysician, history.location.state]);

  const changeListCategory = (value: string) => {
    setActiveCategory(value, categoriesTotals[`${value}CategoryTotal`]);
  };

  return (
    <CLPatientsListCategoriesWrapper>
      {PatiensListCategories.map(item => (
        <CLCategoryItem
          key={item.title}
          onChange={changeListCategory}
          title={item.title}
          icon={activeCategory === item.value ? item.iconActive : item.icon}
          count={categoriesTotals[`${item.value}CategoryTotal`]}
          value={item.value}
          isActive={activeCategory === item.value}
        ></CLCategoryItem>
      ))}
      {isUseSH
        ? <CLCategoryItem
          key={HomeHookupCategory.title}
          onChange={changeListCategory}
          title={HomeHookupCategory.title}
          icon={activeCategory === HomeHookupCategory.value ? HomeHookupCategory.iconActive : HomeHookupCategory.icon}
          count={categoriesTotals[`${HomeHookupCategory.value}CategoryTotal`]}
          value={HomeHookupCategory.value}
          isActive={activeCategory === HomeHookupCategory.value}
        ></CLCategoryItem>
        : null}
      <div className="pagination">
        {(total > patientListCountRows) && <CLPagination
          total={total}
          page={page}
          countOnPage={patientListCountRows}
          next={nextPage}
          previous={previousPage}></CLPagination>}
      </div>

    </CLPatientsListCategoriesWrapper>
  );
});
