import styled from "styled-components";
import { theme } from "../../theme/theme";

export const PatientEventsIcons = styled.div`
  //width: 280px;
  position: relative;
  //border-radius: 16px;
  //box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
  //background: ${theme.colors.light};
  //padding: 24px;
  //margin: 24px 0 24px 24px;
`;
