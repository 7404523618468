import { ICreateUserModelSnapShot } from "./createUser.model";
import { GenderType } from "../../boot/Constants";

export const InitialState: ICreateUserModelSnapShot = {
  id: "0",
  firstName: {
    name: "First Name",
    value: "",
    type: "input",
    maxLength: 50,
    fieldId: "firstName"
  },
  middleName: {
    name: "Middle Name",
    value: "",
    type: "input",
    maxLength: 5,
    fieldId: "middleName",
    isOptional: true
  },
  lastName: {
    name: "Last Name",
    value: "",
    type: "input",
    maxLength: 50,
    fieldId: "lastName"
  },
  comment: {
    name: "Comment",
    value: ".",
    type: "input",
    fieldId: "comment"
  },
  birthDate: {
    name: "Date of Birth",
    value: "",
    type: "dateTime",
    fieldId: "birthDate"
  },
  gender: {
    name: "Gender",
    value: "",
    type: "select",
    options: [{
      value: GenderType.M,
      label: GenderType.M
    }, {
      value: GenderType.F,
      label: GenderType.F
    }, {
      value: GenderType.O,
      label: GenderType.O
    }],
    fieldId: "gender"
  },
  patientId: {
    name: "Patient Id",
    value: "",
    type: "input",
    fieldId: "patientId"
  },
  // Contact Info
  streetAddress: {
    name: "Street Address",
    value: "",
    type: "input",
    fieldId: "streetAddress"
  },
  apartmentNumber: {
    name: "Apartment Number",
    value: "",
    type: "input",
    fieldId: "apartmentNumber",
    isOptional: true
  },
  city: {
    name: "City",
    value: "",
    type: "input",
    fieldId: "city"
  },
  state: {
    name: "State / Territory",
    value: "",
    type: "select",
    options: [],
    fieldId: "state"
  },
  zip: {
    name: "Zip",
    value: "",
    type: "input",
    fieldId: "zip",
    validateZip: true
  },
  timeZone: {
    name: "Time Zone",
    value: "",
    type: "input",
    fieldId: "timeZone",
    validateTimeZone: true
  },
  DST: {
    name: "DST",
    value: "",
    type: "input",
    fieldId: "DST"
  },
  phone: {
    name: "Text Phone Number",
    value: "",
    type: "number",
    fieldId: "phone",
    isCompliance: true
  },
  alternativePhone: {
    name: "Alternative Phone Number",
    value: "",
    type: "number",
    fieldId: "alternativePhone",
    isOptional: true
  },
  otherContacts: {
    name: "Other Contacts",
    value: "",
    type: "input",
    placeholder: "Email etc.",
    fieldId: "otherContacts",
    isOptional: true
  },
  isPaced: {
    name: "Patient is paced",
    value: "false",
    type: "checkbox",
    isChecked: false,
    fieldId: "isPaced"
  },
  ICD10Code: {
    name: "ICD-10 Code",
    value: "",
    type: "multiply",
    options: [],
    fieldId: "ICD10Code"
  },
  medications: {
    name: "Medications",
    value: "",
    type: "input",
    fieldId: "medications",
    isOptional: true
  },
  orderingPhysician: {
    name: "Ordering Physician",
    value: "",
    type: "select",
    fieldId: "orderingPhysician",
    options: []
  },
  referringClinical: {
    name: "Ordering Clinic",
    value: "",
    type: "select",
    options: [],
    fieldId: "referringClinical"
  },
  readingPhysician: {
    name: "Reading Physician",
    value: "",
    type: "select",
    fieldId: "readingPhysician",
    options: []
  },
  readingReferringClinical: {
    name: "Reading Clinic",
    value: "",
    type: "select",
    options: [],
    fieldId: "readingReferringClinical"
  }
};
